
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APP_ID ?? "",
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN ?? "",
    site: process.env.REACT_APP_DD_SITE ?? "",
    service: process.env.REACT_APP_DD_SERVICE ?? "",
    env: process.env.REACT_APP_DD_ENV ?? "",
    version: process.env.REACT_APP_DD_VERSION ?? "",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowUntrustedEvents: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
        { match: process.env.REACT_APP_API_URI ?? "", propagatorTypes: ["tracecontext"] }
    ]
});