import { createContext, useContext } from "react";
import authService from "../../services/authService";

const AuthContext = createContext(authService());

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const authServiceInstance = authService();
  return (
    <AuthContext.Provider value={authServiceInstance}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthContext;
