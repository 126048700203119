const NoAppointmentData = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="w-[10%] min-w-[140px] text-center no-appointments-svg-dark-mode">
        <svg
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M61.3791 28.7661H39.5711C39.0741 28.7667 38.5977 28.9644 38.2463 29.3158C37.8949 29.6672 37.6972 30.1437 37.6966 30.6406V79.1092L37.4467 79.1854L32.0968 80.8237C31.8433 80.901 31.5694 80.8746 31.3353 80.7502C31.1013 80.6258 30.9262 80.4136 30.8484 80.1602L14.935 28.18C14.8576 27.9265 14.884 27.6526 15.0084 27.4185C15.1329 27.1844 15.3451 27.0093 15.5986 26.9316L23.8427 24.4073L47.7427 17.0917L55.9868 14.5674C56.1122 14.5288 56.2441 14.5153 56.3748 14.5277C56.5054 14.5401 56.6324 14.5781 56.7484 14.6396C56.8643 14.7011 56.967 14.7849 57.0506 14.8862C57.1341 14.9874 57.1968 15.1042 57.2352 15.2297L61.3029 28.5162L61.3791 28.7661Z"
            fill="var(--primary-100)"
          />
          <path
            d="M66.1378 28.5161L61.2354 12.5028C61.1538 12.236 61.0204 11.9879 60.843 11.7726C60.6655 11.5574 60.4473 11.3792 60.2009 11.2483C59.9545 11.1174 59.6848 11.0363 59.407 11.0097C59.1293 10.9831 58.8491 11.0114 58.5823 11.0932L46.9916 14.641L23.0929 21.9578L11.5022 25.5069C10.9637 25.6722 10.5129 26.0445 10.2486 26.5419C9.98432 27.0393 9.92818 27.6212 10.0925 28.16L26.8481 82.8869C26.9816 83.3218 27.251 83.7024 27.6167 83.973C27.9824 84.2435 28.4252 84.3898 28.8801 84.3902C29.0907 84.3903 29.3 84.3587 29.5012 84.2965L37.4466 81.8646L37.6965 81.7872V81.526L37.4466 81.6022L29.4275 84.0578C28.9522 84.2027 28.4389 84.1531 28.0001 83.92C27.5613 83.6869 27.2329 83.2893 27.0868 82.8144L10.3325 28.0862C10.2602 27.8509 10.2349 27.6036 10.2583 27.3585C10.2817 27.1135 10.3531 26.8754 10.4686 26.658C10.5841 26.4406 10.7414 26.2481 10.9314 26.0915C11.1214 25.9349 11.3404 25.8174 11.5759 25.7456L23.1666 22.1965L47.0654 14.8809L58.6561 11.3319C58.8347 11.2774 59.0204 11.2496 59.2071 11.2494C59.6079 11.2503 59.9979 11.3795 60.3199 11.6181C60.642 11.8567 60.8791 12.1921 60.9967 12.5753L65.8767 28.5161L65.9541 28.7661H66.2141L66.1378 28.5161Z"
            fill="var(--primary-500)"
          />
          <path
            d="M25.3304 26.9725C25.0895 26.9723 24.8551 26.895 24.6614 26.7518C24.4677 26.6086 24.325 26.4071 24.2543 26.1769L22.6446 20.9193C22.6014 20.778 22.5864 20.6297 22.6005 20.4826C22.6146 20.3356 22.6575 20.1928 22.7268 20.0624C22.7961 19.9319 22.8904 19.8164 23.0043 19.7224C23.1183 19.6284 23.2496 19.5578 23.3908 19.5146L45.3775 12.7834C45.6627 12.6964 45.9708 12.726 46.2341 12.8659C46.4975 13.0058 46.6946 13.2445 46.7822 13.5295L48.3918 18.7872C48.4788 19.0724 48.4491 19.3805 48.3092 19.6438C48.1693 19.9072 47.9307 20.1043 47.6457 20.1919L25.659 26.9231C25.5525 26.9558 25.4418 26.9724 25.3304 26.9725Z"
            fill="var(--primary-500)"
          />
          <path
            d="M33.7622 16.6169C35.1426 16.6169 36.2616 15.4979 36.2616 14.1175C36.2616 12.7372 35.1426 11.6182 33.7622 11.6182C32.3819 11.6182 31.2629 12.7372 31.2629 14.1175C31.2629 15.4979 32.3819 16.6169 33.7622 16.6169Z"
            fill="var(--primary-500)"
          />
          <path
            d="M33.7622 15.7002C34.6363 15.7002 35.3449 14.9917 35.3449 14.1176C35.3449 13.2435 34.6363 12.5349 33.7622 12.5349C32.8881 12.5349 32.1796 13.2435 32.1796 14.1176C32.1796 14.9917 32.8881 15.7002 33.7622 15.7002Z"
            fill="var(--primary-100)"
          />
          <path
            d="M85.3091 83.7515H43.0702C42.7885 83.7512 42.5185 83.6392 42.3194 83.4401C42.1203 83.2409 42.0083 82.9709 42.0079 82.6893V32.0776C42.0082 31.796 42.1203 31.526 42.3194 31.3268C42.5185 31.1277 42.7885 31.0157 43.0702 31.0154H85.3091C85.5907 31.0157 85.8607 31.1277 86.0598 31.3269C86.259 31.526 86.371 31.796 86.3713 32.0776V82.6893C86.371 82.9709 86.2589 83.2409 86.0598 83.4401C85.8607 83.6392 85.5907 83.7512 85.3091 83.7515Z"
            fill="var(--primary-100)"
          />
          <path
            d="M65.8766 28.5161H39.571C39.0078 28.5169 38.4679 28.741 38.0697 29.1392C37.6715 29.5375 37.4474 30.0774 37.4466 30.6406V81.6022L37.6965 81.526V30.6406C37.6971 30.1436 37.8948 29.6672 38.2462 29.3157C38.5976 28.9643 39.0741 28.7667 39.571 28.766H65.9541L65.8766 28.5161ZM88.8081 28.5161H39.571C39.0078 28.5169 38.4679 28.741 38.0697 29.1392C37.6715 29.5375 37.4474 30.0774 37.4466 30.6406V87.8755C37.4474 88.4387 37.6715 88.9786 38.0697 89.3769C38.4679 89.7751 39.0078 89.9992 39.571 90H88.8081C89.3713 89.9992 89.9112 89.7751 90.3095 89.3769C90.7077 88.9786 90.9318 88.4387 90.9326 87.8755V30.6406C90.9318 30.0774 90.7077 29.5375 90.3095 29.1392C89.9112 28.741 89.3713 28.5169 88.8081 28.5161ZM90.6826 87.8755C90.682 88.3725 90.4843 88.8489 90.1329 89.2004C89.7815 89.5518 89.3051 89.7494 88.8081 89.75H39.571C39.0741 89.7494 38.5976 89.5518 38.2462 89.2004C37.8948 88.8489 37.6971 88.3725 37.6965 87.8755V30.6406C37.6971 30.1436 37.8948 29.6672 38.2462 29.3157C38.5976 28.9643 39.0741 28.7667 39.571 28.766H88.8081C89.3051 28.7667 89.7815 28.9643 90.1329 29.3157C90.4843 29.6672 90.682 30.1436 90.6826 30.6406V87.8755Z"
            fill="var(--primary-500)"
          />
          <path
            d="M75.6866 34.0146H52.6926C52.3944 34.0142 52.1086 33.8956 51.8977 33.6848C51.6869 33.4739 51.5683 33.1881 51.5679 32.8899V27.3913C51.5683 27.0931 51.6869 26.8072 51.8977 26.5964C52.1086 26.3855 52.3944 26.2669 52.6926 26.2666H75.6866C75.9848 26.2669 76.2707 26.3855 76.4815 26.5964C76.6924 26.8072 76.811 27.0931 76.8113 27.3913V32.8899C76.811 33.1881 76.6924 33.4739 76.4815 33.6848C76.2707 33.8956 75.9848 34.0142 75.6866 34.0146Z"
            fill="var(--primary-500)"
          />
          <path
            d="M64.1896 26.6415C65.5699 26.6415 66.6889 25.5225 66.6889 24.1422C66.6889 22.7618 65.5699 21.6428 64.1896 21.6428C62.8092 21.6428 61.6902 22.7618 61.6902 24.1422C61.6902 25.5225 62.8092 26.6415 64.1896 26.6415Z"
            fill="var(--primary-500)"
          />
          <path
            d="M64.1896 25.6645C65.0303 25.6645 65.7119 24.983 65.7119 24.1422C65.7119 23.3014 65.0303 22.6199 64.1896 22.6199C63.3488 22.6199 62.6672 23.3014 62.6672 24.1422C62.6672 24.983 63.3488 25.6645 64.1896 25.6645Z"
            fill="var(--primary-100)"
          />
          <path
            d="M64.1276 48.3652C66.1997 48.3652 67.8554 48.932 69.0947 50.0656C70.3542 51.1992 70.984 52.7478 70.984 54.7114C70.984 56.7559 70.3339 58.2943 69.0337 59.3267C67.7335 60.3591 66.0067 60.8753 63.8533 60.8753L63.7314 63.2741H60.7146L60.5622 58.5069H61.5678C63.5384 58.5069 65.0417 58.2437 66.0778 57.7174C67.1342 57.1911 67.6624 56.1891 67.6624 54.7114C67.6624 53.6385 67.3475 52.7984 66.7178 52.1911C66.1083 51.5838 65.2551 51.2802 64.158 51.2802C63.061 51.2802 62.1976 51.5737 61.5678 52.1608C60.938 52.7478 60.6232 53.5676 60.6232 54.6203H57.3625C57.3625 53.4057 57.6368 52.3227 58.1853 51.3713C58.7338 50.4199 59.516 49.681 60.5317 49.1547C61.5678 48.6284 62.7664 48.3652 64.1276 48.3652ZM62.1773 69.9846C61.5475 69.9846 61.0193 69.772 60.5927 69.3469C60.1661 68.9218 59.9528 68.3955 59.9528 67.768C59.9528 67.1404 60.1661 66.6141 60.5927 66.189C61.0193 65.7639 61.5475 65.5514 62.1773 65.5514C62.7867 65.5514 63.3048 65.7639 63.7314 66.189C64.158 66.6141 64.3713 67.1404 64.3713 67.768C64.3713 68.3955 64.158 68.9218 63.7314 69.3469C63.3048 69.772 62.7867 69.9846 62.1773 69.9846Z"
            fill="var(--primary-400)"
          />
        </svg>
      </div>
      <div className="font-normal text-sm">No Appointments Available</div>
    </div>
  );
};

export default NoAppointmentData;
