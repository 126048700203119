import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ResizeStateModel } from "../../model/resize-state-model";

const initialState: ResizeStateModel = {
  size: {
    topHeader: "m",
    clinicianAlerts: "m",
    contactPreferences: "m",
    labResults: "m",
    advanceDirective: "m",
    specialist: "m",
    memberStatusFlag: "m",
    awvDueDate: "m",
    stratification: "m",
    problems: "m",
    medication: "m",
    screenings: "m",
    allergies: "m",
    qualityManagement: "m",
    vitals: "m",
    visitsTimeline: "m",
    communication: "m",
    notes: "m",
    appointments: "m",
    riskScore: "m",
    mraGaps: "m",
    hpi: "m",
  },
};

const resizeSetSizeSlice = createSlice({
  name: "setSize",
  initialState,
  reducers: {
    ResizeWidget: (
      state: ResizeStateModel,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = action.payload;
      state.size[name] = value;
    },
  },
});

export const { ResizeWidget } = resizeSetSizeSlice.actions;
export const selectResize = (state: RootState) => state.resizeWidgetMode;

export default resizeSetSizeSlice.reducer;
