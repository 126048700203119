import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

const initialState: any = {};

const widgetDropDownSlice = createSlice({
  name: "widgetDropDownSlice",
  initialState,
  reducers: {
    SetWidgetDropDown: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
  },
});

export const { SetWidgetDropDown } = widgetDropDownSlice.actions;
export const selectWidgetDropDowns = (state: RootState) =>
  state.widgetDropDowns;

export default widgetDropDownSlice.reducer;
