import moment from "moment";
import { Toast } from "primereact/toast";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouterProvider } from "react-router";
import "./App.scss";
import { SetAppointmentDate } from "./Redux/features/activeAppointmentsDateSlice";
import { selectAppStatus } from "./Redux/features/appStatusSlice";
import { EditLayout } from "./Redux/features/editModeSlice";
import { VisibleLayout } from "./Redux/features/expandModeSlice";
import { FilterDataTable } from "./Redux/features/filterDataTableSlice";
import { SetLayoutState } from "./Redux/features/layoutStateSlice";
import { SetMessage } from "./Redux/features/toasterInfoSlice";
import { AppRouter } from "./Router/AppRouter";
import { AuthProvider } from "./components/auth/authProvider";
import IdelPopup from "./components/idle-timer/IdlePopup";
import AppLayoutSettingSaveCancel from "./components/layout-setting/AppLayoutSettingSaveCancel";
import { ToasterComponent } from "./components/toaster-component/ToasterComponent";
import { DateTimeConstant } from "./data/constants/DateTimeConstants";
import { dropStatusList } from "./data/constants/DefaultDropdownStatusList";
import { VisibilityProvider } from "./layout/context/visibilityContext";
import { useCheckWithMinimalAPI } from "./services/checkNetworkAvailibilityService";
import "./utils/Datadog";

const App = () => {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const currentVer = "1.2.8";
  const ver = localStorage.getItem("version");
  const [wasOnline, setWasOnline] = useState(true);
  const userTokenInfoString = localStorage.getItem("UserTokenInfo") ? JSON.parse(localStorage.getItem("UserTokenInfo") ?? "{}") : null;
  const isOnline = useSelector(selectAppStatus);

  const handleOnlineStatusChange = useCallback(() => {
    if (userTokenInfoString !== null && isOnline && Date.now() / 1000 > userTokenInfoString?.expires_at) {
      return window.location.replace("/#/logout");
    }

    if (!isOnline && sessionStorage.getItem("offline-auth") === null) {
      return window.location.replace("/#/otp-login");
    }

    if (!isOnline && wasOnline) {
      // User was online and now offline
      const toastSeverity = "error";
      const toastSummary = "Offline";
      const toastDetail = "Oops looks like you are Offline";

      toast.current?.show({
        severity: toastSeverity,
        summary: toastSummary,
        detail: toastDetail,
      });
      setWasOnline(false); // Set the state to indicate the app is offline
    } else if (isOnline && !wasOnline) {
      // User was offline and now online
      const toastSeverity = "success";
      const toastSummary = "Online";
      const toastDetail = "You are back Online";

      toast.current?.show({
        severity: toastSeverity,
        summary: toastSummary,
        detail: toastDetail,
      });
      setWasOnline(true); // Set the state to indicate the app is online
    }
  }, [isOnline, wasOnline]);

  useEffect(() => {
    Object.keys(dropStatusList).forEach((key) => {
      dispatch(FilterDataTable({ name: key, value: dropStatusList[key] }));
    });
  }, []);

  useEffect(() => {
    // Default value for edit mode on page load: to stop maintaining the previous state on app refresh
    dispatch(EditLayout(false));
    dispatch(SetLayoutState({ configSidebarVisible: false }));

    handleOnlineStatusChange();

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, [handleOnlineStatusChange]);

  if (!ver || ver !== currentVer) {
    localStorage.clear();
    localStorage.setItem("version", currentVer);
  }

  const { checkWithMinimalAPI } = useCheckWithMinimalAPI();
  const setDefaultValues = () => {
    dispatch(SetAppointmentDate(moment().format(DateTimeConstant.DF)));
    dispatch(VisibleLayout(false));
    dispatch(SetMessage({ summary: "", severity: "", detail: "" }));
  };

  useEffect(() => {
    setDefaultValues();
    const intervalId = setInterval(() => {
      checkWithMinimalAPI();
    }, Number(process.env.REACT_APP_CALL_MINIMAL_API_TIME_INTERVAL));
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      {window.self === window.top ? (
        <AuthProvider>
          <VisibilityProvider>
            <RouterProvider router={AppRouter} />
            <Toast ref={toast} />
            <IdelPopup />
            <ToasterComponent />
            <AppLayoutSettingSaveCancel />
          </VisibilityProvider>
        </AuthProvider>
      ) : (
        <div className="min-h-screen flex justify-center items-center bg-gray-100">
          <div className="max-w-lg p-8 bg-white shadow-md rounded-lg">
            <p className="text-2xl font-semibold mb-4">Oops!</p>
            <p className="text-lg text-gray-700 mb-4">Looks like you've wandered off the beaten path.</p>
            <p className="text-lg text-gray-700 mb-4">This application isn't meant to be framed.</p>
            <p className="text-lg text-gray-700 mb-4">To experience its full magic, please visit us directly.</p>
            <p className="text-lg text-gray-700">Happy exploring!</p>
          </div>
        </div>
      )}
    </>
  );
};

export default App;
