import { useState, useEffect } from "react";
import CustomInstallConfirmPopUp from "./CustomInstallConfirmPopUp";
import { useDispatch, useSelector } from "react-redux";
import {
  FocusPin,
  selectFocusPin,
} from "../../../Redux/features/pinFocusSlice";

interface BeforeInstallPromptEvent extends Event {
  platforms: Array<string>;
  userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): void;
}

const AppInstallationLogic = () => {
  const [installPromptEvent, setInstallPromptEvent] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [isInstallModalOpen, setIsInstallModalOpen] = useState(false);

  useEffect(() => {
    const isAppInstalled = localStorage.getItem("isAppInstalled");
    const hasUserDismissed = localStorage.getItem("hasUserDismissed");

    if (isAppInstalled || hasUserDismissed) {
      // App is already installed or user has dismissed the prompt, no need to show the installation prompt
      return;
    }

    const handleBeforeInstallPrompt = (event: Event) => {
      event.preventDefault();
      const promptEvent = event as BeforeInstallPromptEvent;
      setInstallPromptEvent(promptEvent);
      setIsInstallModalOpen(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstall = () => {
    if (installPromptEvent) {
      const promptEvent = installPromptEvent;

      // Manually trigger the installation process
      promptEvent.prompt();

      // Handle the user's choice
      promptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
          localStorage.setItem("isAppInstalled", "true");
        } else {
          console.log("User dismissed the install prompt");
          localStorage.setItem("hasUserDismissed", "true");

          // Close the custom modal when the user clicks "Not Now" in the browser's prompt
          setTimeout(() => {
            setIsInstallModalOpen(false);
            dispatch(FocusPin(!pinFocus.isPinFocused));
          }, 1);
        }

        // Reset the event
        setInstallPromptEvent(null);
      });

      // Close the custom modal only when the user accepts the browser prompt
      setIsInstallModalOpen(false);
    }
  };
  const dispatch = useDispatch();
  const pinFocus = useSelector(selectFocusPin);

  const handleCloseModal = () => {
    localStorage.setItem("hasUserDismissed", "true");
    setIsInstallModalOpen(false);
    dispatch(FocusPin(!pinFocus.isPinFocused));
  };

  return (
    <div>
      <CustomInstallConfirmPopUp
        isOpen={isInstallModalOpen}
        onRequestClose={handleCloseModal}
        onInstall={handleInstall}
      ></CustomInstallConfirmPopUp>
    </div>
  );
};

export default AppInstallationLogic;
