import { Accordion, AccordionTab } from "primereact/accordion";
import { Tooltip } from "primereact/tooltip";
import { useState } from "react";

export const GenericAccordion = ({ payload, config, active }: any) => {
  const [activeIndex, setActiveIndex] = useState(active);
  const onTabChange = (event: any) => {
    const { index } = event;
    setActiveIndex(index);
  };

  return (
    <Accordion activeIndex={activeIndex} onTabChange={onTabChange}>
      {payload.map((item: any, index: any) => (
        <AccordionTab
          disabled={config.layoutMode}
          key={(index = config.i)}
          header={
            <>
              {config.stateTooltip && (
                <Tooltip
                  target={`.header-tooltip-${index}`}
                  position="right"
                  content={
                    index === activeIndex
                      ? "Click to collapse"
                      : "Click to expand"
                  }
                />
              )}
              <div className={`header-tooltip-${index} flex flex-col`}>
                {item.header}
              </div>
            </>
          }
        >
          {item.body}
        </AccordionTab>
      ))}
    </Accordion>
  );
};
