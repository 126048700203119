import { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";
import { selectAppReset } from "../Redux/features/appResetSlice";
import { selectEdit } from "../Redux/features/editModeSlice";
import { ResizeWidget } from "../Redux/features/resizeSetSizeSlice";
import MemberDetails from "../components/member-details/MemberDetails";
import { LayoutStructure } from "../data/LayoutStructure";
import ClairLayoutItem from "./clair-layout-item/ClairLayoutItem";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

const ClairLayout = ({ user }: any) => {
  const dispatch = useDispatch();
  const layoutMode = useSelector(selectEdit);
  //For adding value
  const ResponsiveGridLayout = WidthProvider(Responsive);
  const layoutConfig: any = LayoutStructure.DataSource;
  const breakpoints = { lg: 1104, md: 804, sm: 672, xs: 480, xxs: 0 };
  const cols = { lg: 12, md: 8, sm: 4, xs: 2, xxs: 1 };
  useEffect(() => {
    // Add the class to the body tag
    document.body.classList.add("clair-pp-bg");

    // Cleanup: remove the class when the component is unmounted
    return () => {
      document.body.classList.remove("clair-pp-bg");
    };
  }, []);

  const currentUser = user;
  let defaultLayout = layoutConfig;

  let savedLayout = localStorage.getItem("layouts");
  if (savedLayout != null) {
    defaultLayout = JSON.parse(savedLayout);
  } else {
    localStorage.setItem("layouts", JSON.stringify(defaultLayout));
  }
  // layout is an array of objects, see the demo for more complete usage

  const newLayout = defaultLayout[currentUser];
  const [scrWidth, setScrWidth] = useState<any>("lg");

  const onBreakpointChange = (newBreakpoint: string) => {
    let size = newBreakpoint === "xxs" ? "xs" : newBreakpoint;
    setScrWidth(() => size);
  };

  const resetState = useSelector(selectAppReset);

  useEffect(() => {}, [resetState.isAppReset]);

  const handleDragStop = (layout: any) => {
    // Update the layout state with the new position
    let userLayout = newLayout.map((element: any) => {
      layout.forEach((newEle: any) => {
        if (newEle.i === element.i) {
          element.size[scrWidth].x = newEle.x;
          element.size[scrWidth].y = newEle.y;
        }
      });
      return element;
    });

    // Save the layout to storage
    const modifiedLayout = { app: userLayout };
    localStorage.setItem("edit-layouts", JSON.stringify(modifiedLayout));
  };

  const onResizeStop = (_clayout: any, _oldItem: any, newItem: any) => {
    let userLayoutItem = newLayout.filter((a: any) => a.i === newItem.i)[0];
    let userLayout = newLayout;
    let index = userLayout.findIndex((a: any) => a.i === newItem.i);
    userLayoutItem.size[scrWidth].w = newItem.w;
    userLayoutItem.size[scrWidth].h = newItem.h;
    userLayout[index] = userLayoutItem;
    updateSize(newItem);
    const modifiedLayout = { app: userLayout };
    localStorage.setItem("edit-layouts", JSON.stringify(modifiedLayout));
  };

  const updateSize = (newItem: any) => {
    if (newItem.h < 5 || newItem.w < 3) {
      dispatch(ResizeWidget({ name: newItem.i, value: "xs" }));
    }
    if (
      (newItem.h >= 5 && newItem.h < 6) ||
      (newItem.w >= 3 && newItem.w < 5)
    ) {
      dispatch(ResizeWidget({ name: newItem.i, value: "s" }));
    }
    if (
      (newItem.h >= 6 && newItem.h < 7) ||
      (newItem.w >= 5 && newItem.w < 7)
    ) {
      dispatch(ResizeWidget({ name: newItem.i, value: "m" }));
    }
    if (newItem.h >= 6 || newItem.w >= 6) {
      dispatch(ResizeWidget({ name: newItem.i, value: "m" }));
    }
  };
  return (
    <>
      <div className="px-3">
        <MemberDetails />
      </div>
      <ResponsiveGridLayout
        className={`layout ${false ? "animate-pulse" : ""}`}
        rowHeight={30}
        compactType={"vertical"}
        breakpoints={breakpoints}
        onBreakpointChange={onBreakpointChange}
        cols={cols}
        margin={[16, 16]}
        autoSize={true}
        onDragStop={handleDragStop}
        onResizeStop={onResizeStop}
        draggableHandle=".drag-handle"
        isBounded={true}
      >
        {newLayout
          ?.filter((a: any) => a.i !== "topHeader")
          ?.map((element: any, index: number) => {
            return (
              <div
                className={"drag-handle rounded-xl border " + element.i}
                key={element.i}
                data-grid={{
                  x: element.size[scrWidth].x,
                  y: element.size[scrWidth].y,
                  w: element.size[scrWidth].w,
                  h: element.size[scrWidth].h,
                  maxW: element.size[scrWidth].maxW,
                  maxH: element.size[scrWidth].maxH,
                  minW: element.size[scrWidth].minW,
                  minH: element.size[scrWidth].minH,
                  isResizable: element.resizable && layoutMode.isEdit,
                  isDraggable: layoutMode.isEdit,
                }}
              >
                <ClairLayoutItem
                  scrWidth={scrWidth}
                  element={element}
                  index={index}
                  key={index}
                  layoutMode={layoutMode.isEdit}
                />
              </div>
            );
          })}
      </ResponsiveGridLayout>
    </>
  );
};

export default ClairLayout;
