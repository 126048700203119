import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectFilter } from "../../Redux/features/filterDataTableSlice";
import { AppointmentService } from "../../services/AppointmentService";
import { GenericTable } from "../generic-components/GenericTable";

export const Problems = ({ config, expanded }: any) => {
  const filterSelector = useSelector(selectFilter);
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
      } else {
        setData([]);
      }
    };
    fetchData();
  }, [id]);

  const filterData = (filterdata: any) => {
    const filterValue = expanded ? config.filterValue : filterSelector.filters[config.i];

    if (filterValue && filterValue !== "All") {
      filterdata = filterdata?.filter((a: any) => a[config.filterColumn] === filterValue);
    }

    return filterdata;
  };

  const formatedData = data?.map((x: any) => {
    let each = { ...x };
    config?.cols
      ?.filter((a: any) => a.valueClassName)
      .forEach((element: any) => {
        each["className"] = each["className"] ?? {};
        let actCol = element.col ?? element.valueCol;
        if (each?.[actCol]) {
          each["className"][actCol] = `${element.valueClassName?.toLowerCase()}-${each?.[actCol]?.toLowerCase().replace(" ", "-")}`;
        }
      });
    return each;
  });

  const resultData = filterData(formatedData) ?? [];

  return <GenericTable payload={resultData} config={config} expanded={expanded} />;
};
