import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSyncLoading } from "../Redux/features/syncLoadingDoneSlice";
import ConfirmationPopUP from "../components/pop-ups/confirmation-pop-up/ConfirmationPopUp";
import CustomDialog from "../components/pop-ups/custom-dialog/CustomDialog";
import QRCodeGenerator from "../components/qrcode-generator/QRCodeGenerator";
import { DateTimeConstant } from "../data/constants/DateTimeConstants";
import { AppointmentService } from "../services/AppointmentService";
import type { MenuModel } from "../types/types";
import AppSubMenu from "./AppSubMenu";

const AppMenu = () => {
  const [isLogoutConfirmationVisible, setIsLogoutConfirmationVisible] = useState(false);
  const [is2FADialogVisible, setIs2FADialogVisible] = useState(false);

  const handleTwoFactorAuthClick = () => {
    setIs2FADialogVisible(true);
  };

  const handleLogoutClick = () => {
    setIsLogoutConfirmationVisible(true);
  };

  const handleLogoutConfirmation = () => {
    setIsLogoutConfirmationVisible(false);
    try {
      sessionStorage.removeItem("offline-auth");
      window.location.replace("/#/otp-login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleCancelLogout = () => {
    setIsLogoutConfirmationVisible(false);
  };

  const { getAll } = AppointmentService();
  const [appointments, setAppointments] = useState([]);
  const syncloadingString = useSelector(selectSyncLoading);

  const model: MenuModel[] = [
    {
      label: "Appointments",
      icon: "cl_patient",
      to: "/pages/appointment-details",
      items: [],
    },
    {
      label: "User Profile",
      icon: "cl_user",
      items: [
        {
          label: "2F Authentication",
          icon: "cl_tfa",
          command: handleTwoFactorAuthClick,
        },
        {
          label: "Logout",
          icon: "cl_log_off_2",
          command: handleLogoutClick,
        },
      ],
    },
  ];

  useEffect(() => {
    const dbSet = async () => {
      let latestAppointments = await getAll(moment().format(DateTimeConstant.DF));
      latestAppointments.sort((a: any, b: any) => {
        return 1 * a["start"].localeCompare(b["start"]);
      });
      setAppointments(latestAppointments);
    };
    dbSet();
  }, [syncloadingString]);

  if (appointments.length > 0) {
    model[0].items = [
      {
        title: "Today's Appointment",
        label: "",
      },
    ];
    // If there are upcoming appointments, populate the model
    model[0]?.items?.push(
      ...appointments.map((item: any) => ({
        label: `${item.memberName} - ${moment.utc(item?.start).local().format("hh:mm A")}`,
        icon: "cl_patient",
        to: `/pages/appointment-details/${item.id}`,
      })),
    );
  } else {
    model[0]?.items?.push({
      label: "No Appointments Today",
    });
  }

  const twofaHeader = () => {
    return (
      <div className="flex w-fill-available items-center justify-start">
        <div className="!w-8 !h-8 inline-flex items-center justify-center rounded-full flex-shrink-0 header-icon">
          <i className="cl_tfa text-2xl text-primary"></i>
        </div>
        <div className="pl-3 flex items-center justify-items-center">
          <div className="expanded-popup-header-value">2 Factor Authentication</div>
        </div>
      </div>
    );
  };

  const twoFADiagContent = () => {
    return (
      <div className="flex flex-col !gap-10">
        <div className="text-primary text-xl">
          Please use any of the recommended Authenticator apps (Microsoft/Google/Okta) to scan the QR code and generate your PIN.
        </div>
        <div className="flex justify-center">{<QRCodeGenerator />}</div>
      </div>
    );
  };

  return (
    <>
      <ConfirmationPopUP
        visible={isLogoutConfirmationVisible}
        header="Are you sure you want to logout?"
        onConfirm={handleLogoutConfirmation}
        onCancel={handleCancelLogout}
      />
      <CustomDialog
        visible={is2FADialogVisible}
        header={twofaHeader()}
        content={twoFADiagContent()}
        onHide={() => setIs2FADialogVisible(false)}
        maximizable={false}
        className={"w-[560px]"}
        maximized={false}
        resizable={false}
        draggable={false}
      />
      {/*
      <CustomDialog
        visible={isOfflinePinRegistration}
        header={offlinePinHeader()}
        content={offlinePinContent()}
        onHide={() => setIsOfflinePinRegistration(false)}
        maximizable={false}
        className={"w-[496px] offline-pin-registration"}
        maximized={false}
        resizable={false}
        draggable={false}
      /> */}

      <AppSubMenu model={model} />
    </>
  );
};

export default AppMenu;
