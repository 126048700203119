import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { useDispatch, useSelector } from "react-redux";
import {
  AppResetLayout,
  selectAppReset,
} from "../../Redux/features/appResetSlice";
import { EditLayout, selectEdit } from "../../Redux/features/editModeSlice";
import { SetLayoutState } from "../../Redux/features/layoutStateSlice";

const AppLayoutSetting = () => {
  const dispatch = useDispatch();
  const appReset = useSelector(selectAppReset);
  const layoutMode = useSelector(selectEdit);
  // const layoutExportDialogVisible = useSelector(selectLayoutExportDialog);
  const handleEdit = (mode: boolean) => {
    dispatch(EditLayout(mode));
    dispatch(
      SetLayoutState({
        configSidebarVisible: false,
      })
    );
  };

  // const handleExportDialogVisible = () => {
  //   dispatch(LayoutExportDialog(!layoutExportDialogVisible.isVisible));
  // };

  const handleReset = () => {
    localStorage.removeItem("edit-layouts");
    localStorage.removeItem("layouts");
    dispatch(AppResetLayout(!appReset.isAppReset));
  };

  return (
    <>
      {layoutMode.isEdit ? (
        <div className="flex items-center justify-center">
          <Message
            className="w-full justify-content-start !border-l-8"
            severity="info"
            text="You're on Layout edit mode"
          />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          {/* <Button
            label="Export Layout"
            rounded
            icon="cl_export_file_xl"
            aria-label="Export Layout"
            title="Export Layout"
            className="w-full"
            severity="info"
            onClick={() => handleExportDialogVisible()}
          /> */}
          <Button
            label="Edit Layout"
            rounded
            icon="cl_create"
            aria-label="Edit Layout"
            title="Edit Layout"
            className="w-full"
            severity="help"
            onClick={() => handleEdit(true)}
          />
          <Button
            label="Reset Layout"
            rounded
            icon="cl_dashboard_fill"
            aria-label="Reset Layout"
            title="Reset Layout"
            className="w-full"
            severity="danger"
            onClick={() => handleReset()}
          />
        </div>
      )}
    </>
  );
};

export default AppLayoutSetting;
