import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Breadcrumb } from "../../types/types";

interface BreadcrumbState {
  breadcrumb: Breadcrumb | null;
}

const initialState: BreadcrumbState = {
  breadcrumb: null,
};

const breadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    setBreadcrumb: (state, action: PayloadAction<Breadcrumb | null>) => {
      state.breadcrumb = action.payload;
    },
  },
});

export const { setBreadcrumb } = breadcrumbSlice.actions;
export const selectBreadcrumb = (state: { breadcrumb: BreadcrumbState }) =>
  state.breadcrumb.breadcrumb;

export default breadcrumbSlice.reducer;
