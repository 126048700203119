import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { FilterDateStateModel } from "../../model/visitTimeline-filter-model";

const initialState: FilterDateStateModel = {
  visitDate: "",
};

const visitSlice = createSlice({
  name: "visitDate",
  initialState,
  reducers: {
    FilterVisitDate: (state, action) => {
      state.visitDate = action.payload;
    },
  },
});

export const { FilterVisitDate } = visitSlice.actions;
export const selectVisitDate = (state: RootState) => state.visitDate;

export default visitSlice.reducer;