import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { FilterDataStateModel } from "../../model/filter-model";

const initialState: FilterDataStateModel = {
  filters: {
    topHeader: "All",
    clinicianAlerts: "All",
    contactPreferences: "All",
    labResults: "All",
    advanceDirective: "All",
    specialist: "All",
    memberStatusFlag: "All",
    awvDueDate: "All",
    stratification: "All",
    problems: "Active",
    medication: "All",
    screenings: "All",
    allergies: "All",
    qualityManagement: "All",
    vitals: "",
    visitsTimeline: "",
    communication: "All",
    notes: "All",
    appointments: "All",
    riskScore: "All",
    mraGaps: "All",
    hpi: "All",
  },
};

const filterDataTableModeSlice = createSlice({
  name: "filterDataTableMode",
  initialState,
  reducers: {
    FilterDataTable: (
      state: FilterDataStateModel,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = action.payload;
      state.filters[name] = value;
    },
  },
});
export const { FilterDataTable } = filterDataTableModeSlice.actions;
export const selectFilter = (state: RootState) => state.filterDataTableMode;

export default filterDataTableModeSlice.reducer;
