import moment from "moment";
import { DateTimeConstant } from "../../../data/constants/DateTimeConstants";
import { placeHolder } from "../../../data/constants/HelperClass";

const MemberOfficeDetails = ({ data, widgetsConfig }: any) => {
  const memberOfficeDetails: any[] = widgetsConfig;
  return (
    <div className="flex flex-col gap-2 set-scroll-h touch-pan-y pb-2">
      <div className="title-text">Office Details</div>
      {data?.map((each: any, i: number) => {
        return (
          <div className="flex flex-col !gap-3" key={`i${i}`}>
            {memberOfficeDetails.map((item, index) => {
              const fieldValue = each[item.field];
              const formattedValue =
                item.dataType === "Date" && fieldValue
                  ? moment(fieldValue).format(DateTimeConstant.DF)
                  : fieldValue;
              return (
                <div className="flex flex-col" key={index}>
                  <div className="label-text">{item?.title}</div>
                  <div className="value-text">
                    {placeHolder(formattedValue)}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
export default MemberOfficeDetails;
