import { createSlice } from "@reduxjs/toolkit";
import { PinFocusChangeModel } from "../../model/pin-focus-model";
import { RootState } from "../app/store";

const initialState: PinFocusChangeModel = {
  isPinFocused: false,
};

const pinFocusSlice = createSlice({
  name: "pinFocus",
  initialState,
  reducers: {
    FocusPin: (state, action) => {
      state.isPinFocused = action.payload;
    },
  },
});

export const { FocusPin } = pinFocusSlice.actions;
export const selectFocusPin = (state: RootState) => state.pinFocus;

export default pinFocusSlice.reducer;
