import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { AppStatusLayout } from "../Redux/features/appStatusSlice";

export const useCheckWithMinimalAPI = () => {
  const dispatch = useDispatch();
  const loadingRef = useRef(false);

  const checkWithMinimalAPI = useCallback(async () => {
    const prefix = process.env.REACT_APP_SERVICE_URI ?? "";

    const liveSessionUrl = `${prefix}/service/isOnline`;

    loadingRef.current = true;
    try {
      const res = await fetch(liveSessionUrl);
      if (res.ok) {
        dispatch(AppStatusLayout(true));
      } else {
        dispatch(AppStatusLayout(false));
      }
    } catch (error) {
      dispatch(AppStatusLayout(false));
    } finally {
      loadingRef.current = false;
    }
  }, []);

  return { checkWithMinimalAPI, loading: loadingRef.current };
};
