import { API_ENDPOINTS } from "../data/constants/AppConstants";
import { IIdentityServerResponseApplicationUser } from "../model/common/identity-server-response-model";

export const useClientUserService = () => {
  const userTokenInfoString = localStorage.getItem("UserTokenInfo");
  let UserId: string;
  let response: IIdentityServerResponseApplicationUser;
  if (userTokenInfoString !== undefined && userTokenInfoString !== null) {
    UserId = JSON.parse(userTokenInfoString ?? "{}")?.profile?.sub ?? null;
  }
  const ClientName = process.env.REACT_APP_CLIENT_ID;
  const tokenInfo = JSON.parse(localStorage.getItem("UserTokenInfo") ?? "{}");
  const token = tokenInfo?.access_token;

  const getClientUserMapping = async (): Promise<IIdentityServerResponseApplicationUser | null> => {
    if (!UserId) {
      console.error("UserId is missing");
      return null;
    }

    try {
      const requestBody = JSON.stringify({ UserId, ClientName });
      const apiUrl = `${process.env.REACT_APP_AUTHORITY}${API_ENDPOINTS.GET_CLIENTUSER_SERVICE_ENDPOINT}`;

      const res = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: requestBody,
      });

      if (!res.ok || res.status === 204) {
        throw new Error(`HTTP/HTTPS error! status: ${res.status}`);
      } else {
        response = await res.json();
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  return { getClientUserMapping };
};
