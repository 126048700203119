import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectFilter } from "../../Redux/features/filterDataTableSlice";
import NoDataAvailable from "../../components/no_record_found/NoDataAvailable";
import { DateTimeConstant } from "../../data/constants/DateTimeConstants";
import { placeHolder } from "../../data/constants/HelperClass";
import DateTimeSplitter from "../../layout/hooks/DateSplitter";
import { AppointmentService } from "../../services/AppointmentService";

export const LabResults = ({ config, expanded }: any) => {
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();
  const enablePagination = expanded && data?.length > 0;

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  const filterSelector = useSelector(selectFilter);

  const filterData = (data: any) => {
    const filterValue = expanded
      ? config.filterValue
      : filterSelector.filters[config.i];

    if (filterValue && filterValue !== "All") {
      data = data.filter((a: any) => a[config.filterColumn] === filterValue);
    }

    return data;
  };

  const formatedData = data?.map((x: any) => {
    let each = { ...x };
    config?.cols
      ?.filter((a: any) => a.valueClassName)
      .forEach((element: any) => {
        each["className"] = each["className"] ?? {};
        let actCol = element.col ?? element.valueCol;
        if (each?.[actCol]) {
          const lastChar = each?.[actCol]?.slice(-1).toLowerCase();
          each["className"][
            actCol
          ] = `${element.valueClassName?.toLowerCase()}-${lastChar}`;
        }
      });

    return each;
  });

  const resultData = filterData(formatedData ?? []);

  const headerTemplate = (dataData: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span className="test-type">{dataData.testName}</span>
      </div>
    );
  };

  const columnBodyTemplate = (item: any, colConfig: any) => {
    let actCol = colConfig.valueCol ?? colConfig.col;
    let value = item[actCol];
    return colConfig.dataType === "Date" ? (
      colConfig.dataFormat === "DateTime" ? (
        <span className={item?.className?.[actCol] ?? ""}>
          {value
            ? moment.utc(value).local().isValid()
              ? DateTimeSplitter(value)
              : value
            : "N/A"}
        </span>
      ) : (
        <span className={item?.className?.[actCol] ?? ""}>
          {value
            ? moment.utc(value).local().isValid()
              ? moment.utc(value).local().format(DateTimeConstant.DF)
              : value
            : "N/A"}
        </span>
      )
    ) : (
      <span className={item?.className?.[actCol] ?? ""}>
        {placeHolder(value)}
      </span>
    );
  };

  return (
    <DataTable
      value={resultData}
      paginator={enablePagination}
      rows={10}
      first={0}
      totalRecords={resultData?.length}
      sortField={config.sortField ?? "date"}
      sortOrder={config.sortOrder ?? -1}
      scrollable
      scrollHeight={"flex"}
      rowGroupHeaderTemplate={headerTemplate}
      removableSort
      sortMode={"single"}
      rowGroupMode="subheader"
      groupRowsBy="testName"
      tableStyle={{ minWidth: "50rem" }}
      showHeaders={config.showHeaders}
      emptyMessage={<NoDataAvailable />}
    >
      {config.cols
        .filter((a: any) => !a.hideInWidget || expanded)
        .map((x: any, i: any) => (
          <Column
            field={x.col}
            sortable={x.isSortable}
            style={{ width: `${x.width}rem` }}
            header={x.display}
            dataType={x.dataType ?? "string"}
            className={x.className ?? ""}
            body={(e) => columnBodyTemplate(e, x)}
            key={"eachkey-" + x + i}
          ></Column>
        ))}
    </DataTable>
  );
};
