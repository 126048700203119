import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import "swiper/css";
import "swiper/css/bundle";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { RootState } from "../../Redux/app/store";
import { FilterDataTable } from "../../Redux/features/filterDataTableSlice";
import { SetWidgetDropDown } from "../../Redux/features/widgetDropDownSlice";
import NoDataAvailable from "../../components/no_record_found/NoDataAvailable";
import { placeHolder } from "../../data/constants/HelperClass";
import { AppointmentService } from "../../services/AppointmentService";

interface VitalsProps {
  config: any;
}

const makeClassName = (col: string, value: string) => {
  if (col === "priority") {
    var result = "";
    if (value !== undefined)
      result =
        col.split(" ").join("-").toLowerCase() + "-" + value.toLowerCase();
    return result;
  } else {
    return "";
  }
};

const Vitals = ({ config }: VitalsProps) => {
  const dispatch = useDispatch();
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
        ///
        const vitalDateValue: any = result
          ?.filter((a: any) => moment(a.date).isValid())
          ?.map((eachResult: any) =>
            moment(eachResult.date).format("MMM DD, YYYY")
          );
        const distinctValues = [
          ...Array.from(
            new Set<any>(vitalDateValue?.map((date: string) => date))
          ),
        ];
        const sortedDateArray = distinctValues
          .sort((a: any, b: any) =>
            new Date(b).toISOString().localeCompare(new Date(a).toISOString())
          )
          .slice(0, 5);

        const dropDownValues = sortedDateArray?.map((eachDate) => {
          return {
            label: eachDate,
            value: eachDate,
          };
        });
        dispatch(SetWidgetDropDown({ key: config.i, data: dropDownValues }));
        dispatch(
          FilterDataTable({ name: config.i, value: dropDownValues?.[0]?.value })
        );
        ///
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  if (data?.length > 0) {
    for (var i = 0; i < data.length; i++) {
      data[i].date = moment(data[i].date).format("MMM DD, YYYY");
    }
  }

  const filterValue = useSelector(
    (state: RootState) => state.filterDataTableMode.filters[config.i]
  );

  const activeData = data?.filter(
    (a: any) => a.date === moment(filterValue).format("MMM DD, YYYY")
  )?.[0];

  const feildConfig = config?.dataset?.map((a: any) => {
    let each = { ...a };
    if (activeData) {
      if (a.header === "Pulse") {
        let pulse = parseFloat(activeData[a.field]);
        let status = pulse > 100 ? "High" : pulse < 60 ? "Low" : "Normal";
        each["priority"] = activeData[a.field] ? status : "";
      }

      if (a.header === "BMI") {
        let bmi = parseFloat(activeData[a.field]);
        let status = bmi > 24.9 ? "High" : bmi < 18.5 ? "Low" : "Normal";
        each["priority"] = activeData[a.field] ? status : "";
      }

      if (a.header === "BP") {
        let bp = parseFloat(activeData[a.field]);
        let status = bp > 130 ? "High" : bp < 110 ? "Low" : "Normal";
        each["priority"] = activeData[a.field] ? status : "";
      }

      if (a.header === "Temp") {
        let temp = parseFloat(activeData[a.field]);
        let status = temp > 98.9 ? "High" : temp < 97.5 ? "Low" : "Normal";
        each["priority"] = activeData[a.field] ? status : "";
      }

      if (a.header === "RR") {
        let rr = parseFloat(activeData[a.field]);
        let status = rr > 18 ? "High" : rr < 12 ? "Low" : "Normal";
        each["priority"] = activeData[a.field] ? status : "";
      }

      if (a.header === "O₂") {
        let o2 = parseFloat(activeData[a.field]);
        let status = o2 > 100 ? "High" : o2 < 95 ? "Low" : "Normal";
        each["priority"] = activeData[a.field] ? status : "";
      }
      
      return each;
    }
    return null;
  });

  return data?.length > 0 ? (
    <div className="flex h-full items-center">
      <Swiper
        // install Swiper modules
        className=""
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={5}
        navigation={false}
        pagination={false}
        scrollbar={false}
        breakpoints={{
          0: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 6,
          },
          900: {
            slidesPerView: 7,
          },
          1024: {
            slidesPerView: 7,
          },
          1366: {
            slidesPerView: 6,
          },
          1501: {
            slidesPerView: 5,
          },
        }}
      >
        {feildConfig?.map((vital: any, index: any) => {
          return (
            <SwiperSlide className="text-center" key={index}>
              <div className="swiper-header tracking-widest mb-1">
                {vital?.header}
                <sub>{vital?.sub}</sub>
              </div>
              <div className="swiper-value">
                {placeHolder(activeData?.[vital?.field])}
                {activeData?.[vital?.field] != null && (
                  <span className="swiper-type">{vital?.type}</span>
                )}
              </div>
              <div
                className={`flex justify-center swiper-priority ${makeClassName(
                  "priority",
                  vital?.priority
                )}`}
              >
                {vital?.priority && (
                  <i
                    className={`${
                      vital?.priority === "High"
                        ? "cl_arrow_up_fill"
                        : vital?.priority === "Low"
                        ? "cl_arrow_down_fill"
                        : "cl_normal_dash"
                    }`}
                  ></i>
                )}
                <span>{vital?.priority}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  ) : (
    <NoDataAvailable height="60" width="60" />
  );
};

export default Vitals;
