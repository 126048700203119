import { UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";

const oidcConfig: UserManagerSettings = {
  authority: process.env.REACT_APP_AUTHORITY ?? "",
  client_id: process.env.REACT_APP_CLIENT_ID ?? "",
  client_secret: process.env.REACT_APP_MEMBER_APP_OIDC_CLIENTSECRET,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI ?? "",
  response_type: process.env.REACT_APP_RESPONSE_TYPE,
  scope: process.env.REACT_APP_SCOPE,
  post_logout_redirect_uri: `${process.env.REACT_APP_API_URI}/#/${process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI}`,
  automaticSilentRenew: Boolean(process.env.REACT_APP_AUTOMATIC_SILENT_RENEW),
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URI,
  monitorSession: Boolean(process.env.REACT_APP_MONITOR_SESSION),
  checkSessionIntervalInSeconds: Number(process.env.REACT_APP_INTERVEL_SESSION),
  loadUserInfo: Boolean(process.env.REACT_APP_LOAD_USER_INFO),  
  accessTokenExpiringNotificationTimeInSeconds: Number(
    process.env.REACT_APP_ACCESS_TOKEN_EXPIRING_NOTIFICATION_TIME
  ),
  //   revokeAccessTokenOnSignout: process.env.REACT_APP_REVOKE_ACCESS_TOKEN_ON_SIGNOUT,
  filterProtocolClaims: Boolean(process.env.REACT_APP_FILTER_PROTOCOL_CLAIMS),
  includeIdTokenInSilentRenew: Boolean(
    process.env.REACT_APP_INCULDE_ID_TOKEN_IN_SILENT_RENEW
  ),
     userStore: new WebStorageStateStore({ store: window.localStorage })
  //   refresh_token_expiration: process.env.REACT_APP_ACCESS_TOKEN_EXPIRING_NOTIFICATION_TIME,
};

export default oidcConfig;

export const METADATA_OIDC = {
  issuer: process.env.REACT_APP_AUTHORITY,
  jwks_uri:
    process.env.REACT_APP_AUTHORITY + ".well-known/openid-configuration/jwks",
  authorization_endpoint: process.env.REACT_APP_AUTHORITY + "connect/authorize",
  token_endpoint: process.env.REACT_APP_AUTHORITY + "connect/token",
  userinfo_endpoint: process.env.REACT_APP_AUTHORITY + "connect/userinfo",
  end_session_endpoint: process.env.REACT_APP_AUTHORITY + "connect/endsession",
  check_session_iframe:
    process.env.REACT_APP_AUTHORITY + "connect/checksession",
  revocation_endpoint: process.env.REACT_APP_AUTHORITY + "connect/revocation",
  introspection_endpoint:
    process.env.REACT_APP_AUTHORITY + "connect/introspect",
};
