import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ActiveAppointmentsDate } from "../../model/active-appointments-date";
import moment from "moment";

const initialState: ActiveAppointmentsDate = {
  activeAppintmentsDate: moment().format("MM-DD-YYYY"),
};

const activeAppintmentsDateSlice = createSlice({
  name: "activeAppintmentsDate",
  initialState,
  reducers: {
    SetAppointmentDate: (state, action) => {
      state.activeAppintmentsDate = action.payload;
    },
  },
});

export const { SetAppointmentDate } = activeAppintmentsDateSlice.actions;
export const selectActiveAppointmentsDate = (state: RootState) => state.activeAppintmentsDate;

export default activeAppintmentsDateSlice.reducer;
