import React, { useState } from "react";
import type {
  ChildContainerProps,
  LayoutContextProps,
  Breadcrumb,
} from "../../types/types";
import { useDispatch, useSelector } from "react-redux";
import {
  SetLayoutState,
  selectLayoutState,
} from "../../Redux/features/layoutStateSlice";
import { selectLayoutConfig } from "../../Redux/features/layoutConfigSlice";

export const LayoutContext = React.createContext({} as LayoutContextProps);

export const LayoutProvider = (props: ChildContainerProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  //default layout configuration

  const dispatch = useDispatch();
  const layoutState = useSelector(selectLayoutState);
  const layoutConfig = useSelector(selectLayoutConfig);

  const onMenuToggle = () => {
    if (isOverlay()) {
      dispatch(
        SetLayoutState({
          overlayMenuActive: !layoutState.overlayMenuActive,
        })
      );
    }

    if (isDesktop()) {
      dispatch(
        SetLayoutState({
          staticMenuDesktopInactive: !layoutState.staticMenuDesktopInactive,
        })
      );
    } else {
      dispatch(
        SetLayoutState({
          staticMenuMobileActive: !layoutState.staticMenuMobileActive,
        })
      );
    }
  };

  const showConfigSidebar = () => {
    dispatch(SetLayoutState({ configSidebarVisible: true }));
  };

  const showProfileSidebar = () => {
    dispatch(SetLayoutState(layoutState.profileSidebarVisible));
  };

  const isOverlay = () => {
    return layoutConfig.menuMode === "overlay";
  };

  const isSlim = () => {
    return layoutConfig.menuMode === "slim";
  };

  const isSlimPlus = () => {
    return layoutConfig.menuMode === "slim-plus";
  };

  const isHorizontal = () => {
    return layoutConfig.menuMode === "horizontal";
  };

  const isDesktop = () => {
    return window.innerWidth > 991;
  };

  const value = {
    layoutConfig,
    layoutState,
    onMenuToggle,
    showConfigSidebar,
    showProfileSidebar,
    isSlim,
    isSlimPlus,
    isHorizontal,
    isDesktop,
    breadcrumbs,
    setBreadcrumbs,
  };

  return (
    <LayoutContext.Provider value={value}>
      <>{props.children}</>
    </LayoutContext.Provider>
  );
};
