import { createSlice } from "@reduxjs/toolkit";
import { IToasterInfo } from "../../model/toaster-model";
import { RootState } from "../app/store";

const initialState: IToasterInfo = {
  summary: "",
  severity: undefined,
  detail: "",
};

const toasterInfoSlice = createSlice({
  name: "toasterInfo",
  initialState,
  reducers: {
    SetMessage: (state, action) => {
      state.summary = action.payload?.summary;
      state.severity = action.payload?.severity;
      state.detail = action.payload?.detail;
    },
  },
});

export const { SetMessage } = toasterInfoSlice.actions;
export const selectToasterMessage = (state: RootState) =>
  state.toasterInfo;

export default toasterInfoSlice.reducer;
