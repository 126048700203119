import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ExpandModeModel } from "../../model/expan-mode-model";

const initialState: ExpandModeModel = {
  isVisible: false,
};

const expandModeSlice = createSlice({
  name: "expandMode",
  initialState,
  reducers: {
    VisibleLayout: (state, action) => {
      state.isVisible = action.payload;
    },
  },
});

export const { VisibleLayout } = expandModeSlice.actions;
export const selectVisible = (state: RootState) => state.expandMode;

export default expandModeSlice.reducer;
