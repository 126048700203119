// PinInput.tsx

import React, { ChangeEvent, KeyboardEvent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { offlinePinInfo, selectOfflinePin } from "../../Redux/features/offlinePinSlice";
import { selectFocusPin } from "../../Redux/features/pinFocusSlice";

interface PinInputProps {
  pin: string;
  pinLength: number;
  onPinChange: (pin: string) => void;
  onSubmit: () => void;
}

export class IOfflinePin {
  pin?: string;
  isPinVerified: boolean = false;
}

const PinInput: React.FC<PinInputProps> = ({ pin, pinLength, onPinChange, onSubmit }) => {
  const dispatch = useDispatch();
  const pinData: IOfflinePin = useSelector(selectOfflinePin);
  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(pinLength).fill(null));
  const pinFocused = useSelector(selectFocusPin);

  const focusPinInput = () => {
    inputRefs.current[0]?.focus();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    const { key, shiftKey } = event;
    const newPin = pin.split("");
    const currentValue = newPin[index];
    if (key === "Enter") {
      // Call onSubmit callback when Enter key is pressed
      if (pin.length === pinLength) {
        onSubmit();
        focusPinInput();
      }
    } else if (key === "Backspace") {
      // Handle backspace or delete: clear the current input value
      if (currentValue) {
        inputRefs.current[index]?.focus();
      } else if (!currentValue) {
        inputRefs.current[index - 1]?.focus();
      }
    } else if (key === "Tab" && !shiftKey) {
      // Prevent moving to the next input on Tab
      event.preventDefault();
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLInputElement>, index: number) => {
    // Prevent selecting the input with no value using the mouse, except for the first input
    if (!pin[index - 1] && index > 0) {
      event.preventDefault();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;
    // Allow only numeric input and limit to pinLength
    const sanitizedValue = value.replace(/\D/g, "").slice(0, 1);
    const sanitizedNextValue = value.replace(/\D/g, "").slice(1, 2);

    if (sanitizedValue !== "") {
      // Move the cursor to the next input if available
      if (index < pinLength - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }

    const newPin = pin.split("");
    newPin[index] = sanitizedValue;
    if (index < pinLength - 1 && sanitizedNextValue) {
      newPin[index + 1] = sanitizedNextValue;
    }
    onPinChange(newPin.join(""));

    //dispatch the pin to redux
    const Pin = pinData?.pin?.split("") ?? [];
    Pin[index] = sanitizedValue;
    const result = Pin.join("");
    dispatch(offlinePinInfo({ pin: result }));
  };

  useEffect(() => {
    focusPinInput();
  }, [pinFocused.isPinFocused]);

  useEffect(() => {
    if (!pin) {
      focusPinInput();
    }
  }, [pin]);

  return (
    <div className={`pin-input-wrapper`}>
      {[...Array(pinLength)].map((_, index) => (
        <input
          key={index}
          type="password"
          maxLength={2}
          value={pin[index] || ""}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onMouseDown={(e) => handleMouseDown(e, index)}
          ref={(input) => (inputRefs.current[index] = input)}
          className="input-label"
        />
      ))}
    </div>
  );
};

export default PinInput;
