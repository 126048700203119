import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ActiveUserStateModel } from "../../model/active-user-model";

const initialState: ActiveUserStateModel = {
  user: "app",
};

const activeUserSlice = createSlice({
  name: "activeUser",
  initialState,
  reducers: {
    ActiveUserInfo: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { ActiveUserInfo } = activeUserSlice.actions;
export const selecActiveUser = (state: RootState) => state.activeUser;

export default activeUserSlice.reducer;
