import React from "react";
import moment from "moment";
import { DateTimeConstant } from "../../../data/constants/DateTimeConstants";
import { placeHolder } from "../../../data/constants/HelperClass";

const MemberInsuranceDetails = ({ data, widgetsConfig }: any) => {
  const memberInsuranceDetails: any[] = widgetsConfig;
  const insuranceName = memberInsuranceDetails[0];
  const insuranceDetails = memberInsuranceDetails.slice(1);
  return (
    <div className="flex flex-col gap-2 set-scroll-h touch-pan-y pb-2">
      <div className="title-text">Insurance Details</div>
      {data?.map((each: any, i: number) => {
        return (
          <React.Fragment key={`i${i}`}>
            <div className="flex flex-col gap-2">
              <div className="title-text">{each?.[insuranceName.field]}</div>
            </div>
            <div className="!grid grid-cols-2 flex-wrap gap-3">
              {insuranceDetails.map((item, index) => {
                const fieldValue = each[item.field];
                const formattedValue =
                  item.dataType === "Date" && fieldValue
                    ? moment(fieldValue).format(DateTimeConstant.DF)
                    : fieldValue;
                return (
                  <div className="flex flex-col" key={index}>
                    <div className="label-text">{item?.title}</div>
                    <div className="value-text">
                      {placeHolder(formattedValue)}
                    </div>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
export default MemberInsuranceDetails;
