"use client";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import React, { useContext, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { SetLayoutState } from "../Redux/features/layoutStateSlice";
import type { AppMenuItemProps } from "../types/types";
import { LayoutContext } from "./context/layoutcontext";
import { MenuContext } from "./context/menucontext";
import { useSubmenuOverlayPosition } from "./hooks/useSubmenuOverlayPosition";

const AppMenuitem = (props: AppMenuItemProps) => {
  const pathname = ""; // path should be added
  const { activeMenu, setActiveMenu } = useContext(MenuContext);
  const { isSlim, isSlimPlus, isHorizontal, isDesktop, layoutState, layoutConfig } = useContext(LayoutContext);
  const submenuRef = useRef<HTMLUListElement>(null);
  const menuitemRef = useRef<HTMLLIElement>(null);
  const item = props.item;
  const key = props.parentKey ? props.parentKey + "-" + props.index : String(props.index);
  const isActiveRoute = item!.to && pathname === item!.to;
  const active = activeMenu === key || !!(activeMenu && activeMenu.startsWith(key + "-"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useSubmenuOverlayPosition({
    target: menuitemRef.current,
    overlay: submenuRef.current,
    container: menuitemRef.current && menuitemRef.current.closest(".layout-menu-container"),
    when: props.root && active && (isSlim() || isSlimPlus() || isHorizontal()) && isDesktop(),
  });

  useEffect(() => {
    if (layoutState.resetMenu) {
      setActiveMenu("");
      dispatch(
        SetLayoutState({
          resetMenu: false,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutState?.resetMenu]);

  useEffect(() => {
    if (!(isSlim() || isSlimPlus() || isHorizontal()) && isActiveRoute) {
      setActiveMenu(key);
    }
    const url = pathname; // + searchParams.toString();
    const onRouteChange = () => {
      if (!(isSlim() || isHorizontal()) && item!.to && item!.to === url) {
        setActiveMenu(key);
      }
    };
    onRouteChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pathname,

    // searchParams,

    layoutConfig,
  ]);

  const itemClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    //avoid processing disabled items
    if (item!.disabled) {
      event.preventDefault();
      return;
    }

    if (item?.to) {
      if (!isNaN(parseInt(item.to))) {
        // dispatch(SwitchDataBase(item?.to));
      } else {
        navigate(item?.to);
      }
    }

    //execute command
    if (item?.command) {
      item?.command({ originalEvent: event, item: item });
    }

    // toggle active state
    if (item?.items) {
      setActiveMenu(key);
      // setActiveMenu(active ? props.parentKey! : key);
      // commented for mouse events
      if (props.root && !active && (isSlim() || isHorizontal() || isSlimPlus())) {
        dispatch(
          SetLayoutState({
            overlaySubmenuActive: true,
          }),
        );
      }
    } else {
      if (!isDesktop()) {
        dispatch(
          SetLayoutState({
            staticMenuMobileActive: !layoutState.staticMenuMobileActive,
          }),
        );
      }

      dispatch(
        SetLayoutState({
          menuHoverActive: false,
        }),
      );
      setActiveMenu(key);
    }
  };

  const handleHover = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (!item?.to || !item?.items?.length) {
      return;
    }
    if (item!.disabled) {
      //avoid processing disabled items
      event.preventDefault();
      return;
    }

    // navigate with hover
    if (props.root && (isSlim() || isHorizontal() || isSlimPlus())) {
      const isSubmenu = event.currentTarget.closest(".layout-root-menuitem.active-menuitem > ul") !== null;
      if (isSubmenu)
        dispatch(
          SetLayoutState({
            menuHoverActive: true,
          }),
        );
      else
        dispatch(
          SetLayoutState({
            menuHoverActive: !layoutState.menuHoverActive,
          }),
        );
    }

    //execute command
    if (item?.command) {
      item?.command({ originalEvent: event, item: item });
    }

    // toggle active state
    if (item?.items) {
      setActiveMenu(key);
      // setActiveMenu(active ? props.parentKey! : key);
      // commented for mouse events
      if (props.root && !active && (isSlim() || isHorizontal() || isSlimPlus())) {
        dispatch(
          SetLayoutState({
            overlaySubmenuActive: true,
          }),
        );
      }
    } else {
      if (!isDesktop()) {
        dispatch(
          SetLayoutState({
            staticMenuMobileActive: !layoutState.staticMenuMobileActive,
          }),
        );
      }

      if (isSlim() || isSlimPlus() || isHorizontal()) {
        dispatch(
          SetLayoutState({
            menuHoverActive: false,
          }),
        );
      }

      setActiveMenu(key);
    }
  };
  const Mouseleave = () => {
    setActiveMenu("");
  };

  const subMenu =
    item?.items && item?.visible !== false ? (
      <ul ref={submenuRef} onMouseLeave={Mouseleave}>
        {item?.items.map((child, i) => {
          return <AppMenuitem item={child} index={i} className={child.badgeClass} parentKey={key} key={child.label} />;
        })}
      </ul>
    ) : null;

  return (
    <li
      ref={menuitemRef}
      className={classNames({
        "layout-root-menuitem": props.root,
        "active-menuitem": active,
        "upcoming-appointments": item?.label === "Appointments",
        "user-profile": item?.label === "User Profile",
      })}
    >
      {props.root && item?.visible !== false && (
        <div className="layout-menuitem-root-text">
          <a href={item?.url} onClick={(e) => itemClick(e)} className="flex justify-start items-center gap-2 cursor-pointer">
            <i className={classNames("layout-menuitem-icon", item?.icon)}></i>
            <span className="layout-menuitem-text text-sm">{item?.label}</span>
          </a>
        </div>
      )}
      {item?.visible !== false ? (
        <>
          {item?.title ? (
            <React.Fragment>
              {!layoutState.sidebarActive && !layoutState.anchored && layoutConfig.menuMode === "drawer" ? (
                <div className="menu-section-title flex flex-row pb-2 border-b border-primary mb-2 font-medium">Upc.. Appt.</div>
              ) : (
                <div className="menu-section-title flex flex-row pb-2 border-b border-primary mb-2 font-medium">
                  <i className={classNames("layout-menuitem-icon", item?.titleIcon)}></i>
                  {item?.title}
                </div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <a
                href={item?.url}
                onMouseEnter={(e) => handleHover(e)}
                className={classNames(item?.class, "p-ripple tooltip-target")}
                target={item?.target}
                data-pr-tooltip={item?.label}
                data-pr-disabled={!(isSlim() && props.root && !layoutState.menuHoverActive)}
                tabIndex={0}
                onClick={(e) => itemClick(e)}
              >
                {item?.icon && <i className={classNames("layout-menuitem-icon", item?.icon)}></i>}
                <span className="layout-menuitem-text text-sm">{item?.label}</span>
                {item?.items?.length && item?.items?.length > 0 && <i className="cl_arrow_down_line layout-submenu-toggler"></i>}
                <Ripple />
              </a>
            </React.Fragment>
          )}
        </>
      ) : null}
      {subMenu}
    </li>
  );
};

export default AppMenuitem;
