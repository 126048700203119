import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";

interface ConfirmationPopUPProps {
  visible: boolean;
  header?: string;
  onConfirm: () => void;
  onCancel: () => void;
  message?: any;
  className?: string;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmationPopUP: React.FC<ConfirmationPopUPProps> = ({
  visible,
  header,
  onConfirm,
  onCancel,
  message,
  className,
  confirmText = "Confirm", // Set default value for confirmText
  cancelText = "Cancel", // Set default value for cancelText
}) => {
  return (
    <ConfirmDialog
      visible={visible}
      closable={false}
      draggable={false}
      header={header}
      headerClassName={`text-primary font-medium text-xl text-center ${
        header ? "" : "p-2"
      }`}
      contentClassName={message ? "" : "p-0"}
      message={message}
      className={className}
      footer={
        <div className="flex flex-row items-center justify-center !gap-6">
          <Button
            label={confirmText}
            className="py-2 m-0"
            aria-label={confirmText}
            rounded
            onClick={onConfirm}
          />
          <Button
            label={cancelText}
            aria-label={cancelText}
            rounded
            className="py-2 m-0"
            outlined
            onClick={onCancel}
          />
        </div>
      }
    ></ConfirmDialog>
  );
};

export default ConfirmationPopUP;