import { Navigate, createHashRouter } from "react-router-dom";
import ClairLayout from "../clair-layout/ClairLayout";
import AppointmentDetails from "../components/appointment-details/AppointmentDetails";
import { AuthCallBack } from "../components/auth/authCallback";
import Logout from "../components/logout/logout";
import NotFound from "../components/not-found/NotFound";
import OfflineLogin from "../components/offline-login/OfflineLogin";
import ResetPassword from "../components/reset-password/ResetPassword";
import CommonLayout from "../layout/common-layout/CommonLayout";
import ProtectedLayout from "../layout/hooks/protected-layout";

const baseRoutes = [
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/auth-callback-logout",
    element: <Navigate to="/" replace />,
  },
  {
    path: "/",
    element: localStorage.getItem("UserTokenInfo") ? <ProtectedLayout /> : <AuthCallBack />,
    children: [
      {
        path: "pages",
        element: <CommonLayout />,
        children: [
          {
            path: "appointment-details",
            element: <AppointmentDetails />,
          },
          {
            path: "appointment-details/:id",
            element: <ClairLayout user="app" />,
          },
          {
            path: "",
            element: <Navigate to="appointment-details" replace />,
          },
        ],
      },
      {
        path: "",
        element: <Navigate to="pages/appointment-details" replace />,
      },
    ],
  },
  {
    path: "/otp-login",
    element: <OfflineLogin />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
];

const notFoundRoute = {
  path: "*",
  element: <NotFound />,
};

export const routes = [...baseRoutes, notFoundRoute];

export const AppRouter = createHashRouter(routes);
