import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import oidcConfig, { METADATA_OIDC } from "../config/oidc-config";

const authService = () => {
  const UserManagerInstance = new UserManager({
    ...oidcConfig,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    metadata: {
      ...METADATA_OIDC,
    },
  });

  UserManagerInstance.events.addUserLoaded((user) => {
    localStorage.setItem("UserTokenInfo", JSON.stringify(user));
    if (window.location.href.indexOf("auth-callback") !== -1) {
      navigateToScreen();
    }
  });

  // UserManagerInstance.events.addUserSignedOut(() => {
  //   logoutExternal();
  // });

  UserManagerInstance.events.addSilentRenewError((e) => {
    console.log("silent renew error", e.message);
  });

  UserManagerInstance.events.addAccessTokenExpiring(() => {
    signinSilent();
  });

  const signinSilent = () => {
    UserManagerInstance.signinSilent()
      .then((user) => {
        localStorage.setItem("UserTokenInfo", JSON.stringify(user));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const signinSilentCallback = () => {
    UserManagerInstance.signinSilentCallback();
  };

  const signinRedirectCallback = async () => {
    await UserManagerInstance.signinRedirectCallback();
  };

  const getUser = () => {
    UserManagerInstance.getUser().then((user) => {
      if (!user) {
        return UserManagerInstance.signinRedirectCallback();
      }
      return user;
    });
  };

  const parseJwt = (token: any) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  const signinRedirect = () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    UserManagerInstance.signinRedirect().then(() => {
      localStorage.setItem("loginCalled", "true");
    });
  };

  const navigateToScreen = async () => {
    localStorage.setItem("UserLoggedInStatus", "true");
    window.location.replace("/");
  };

  const isAuthenticated = () => {
    const token = localStorage.getItem("UserTokenInfo");
    return token !== null && token !== undefined;
  };

  const logout = () => {
    const userToken = localStorage.getItem("UserTokenInfo");
    const idToken = userToken ? JSON.parse(userToken).id_token : null;
    UserManagerInstance.signoutRedirect({ id_token_hint: idToken });
    UserManagerInstance.clearStaleState();
  };

  const clearSessionStorage = () => {
    sessionStorage.clear();
  };

  const clearAuthSession = () => {
    sessionStorage.removeItem("offline-auth");
  };

  const logoutInternal = () => {
    logout();
    removeUser();
    clearSessionStorage();
    localStorage.clear();
    localStorage.setItem("hasUserDismissed", "true");
    window.location.replace(`${process.env.REACT_APP_API_URI}`);
  };

  // const logoutExternal = () => {
  //   UserManagerInstance.clearStaleState();
  //   localStorage.clear();
  //   localStorage.setItem("hasUserDismissed", "true");
  //   clearSessionStorage();
  //   window.location.replace("/");
  // };

  const signoutRedirectCallback = () => {
    UserManagerInstance.signoutRedirectCallback().then(() => {
      localStorage.clear();
    });
    UserManagerInstance.clearStaleState();
    localStorage.setItem("hasUserDismissed", "true");
  };

  const removeUser = () => {
    UserManagerInstance.removeUser();
  };

  return {
    signinSilent,
    signinSilentCallback,
    signinRedirectCallback,
    getUser,
    parseJwt,
    signinRedirect,
    isAuthenticated,
    logout,
    logoutInternal,
    signoutRedirectCallback,
    removeUser,
    clearAuthSession,
  };
};

export default authService;
