import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { LayoutConfig } from "../../types/layout";

const initialState: LayoutConfig = {
  ripple: true,
  inputStyle: "outlined",
  menuMode: "slim-plus",
  menuTheme: "colorScheme",
  colorScheme: "light",
  theme: "clair",
  scale: 16,
};

const layoutConfigSlice = createSlice({
  name: "layoutThemeSlice",
  initialState,
  reducers: {
    SetLayoutConfig: (state, action) => {
      if (action.payload?.ripple !== undefined) {
        state.ripple = action.payload.ripple;
      }
      if (action.payload?.inputStyle) {
        state.inputStyle = action.payload.inputStyle;
      }
      if (action.payload?.menuMode) {
        state.menuMode = action.payload.menuMode;
      }
      if (action.payload?.menuTheme) {
        state.menuTheme = action.payload.menuTheme;
      }
      if (action.payload?.colorScheme) {
        state.colorScheme = action.payload.colorScheme;
      }
      if (action.payload?.theme) {
        state.theme = action.payload.theme;
      }
      if (action.payload?.scale) {
        state.scale = action.payload.scale;
      }
    },
  },
});

export const { SetLayoutConfig } = layoutConfigSlice.actions;

export const selectLayoutConfig = (state: RootState) => state.layoutConfig;

export default layoutConfigSlice.reducer;
