import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import activeAppointmentsDateReducer from "../features/activeAppointmentsDateSlice";
import activeAppointmentIdReducer from "../features/activeAppointmentSlice";
import activeUserReducer from "../features/activeUserSlice";
import appResetReducer from "../features/appResetSlice";
import appStatusReducer from "../features/appStatusSlice";
import breadcrumbReducer from "../features/breadcrumbSlice";
import confirmationDialogReducer from "../features/confirmationSlice";
import confirmRefreshDataReducer from "../features/confirmRefreshDataSlice";
import editModeReducer from "../features/editModeSlice";
import expandItemReducer from "../features/expandedItemSlice";
import expandModeReducer from "../features/expandModeSlice";
import visitDateReducer from "../features/filterDataForVisitTimeline";
import filterDataTableModeReducer from "../features/filterDataTableSlice";
import vitalDateReducer from "../features/filterDateForVitalsSlice";
import layoutConfigReducer from "../features/layoutConfigSlice";
import layoutExportDailogModeReducer from "../features/layoutExportDailogModeSlice";
import layoutStateReducer from "../features/layoutStateSlice";
import offlinePinReducer from "../features/offlinePinSlice";
import pinFocusReducer from "../features/pinFocusSlice";
import refreshDataReducer from "../features/refreshDataSlice";
import resizeWidgetReducer from "../features/resizeSetSizeSlice";
import switchDataReducer from "../features/switchDataSlice";
import syncLoadingDoneReducer from "../features/syncLoadingDoneSlice";
import toasterInfoReducer from "../features/toasterInfoSlice";
import widgetDropDownReducer from "../features/widgetDropDownSlice";

const persistConfig = {
  key: "root",
  storage,
};

const reducer = combineReducers({
  editMode: editModeReducer,
  switchData: switchDataReducer,
  layoutExportDialogVisible: layoutExportDailogModeReducer,
  activeUser: activeUserReducer,
  resizeWidgetMode: resizeWidgetReducer,
  expandItem: expandItemReducer,
  expandMode: expandModeReducer,
  syncLoadingDone: syncLoadingDoneReducer,
  filterDataTableMode: filterDataTableModeReducer,
  appStatus: appStatusReducer,
  appReset: appResetReducer,
  layoutConfig: layoutConfigReducer,
  layoutState: layoutStateReducer,
  vitalDate: vitalDateReducer,
  visitDate: visitDateReducer,
  pinFocus: pinFocusReducer,
  breadcrumb: breadcrumbReducer,
  confirmationDialogVisible: confirmationDialogReducer,
  offlinePinState: offlinePinReducer,
  activeAppintment: activeAppointmentIdReducer,
  activeAppintmentsDate: activeAppointmentsDateReducer,
  widgetDropDowns: widgetDropDownReducer,
  refreshData: refreshDataReducer,
  confirmRefreshData: confirmRefreshDataReducer,
  toasterInfo: toasterInfoReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
