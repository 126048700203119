import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import NoDataAvailable from "../../components/no_record_found/NoDataAvailable";
import { DateTimeConstant } from "../../data/constants/DateTimeConstants";
import { placeHolder } from "../../data/constants/HelperClass";
import DateTimeSplitter from "../../layout/hooks/DateSplitter";

export const GenericTable = ({ payload, config, expanded }: any) => {
  const tblData = payload;
  const totalRecords = tblData?.length ?? 0;
  const enablePagination = expanded && totalRecords > 0;
  const [first, setFirst] = useState(0); // Current page
  const [rows, setRows] = useState(10); // Rows per page

  useEffect(() => {
    // Reset pagination to first page when filter value changes
    setFirst(0);
  }, [expanded, config.filterValue]);

  const onPage = (e: any) => {
    setFirst(e.first);
    setRows(e.rows);
  };

  const headerTemplate = (dataData: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span className="test-type">{dataData.testType}</span>
      </div>
    );
  };

  const columnBodyTemplate = (item: any, colConfig: any) => {
    let actCol = colConfig.valueCol ?? colConfig.col;
    let value = item[actCol];
    return colConfig.dataType === "Date" ? (
      colConfig.dataFormat === "DateTime" ? (
        <span className={item?.className?.[actCol] ?? ""}>
          {value ? (moment.utc(value).local().isValid() ? DateTimeSplitter(value) : value) : "N/A"}
        </span>
      ) : (
        <span className={item?.className?.[actCol] ?? ""}>
          {value ? (moment.utc(value).local().isValid() ? moment.utc(value).local().format(DateTimeConstant.DF) : value) : "N/A"}
        </span>
      )
    ) : (
      <span className={item?.className?.[actCol] ?? ""}>{placeHolder(value)}</span>
    );
  };

  return (
    <DataTable
      value={tblData}
      sortField={config.sortField}
      sortOrder={config.sortOrder ?? 1}
      scrollable
      scrollHeight={"flex"}
      paginator={enablePagination}
      rows={rows}
      first={first}
      totalRecords={totalRecords}
      onPage={onPage}
      rowGroupHeaderTemplate={headerTemplate}
      removableSort
      sortMode={config.sortMode ?? "single"}
      showHeaders={config.showHeaders}
      emptyMessage={<NoDataAvailable />}
      className={`${tblData?.length === 0 ? "no-data-available" : ""}`}
    >
      {config.cols
        ?.filter((a: any) => !a.hideInWidget || expanded)
        ?.map((x: any, i: any) => (
          <Column
            field={x.col}
            sortable={x.isSortable}
            style={{ width: `${x.width}rem` }}
            header={x.display}
            dataType={x.dataType ?? "string"}
            className={x.className ?? ""}
            body={(e) => columnBodyTemplate(e, x)}
            key={"eachkey-" + x + i}
          ></Column>
        ))}
    </DataTable>
  );
};
