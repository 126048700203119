"use client";
import { Button } from "primereact/button";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAppStatus } from "../../Redux/features/appStatusSlice";
import { currentYear } from "../../data/constants/HelperClass";
import type { Page } from "../../types/layout";
import { useAuth } from "../auth/authProvider";

const Logout: Page = () => {
  localStorage.setItem("hasUserDismissed", "true");
  const auth = useAuth();
  const userTokenInfoString = localStorage.getItem("UserTokenInfo") ? JSON.parse(localStorage.getItem("UserTokenInfo") ?? "{}") : null;
  const isOnline = useSelector(selectAppStatus);

  const handleContinue = () => {
    auth.logoutInternal();
  };

  useEffect(() => {
    if (userTokenInfoString !== null && Date.now() / 1000 < userTokenInfoString?.expires_at) {
      return window.location.replace("/");
    }
  }, []);

  return (
    <div className="logout-page">
      <div className="flex place-content-center items-center h-screen justify-between p-10 lg:p-24 xl:p-60 ml-auto">
        <div className="self-center flex w-full justify-center">
          <div className="bg-white flex flex-col rounded-xl px-12 py-14 !gap-8">
            <div className="!px-6 flex flex-col !gap-7 justify-stretch">
              <div className="items-center flex flex-row justify-center">
                <img className="self-end" src="/svg/logo_full.svg" alt="powered by aaneel" />
              </div>
              <div className="flex flex-col items-center">
                <div className="login-text font-medium text-[2rem]">Session Expired</div>
                <div className="logout-text mt-3">You have been logged out.</div>
                <div className="gray-text font-normal text-base mt-3">Click 'Continue' to log in again.</div>
                <div className="font-normal yellow-text text-base mt-1">Note: User needs to be Online</div>
              </div>
              <div className="flex items-center justify-center">
                <Button
                  size="small"
                  className="w-[18.875rem] text-base font-medium"
                  label="Continue"
                  rounded
                  disabled={!isOnline}
                  onClick={handleContinue}
                />
              </div>
            </div>
            <div className="flex flex-row !gap-3 justify-center">
              <div className="self-end leading-none gray-text text-xs">© {currentYear} AaNeel Infotech LLC</div>
              <div className="relative">
                <div className="gray-text absolute right-0 -top-[0.5rem] text-[0.625rem]">Powered by</div>
                <img className="self-end" src="/svg/aaneel-poweredby.svg" alt="powered by aaneel" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
