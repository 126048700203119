// src/store/confirmationSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ConfirmationDialogVisibleStateModel } from "../../model/confirmation-dialog-model";

const initialState: ConfirmationDialogVisibleStateModel = {
  isVisible: false,
};

const confirmationDialogSlice = createSlice({
  name: "confirmationDialogVisible",
  initialState,
  reducers: {
    ConfirmationDialog: (state, action) => {
      state.isVisible = action.payload;
    },
  },
});

export const { ConfirmationDialog } = confirmationDialogSlice.actions;
export const selectConfirmationDialog = (state: RootState) =>
  state.confirmationDialogVisible;

export default confirmationDialogSlice.reducer;
