import { Dialog } from "primereact/dialog";

interface CustomDialogProps {
  visible: boolean;
  header: React.ReactNode;
  content: React.ReactNode;
  onHide: () => void;
  maximizable?: boolean;
  className?: string;
  maximized?: boolean;
  resizable?: boolean;
  draggable?: boolean;
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  visible,
  header,
  content,
  onHide,
  maximizable,
  className,
  maximized,
  resizable,
  draggable,
}) => {
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={header}
      headerClassName="!py-4 !mb-4"
      modal
      maximizable={maximizable}
      className={"expanded-pop-up-wrap " + className}
      maximized={maximized}
      closeIcon={"cl_close_circle_line"}
      resizable={resizable}
      draggable={draggable}
      maximizeIcon={"cl_maximize_popup"}
    >
      {content}
    </Dialog>
  );
};

export default CustomDialog;
