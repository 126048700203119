import moment from "moment";
import { useContext } from "react";
import AuthContext from "../components/auth/authProvider";
import { API_ENDPOINTS } from "../data/constants/AppConstants";
import { DateTimeConstant } from "../data/constants/DateTimeConstants";
import {
  SyncCompletedMessage,
  SyncErrorMessage,
  SyncNoDataFound,
  SyncTryAgainMessage,
  SyncUnauthorizedMessage,
} from "../data/constants/SyncToasterMessages";
import { AppDatabase } from "../database/AppDB";
import SyncReponseModel from "../model/sync-response-model";

export const SyncService = () => {
  const context = useContext(AuthContext);
  const db = new AppDatabase();

  const storeInLocalDb = async (data: any[]) => {
    const initData = data?.[0];
    if (initData) {
      await db.transaction("rw", db.appCollection, async () => {
        await db.appCollection.add({ id: 1, data: initData });
      });
    }
  };

  const updateInLocalDb = async (data: any[], startDate: string, endDate: string) => {
    const tokenInfo = JSON.parse(localStorage.getItem("UserTokenInfo") ?? "{}");
    const userID = tokenInfo?.profile?.sub;
    await db.transaction("rw", db.appCollection, async () => {
      const document = await db.appCollection.get(1);

      if (document) {
        const userInfoIndex = document?.data?.userInfo.findIndex((info: any) => info.userID === userID);
        if (userInfoIndex === -1) {
          const resultUserInfoIndex = data?.[0]?.userInfo.findIndex((info: any) => info.userID === userID);

          const newUserAppointments = data?.[0]?.userInfo?.[resultUserInfoIndex]?.userAppointments;
          const newUserData = {
            userID: userID,
            userAppointments: newUserAppointments ?? [],
          };
          document.data.userInfo.push(newUserData);
          await db.appCollection.put(document);
        }

        if (userInfoIndex !== -1) {
          const userAppointments = document.data.userInfo[userInfoIndex].userAppointments;
          document.data.userInfo[userInfoIndex].userAppointments = userAppointments?.filter(
            (eachAppointment: any) => moment(eachAppointment.start) < moment(startDate) || moment(eachAppointment.start) > moment(endDate),
          );

          const resultUserInfoIndex = data?.[0]?.userInfo?.findIndex((info: any) => info.userID === userID) ?? [];

          data?.[0]?.userInfo?.[resultUserInfoIndex]?.userAppointments?.forEach((element: any) => {
            document.data.userInfo[userInfoIndex].userAppointments.push(element);
          });
          await db.appCollection.put(document);
        } else {
          console.error("userInfo with specified userId not found.");
        }
      } else {
        console.error("Document not found.");
      }
    });
  };

  // const update3DaysDataInLocalDb = async (data: any[]) => {
  //   const tokenInfo = JSON.parse(localStorage.getItem("UserTokenInfo") ?? "{}");
  //   const userID = tokenInfo?.profile?.sub;
  //   try {
  //     await db.transaction("rw", db.appCollection, async () => {
  //       const document = await db.appCollection.get(1); // Assuming document with ID 1

  //       if (document) {
  //         const userInfoIndex = document?.data?.userInfo.findIndex(
  //           (info: any) => info.userID === userID
  //         );

  //         if (userInfoIndex === -1) {
  //           const resultUserInfoIndex = data?.[0]?.userInfo.findIndex(
  //             (info: any) => info.userID === userID
  //           );

  //           const newUserAppointments =
  //             data?.[0]?.userInfo?.[resultUserInfoIndex]?.userAppointments ??
  //             [];
  //           const newUserData = {
  //             userID: userID,
  //             userAppointments: newUserAppointments,
  //           };
  //           document.data.userInfo.push(newUserData);
  //           await db.appCollection.put(document);
  //         }

  //         if (userInfoIndex !== -1) {
  //           document.data.userInfo[userInfoIndex].userAppointments = [];

  //           const resultUserInfoIndex =
  //             data?.[0]?.userInfo.findIndex(
  //               (info: any) => info.userID === userID
  //             ) ?? [];

  //           data[0]?.userInfo?.[resultUserInfoIndex]?.userAppointments?.forEach(
  //             (element: any) => {
  //               document.data.userInfo[userInfoIndex].userAppointments.push(
  //                 element
  //               );
  //             }
  //           );
  //           await db.appCollection.put(document);
  //         } else {
  //           console.error("userInfo with specified userId not found.");
  //         }
  //       } else {
  //         console.error("Document not found.");
  //       }
  //     });
  //   } catch (ex: any) {}
  // };

  // const clearIndexedDB = async () => {
  //   await db.appCollection.clear();
  // };

  const SyncData = async (startDate: string, endDate: string): Promise<SyncReponseModel> => {
    const prefix = process.env.REACT_APP_SERVICE_URI ?? "";
    const apiUrl = `${prefix}/service${API_ENDPOINTS.SYNC_SERVICE_ENDPOINT}`;

    let message: string = "";
    let severity: "success" | "info" | "warn" | "error" | undefined = undefined;
    //Need to clean this code
    const tokenInfo = JSON.parse(localStorage.getItem("UserTokenInfo") ?? "{}");
    const token = tokenInfo?.access_token;

    //Get the data of date which user requested whether it's available in indexed db or not
    try {
      let res = await fetch(`${apiUrl}?startDate=${startDate}&endDate=${endDate}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200 || res.status === 204) {
        const result = await res.json();
        const apiData: any[] =
          result?.data?.map((a: any) => {
            return a.deviceInfo;
          }) ?? [];

        const docCount = await db.appCollection.count();
        if (docCount === 0) {
          // await clearIndexedDB();
          await storeInLocalDb(apiData);
        } else {
          await updateInLocalDb(apiData, startDate, endDate);
        }
        severity = "success";
        message = apiData.length === 0 ? SyncNoDataFound : SyncCompletedMessage;
      } else if (res.status === 401) {
        severity = "warn";
        context?.signinSilent();
        message = SyncTryAgainMessage;
      } else if (res.status === 403) {
        severity = "warn";
        message = SyncUnauthorizedMessage;
      } else {
        severity = "error";
        message = SyncErrorMessage;
      }
    } catch (ex: any) {
      severity = "error";
      message = SyncErrorMessage;
    } finally {
      if (message) {
        localStorage.setItem("LastSyncOn", moment().format(DateTimeConstant.DTF));
      }
      return { message, severity };
    }
  };

  return { SyncData };
};
