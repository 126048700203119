import moment from "moment";
import { Button } from "primereact/button";
import { Timeline } from "primereact/timeline";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import "swiper/css";
import "swiper/css/bundle";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { RootState } from "../../Redux/app/store";
import { FilterDataTable } from "../../Redux/features/filterDataTableSlice";
import { SetWidgetDropDown } from "../../Redux/features/widgetDropDownSlice";
import NoDataAvailable from "../../components/no_record_found/NoDataAvailable";
import { AppointmentService } from "../../services/AppointmentService";

interface Visit {
  date: string;
  visitType: string;
  scheduledWith: string;
  visitReason?: string;
}

interface VisitTimelineProps {
  config?: any;
}

const VisitTimeline: React.FC<VisitTimelineProps> = ({ config }: VisitTimelineProps) => {
  const dispatch = useDispatch();

  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result ?? []);

        ///
        const vitalDateValue: any = result?.map((result: any) => {
          let each = { ...result };
          return moment(each.date).format("YYYY");
        });

        const distinctValues: number[] = [...Array.from(new Set<number>(vitalDateValue?.map((date: string) => parseInt(date))))];

        const sortedDateArray = distinctValues.sort((a, b) => b - a);

        const dropDownValues = sortedDateArray?.map((eachYear) => {
          return {
            label: String(eachYear),
            value: String(eachYear),
          };
        });
        dispatch(SetWidgetDropDown({ key: config.i, data: dropDownValues }));
        dispatch(FilterDataTable({ name: config.i, value: dropDownValues?.[0]?.value }));
        ///
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);
  if (data?.length > 0) {
    for (var i = 0; i < data.length; i++) {
      data[i].date = moment(data[i].date).format("MM/DD/YYYY");
    }
  }

  const filterValue = useSelector((state: RootState) => state.filterDataTableMode.filters[config.i]);

  useEffect(() => {
    setSelectedFilter("All");
  }, [filterValue]);

  const [selectedFilter, setSelectedFilter] = useState<string>("All");

  const filteredData = [...data?.filter((val: any) => moment(val.date).format("YYYY") === filterValue)];

  const visitTypeFilters: string[] = ["All", ...Array.from(new Set(filteredData?.map((visit: Visit) => visit.visitType)))];

  const handleFilterSelect = (filter: string) => {
    setSelectedFilter(filter);
  };

  const dateContent = (visit: Visit) => (
    <div className="bg-primary h-fit w-24 px-2 py-1 rounded text-center text-white">{moment(visit.date).format("MM/DD/YYYY")}</div>
  );

  const customizedContent = (visit: Visit) => (
    <div className="flex items-start timeline-right-details !pb-2 !mb-1">
      <div className="flex-grow">
        <div className="flex flex-row !gap-3 self-center">
          <div className="text-sm font-medium text-primary mb-1">{visit.visitType}</div>
          <div className="text-primary">
            <i className="cl_dot_big text-xs"></i>
          </div>
          <div className="text-sm font-medium text-primary mb-1">{visit.scheduledWith}</div>
        </div>
        <div className="text-sm title-font-900 ">{visit.visitReason ? visit.visitReason : "N/A"}</div>
      </div>
    </div>
  );

  const sortedData = filteredData.sort((a: Visit, b: Visit) => moment(b.date).valueOf() - moment(a.date).valueOf());

  return sortedData?.length > 0 ? (
    <div className="flex flex-col timeline-content">
      <div className="sticky top-0 z-[1]">
        <Swiper
          spaceBetween={0}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          slidesPerView={5}
          navigation={false}
          pagination={false}
          scrollbar={false}
        >
          {visitTypeFilters?.map((visitType: string, index: number) => (
            <SwiperSlide key={index}>
              <Button
                label={visitType}
                onClick={() => handleFilterSelect(visitType)}
                outlined={selectedFilter === visitType ? false : true}
                rounded
                size="small"
                className={selectedFilter === visitType ? "active" : ""}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Timeline
        value={selectedFilter === "All" ? sortedData : sortedData.filter((visit: Visit) => visit.visitType === selectedFilter)}
        opposite={dateContent}
        content={customizedContent}
      />
    </div>
  ) : (
    <NoDataAvailable />
  );
};

export default VisitTimeline;
