import { useEffect, useState } from "react";
import { useParams } from "react-router";
import sanitizeText from "../../layout/hooks/senitizeData";
import { AppointmentService } from "../../services/AppointmentService";
import { GenericTable } from "../generic-components/GenericTable";

export const Notes = ({ config, expanded }: any) => {
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  // const resultData = data?.map((x: any) => {
  //   let each = { ...x };
  //   config?.cols
  //     ?.filter((a: any) => a.dataType === "Date")
  //     .forEach((element: any) => {
  //       each[element.col] = each?.[element.col]
  //         ? utcConversionDateTime(
  //           moment(each?.[element.col], DateTimeConstant.API_DTF).format(
  //             DateTimeConstant.DTF
  //           )
  //         )
  //         : each?.[element.col];
  //     });
  //   each.noteDesc = sanitizeText(each.noteDesc);
  //   return each;
  // });

  const resultData = data?.map((x: any) => {
    const each: any = { ...x };
    config?.cols?.filter((a: any) => a.dataType === "Date");

    if (each.noteDesc !== null) {
      each.noteDesc = sanitizeText(each.noteDesc);
    }
    return each;
  });

  return <GenericTable payload={resultData} config={config} expanded={expanded} />;
};
