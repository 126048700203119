import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { LayoutState } from "../../types/layout";

const initialState: LayoutState = {
  staticMenuDesktopInactive: false,
  overlayMenuActive: false,
  profileSidebarVisible: false,
  overlaySubmenuActive: false,
  configSidebarVisible: false,
  staticMenuMobileActive: false,
  menuHoverActive: false,
  resetMenu: false,
  sidebarActive: false,
  anchored: false,
};

const layoutStateSlice = createSlice({
  name: "layoutStateSlice",
  initialState,
  reducers: {
    SetLayoutState: (state, action) => {
      if (action.payload?.staticMenuDesktopInactive !== undefined) {
        state.staticMenuDesktopInactive =
          action.payload.staticMenuDesktopInactive;
      }
      if (action.payload?.overlayMenuActive !== undefined) {
        state.overlayMenuActive = action.payload.overlayMenuActive;
      }
      if (action.payload?.profileSidebarVisible !== undefined) {
        state.profileSidebarVisible = action.payload.profileSidebarVisible;
      }
      if (action.payload?.overlaySubmenuActive !== undefined) {
        state.overlaySubmenuActive = action.payload.overlaySubmenuActive;
      }
      if (action.payload?.configSidebarVisible !== undefined) {
        state.configSidebarVisible = action.payload.configSidebarVisible;
      }
      if (action.payload?.staticMenuMobileActive !== undefined) {
        state.staticMenuMobileActive = action.payload.staticMenuMobileActive;
      }
      if (action.payload?.menuHoverActive !== undefined) {
        state.menuHoverActive = action.payload.menuHoverActive;
      }
      if (action.payload?.resetMenu !== undefined) {
        state.resetMenu = action.payload.resetMenu;
      }
      if (action.payload?.sidebarActive !== undefined) {
        state.sidebarActive = action.payload.sidebarActive;
      }
      if (action.payload?.anchored !== undefined) {
        state.anchored = action.payload.anchored;
      }
    },
  },
});

export const { SetLayoutState } = layoutStateSlice.actions;

export const selectLayoutState = (state: RootState) => state.layoutState;

export default layoutStateSlice.reducer;
