import { ProgressSpinner } from "primereact/progressspinner";

const Loader = () => {
  return (
    <div className="flex place-content-center items-center h-screen w-screen justify-between">
      <div className="self-center flex flex-col justify-between items-center m-auto gap-4">
        <div className="relative">
          <ProgressSpinner
            animationDuration="2s"
            strokeWidth="3"
          ></ProgressSpinner>
          <img
            src="/svg/logo.svg"
            alt="Logo"
            className="!w-12 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-60%] absolute"
          />
        </div>
        <div className="loader-message"></div>
      </div>
    </div>
  );
};

export default Loader;
