import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { SwitchDataStateModel } from "../../model/switch-data-model";

const initialState: SwitchDataStateModel = {
  activeDB: 0,
};

const switchDataSlice = createSlice({
  name: "switchData",
  initialState,
  reducers: {
    SwitchDataBase: (state, action) => {
      state.activeDB = action.payload;
    },
  },
});

export const { SwitchDataBase } = switchDataSlice.actions;
export const selectDB = (state: RootState) => state.switchData;

export default switchDataSlice.reducer;
