import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

const useIdle = ({ idleTime }: any): any => {
  const [isIdle, setIsIdle] = useState(false);

  const handleOnIdle = () => {
    setIsIdle(true);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * idleTime,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return { getRemainingTime, getLastActiveTime, isIdle, setIsIdle };
};

export default useIdle;
