import * as OTPAuth from "otpauth";
import { useSelector } from "react-redux";
import { selectOfflinePin } from "../Redux/features/offlinePinSlice";
import { REGEX } from "../data/constants/AppConstants";

const sanitizeUsername = (username: string) => {
  return username.replace(REGEX.ALLOW_ONLY_CHARS, "");
};

const useTotpService = () => {
  const offlinePin = useSelector(selectOfflinePin);
  const userTokenInfoString = localStorage.getItem("UserTokenInfo");
  let username: string, issuer: string, sanitizedUsername: string;
  if (userTokenInfoString !== undefined && userTokenInfoString !== null) {
    username = JSON.parse(userTokenInfoString ?? "{}")?.profile?.given_name ?? null;
    issuer = JSON.parse(userTokenInfoString ?? "{}")?.profile?.aud ?? null;
    sanitizedUsername = sanitizeUsername(username);
  }
  const GetTotpCode = async () => {
    if (sanitizedUsername) {
      let totp = new OTPAuth.TOTP({
        issuer: issuer,
        label: issuer,
        algorithm: process.env.REACT_APP_TOTP_ALGORITHM,
        digits: 6,
        period: 30,
        secret: OTPAuth.Secret.fromBase32(`${process.env.REACT_APP_SECRET}` + sanitizedUsername),
      });
      let totpCode = totp.generate();
      const token = String(offlinePin.pin);
      let delta = totp.validate({ token, window: 1 });
      if (delta != null) {
        return totpCode;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const GetURI = () => {
    if (sanitizedUsername) {
      let totp = new OTPAuth.TOTP({
        issuer: issuer,
        label: username,
        algorithm: process.env.REACT_APP_TOTP_ALGORITHM,
        digits: 6,
        period: 30,
        secret: OTPAuth.Secret.fromBase32(`${process.env.REACT_APP_SECRET}` + sanitizedUsername),
      });
      let URI = totp.toString();
      if (URI != null) {
        return URI;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  return { GetTotpCode, GetURI };
};

export default useTotpService;
