export class LayoutStructure {
  public static DataSource = {
    app: [
      {
        i: "topHeader",
        icon: "",
        label: "TopHeader",
        resizable: false,
        expandable: false,
        hasTitle: false,
        size: {
          lg: {
            x: 0,
            y: 0,
            w: 12,
            minW: 12,
            maxW: 12,
            h: 5.8,
            minH: 5.8,
            maxH: 5.8,
          },
          md: {
            x: 0,
            y: 0,
            w: 24,
            minW: 12,
            maxW: 24,
            h: 5.2,
            minH: 5.2,
            maxH: 5.2,
          },
          sm: {
            x: 0,
            y: 0,
            w: 24,
            minW: 12,
            maxW: 24,
            h: 5.2,
            minH: 5.2,
            maxH: 5.2,
          },
          xs: {
            x: 0,
            y: 0,
            w: 24,
            minW: 12,
            maxW: 24,
            h: 5.2,
            minH: 5.2,
            maxH: 5.2,
          },
        },
      },
      {
        i: "clinicianAlerts",
        label: "ClinicianAlerts",
        resizable: false,
        expandable: false,
        hasTitle: false,
        hasInfo : true,
        size: {
          lg: {
            x: 0,
            y: 5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          md: {
            x: 0,
            y: 5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          sm: {
            x: 0,
            y: 5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          xs: {
            x: 0,
            y: 5,
            w: 4,
            minW: 2,
            maxW: 4,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
        },
      },
      {
        i: "contactPreferences",
        icon: "",
        label: "ContactPreferences",
        resizable: false,
        expandable: false,
        hasTitle: false,
        size: {
          lg: {
            x: 2,
            y: 5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          md: {
            x: 2,
            y: 5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          sm: {
            x: 2,
            y: 5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          xs: {
            x: 4,
            y: 5,
            w: 4,
            minW: 2,
            maxW: 4,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
        },
      },
      {
        i: "labResults",
        label: "LabResults",
        resizable: false,
        expandable: true,
        hasTitle: false,
        size: {
          lg: {
            x: 4,
            y: 5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          md: {
            x: 6,
            y: 6.5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          sm: {
            x: 0,
            y: 9.5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          xs: {
            x: 0,
            y: 9.5,
            w: 4,
            minW: 2,
            maxW: 4,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
        },
      },
      {
        i: "advanceDirective",
        label: "AdvanceDirective",
        resizable: false,
        expandable: true,
        hasTitle: false,
        size: {
          lg: {
            x: 6,
            y: 5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          md: {
            x: 4,
            y: 5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          sm: {
            x: 0,
            y: 6.5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          xs: {
            x: 0,
            y: 6.5,
            w: 4,
            minW: 2,
            maxW: 4,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
        },
      },
      {
        i: "specialist",
        label: "QuickView",
        resizable: false,
        expandable: false,
        hasTitle: false,
        size: {
          lg: {
            x: 8,
            y: 0,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          md: {
            x: 0,
            y: 8,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          sm: {
            x: 2,
            y: 9.5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          xs: {
            x: 4,
            y: 9.5,
            w: 4,
            minW: 2,
            maxW: 4,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
        },
      },
      {
        i: "memberStatusFlag",
        label: "MemberStatusFlag",
        resizable: false,
        expandable: false,
        hasTitle: false,
        size: {
          lg: {
            x: 8,
            y: 2.2,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          md: {
            x: 2,
            y: 6.5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          sm: {
            x: 2,
            y: 6.5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          xs: {
            x: 4,
            y: 6.5,
            w: 4,
            minW: 2,
            maxW: 4,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
        },
      },
      {
        i: "awvDueDate",
        label: "AWVDueDate",
        resizable: false,
        expandable: false,
        hasTitle: false,
        size: {
          lg: {
            x: 10,
            y: 0,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          md: {
            x: 0,
            y: 6.5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          sm: {
            x: 0,
            y: 8,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          xs: {
            x: 0,
            y: 8,
            w: 4,
            minW: 2,
            maxW: 4,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
        },
      },
      {
        i: "stratification",
        label: "Stratification",
        resizable: false,
        expandable: false,
        hasTitle: false,
        size: {
          lg: {
            x: 10,
            y: 2.2,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          md: {
            x: 4,
            y: 6.5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          sm: {
            x: 2,
            y: 8,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
          xs: {
            x: 4,
            y: 8,
            w: 4,
            minW: 2,
            maxW: 4,
            h: 2.2,
            minH: 2.2,
            maxH: 2.2,
          },
        },
      },
      {
        i: "hpi",
        label: "History Of Present Illness",
        resizable: true,
        expandable: false,
        hasTitle: true,
        hasInfoIcon: true,
        size: {
          lg: {
            x: 0,
            y: 14.2,
            w: 4,
            h: 8.1,
            minW: 4,
            minH: 8.1,
          },
          md: {
            x: 0,
            y: 43.5,
            w: 8,
            h: 8.1,
            minW: 4,
            minH: 8.1,
          },
          sm: {
            x: 0,
            y: 45,
            w: 8,
            h: 8.1,
            minW: 4,
            minH: 8.1,
          },
          xs: {
            x: 0,
            y: 45,
            w: 8,
            h: 8.1,
            minW: 4,
            minH: 8.1,
          },
        },
      },
      {
        i: "problems",
        label: "Problems",
        resizable: true,
        expandable: true,
        hasTitle: true,
        size: {
          lg: {
            x: 0,
            y: 6.5,
            w: 4,
            h: 6,
            minW: 1,
            minH: 4,
          },
          md: {
            x: 0,
            y: 9.5,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          sm: {
            x: 0,
            y: 11,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          xs: {
            x: 0,
            y: 11,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
        },
      },
      {
        i: "medication",
        label: "Medication",
        resizable: true,
        expandable: true,
        hasTitle: true,
        size: {
          lg: {
            x: 4,
            y: 6.5,
            w: 4,
            minW: 1,
            h: 6,
            minH: 4,
          },
          md: {
            x: 0,
            y: 15.5,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          sm: {
            x: 0,
            y: 17,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          xs: {
            x: 0,
            y: 17,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
        },
      },
      {
        i: "screenings",
        label: "Screenings",
        resizable: true,
        expandable: false,
        hasTitle: true,
        size: {
          lg: {
            x: 8,
            y: 9.5,
            w: 4,
            minW: 4,
            h: 6,
            minH: 6,
          },
          md: {
            x: 0,
            y: 21.5,
            w: 8,
            minW: 4,
            maxW: 8,
            h: 6,
            minH: 6,
          },
          sm: {
            x: 0,
            y: 23,
            w: 8,
            minW: 4,
            maxW: 8,
            h: 6,
            minH: 6,
          },
          xs: {
            x: 0,
            y: 23,
            w: 8,
            minW: 4,
            maxW: 8,
            h: 6,
            minH: 6,
          },
        },
      },
      {
        i: "allergies",
        label: "Allergies",
        resizable: true,
        expandable: true,
        hasTitle: true,
        size: {
          lg: {
            x: 0,
            y: 12.5,
            w: 4,
            minW: 1,
            h: 6,
            minH: 4,
          },
          md: {
            x: 0,
            y: 27.5,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          sm: {
            x: 0,
            y: 29,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          xs: {
            x: 0,
            y: 29,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
        },
      },
      {
        i: "qualityManagement",
        label: "Quality Management",
        resizable: true,
        expandable: false,
        hasTitle: true,
        hasInfoIcon: true,
        size: {
          lg: {
            x: 4,
            y: 12.5,
            w: 4,
            minW: 1,
            h: 6,
            minH: 4,
          },
          md: {
            x: 0,
            y: 33.5,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          sm: {
            x: 0,
            y: 35,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          xs: {
            x: 0,
            y: 35,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
        },
      },
      {
        i: "vitals",
        label: "Vitals",
        resizable: false,
        expandable: false,
        hasTitle: true,
        size: {
          lg: {
            x: 8,
            y: 15.5,
            w: 4,
            minW: 4,
            h: 4,
            minH: 4,
          },
          md: {
            x: 0,
            y: 39.5,
            w: 8,
            minW: 4,
            maxW: 8,
            h: 4,
            minH: 4,
          },
          sm: {
            x: 0,
            y: 41,
            w: 8,
            minW: 4,
            maxW: 8,
            h: 4,
            minH: 4,
          },
          xs: {
            x: 0,
            y: 41,
            w: 8,
            minW: 4,
            maxW: 8,
            h: 4,
            minH: 4,
          },
        },
      },
      {
        i: "visitsTimeline",
        label: "Visits Timeline",
        resizable: true,
        expandable: false,
        hasTitle: true,
        size: {
          lg: {
            x: 8,
            y: 19.5,
            w: 4,
            minW: 4,
            h: 12,
            minH: 8,
          },
          md: {
            x: 0,
            y: 61.5,
            w: 8,
            minW: 4,
            maxW: 8,
            h: 12,
            minH: 8,
          },
          sm: {
            x: 0,
            y: 63,
            w: 8,
            minW: 4,
            maxW: 8,
            h: 12,
            minH: 8,
          },
          xs: {
            x: 0,
            y: 63,
            w: 8,
            minW: 4,
            maxW: 8,
            h: 12,
            minH: 8,
          },
        },
      },
      {
        i: "communication",
        label: "Communication",
        resizable: true,
        expandable: true,
        hasTitle: true,
        hasInfoIcon: true,
        size: {
          lg: {
            x: 4,
            y: 14.5,
            w: 4,
            h: 8.1,
            minW: 1,
            minH: 4,
          },
          md: {
            x: 0,
            y: 79.5,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          sm: {
            x: 0,
            y: 81,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          xs: {
            x: 0,
            y: 81,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
        },
      },
      {
        i: "notes",
        label: "Notes",
        resizable: true,
        expandable: true,
        hasTitle: true,
        hasInfoIcon: true,
        size: {
          lg: {
            x: 0,
            y: 24.5,
            w: 8,
            minW: 1,
            h: 6,
            minH: 4,
          },
          md: {
            x: 0,
            y: 73.5,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          sm: {
            x: 0,
            y: 75,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          xs: {
            x: 0,
            y: 75,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
        },
      },
      {
        i: "appointments",
        label: "Appointments",
        resizable: true,
        expandable: false,
        hasTitle: true,
        size: {
          lg: {
            x: 0,
            y: 28.5,
            w: 8,
            minW: 1,
            h: 6,
            minH: 4,
          },
          md: {
            x: 0,
            y: 49.5,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          sm: {
            x: 0,
            y: 51,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
          xs: {
            x: 0,
            y: 51,
            w: 8,
            minW: 1,
            maxW: 8,
            h: 6,
            minH: 4,
          },
        },
      },
      {
        i: "riskScore",
        icon: "",
        label: "Risk Score",
        resizable: false,
        expandable: false,
        hasTitle: false,
        size: {
          lg: {
            x: 8,
            y: 31.5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.8,
            minH: 2.8,
            maxH: 2.8,
          },
          md: {
            x: 0,
            y: 79.5,
            w: 4,
            minW: 2,
            maxW: 4,
            h: 2.8,
            minH: 2.8,
            maxH: 2.8,
          },
          sm: {
            x: 0,
            y: 81,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.8,
            minH: 2.8,
            maxH: 2.8,
          },
          xs: {
            x: 0,
            y: 81,
            w: 4,
            minW: 2,
            maxW: 4,
            h: 2.8,
            minH: 2.8,
            maxH: 2.8,
          },
        },
      },
      {
        i: "mraGaps",
        icon: "",
        label: "MRA Gaps",
        resizable: false,
        expandable: false,
        hasTitle: false,
        size: {
          lg: {
            x: 10,
            y: 31.5,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.8,
            minH: 2.8,
            maxH: 2.8,
          },
          md: {
            x: 4,
            y: 79.5,
            w: 4,
            minW: 2,
            maxW: 4,
            h: 2.8,
            minH: 2.8,
            maxH: 2.8,
          },
          sm: {
            x: 2,
            y: 81,
            w: 2,
            minW: 2,
            maxW: 2,
            h: 2.8,
            minH: 2.8,
            maxH: 2.8,
          },
          xs: {
            x: 4,
            y: 81,
            w: 4,
            minW: 2,
            maxW: 4,
            h: 2.8,
            minH: 2.8,
            maxH: 2.8,
          },
        },
      },
    ],
  };
}
