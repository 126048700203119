import QRCode from "react-qr-code";
import useTotpService from "../../services/totpService";

const QRCodeGenerator = () => {
  const { GetURI } = useTotpService();
  const otpUri = GetURI();

  return (
    <div>
      {/* <h2>Scan this QR code with Authenticator App</h2> */}
      <QRCode value={otpUri} />
    </div>
  );
};

export default QRCodeGenerator;
