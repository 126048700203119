import { Button } from "primereact/button";
import { appVersion, currentYear } from "../../data/constants/HelperClass";
import { useAuth } from "../auth/authProvider";

const Access403 = () => {
  const userTokenInfoString = localStorage.getItem("UserTokenInfo");
  const userName = JSON.parse(userTokenInfoString ?? "{}")?.profile?.name ?? null;
  const authContext = useAuth();

  const handleLogoutClick = () => {
    try {
      if (authContext.isAuthenticated()) {
        authContext.logoutInternal();
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div className="access-403">
      <div className="flex place-content-center items-center h-screen justify-between p-10 lg:p-24 xl:p-60 ml-auto">
        <div className="self-center flex flex-col lg:flex-row justify-between w-full !gap-5 items-center">
          <div className="flex items-start">
            {/* <div className="flex flex-col text-white">
              <div>Welcome to</div>
              <h1 className="my-0 text-white font-medium">ClairEMR®</h1>
              <div className="text-sm version-text">Version {appVersion}</div>
            </div> */}
          </div>
          <div className={"bg-white flex flex-col rounded-xl px-12 py-14 !gap-10 w-[412px]"}>
            <div className="flex flex-col !gap-8 justify-stretch">
              <div>
                <img className="self-end" src="/svg/logo_full.svg" alt="powered by aaneel" />
              </div>
              <div className="flex flex-col !gap-6">
                <div className="flex flex-col">
                  <h1 className="login-text font-medium text-2xl flex flex-row gap-2 ">
                    Welcome to
                    <div className="flex flex-col relative">
                      <div>ClairEMR</div>
                      <div className="text-xs version-text text-right absolute -bottom-2 right-0 font-normal">Version {appVersion}</div>
                    </div>
                  </h1>
                </div>
                <div className="!gap-6 rounded-lg message-bg p-3">
                  <div className="flex flex-col text-sm !gap-2">
                    <div>
                      This application is inaccessible to user <span className="font-medium">({userName})</span>.
                      <span className="cursor-pointer underline text-primary font-semibold px-1" onClick={handleLogoutClick}>
                        Click here
                      </span>
                      to log in using a separate account.
                    </div>
                    <div className="note-text font-semibold">Note: This action will log you out of all AaNeel-related products.</div>
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <Button
                    label="Privacy Policy"
                    className="text-sm font-normal underline"
                    link
                    onClick={() => window.open("https://www.aaneel.com/application-privacy-policy", "_blank")}
                  />
                  <div className="v-seperator"></div>
                  <Button
                    label="Terms of Services"
                    className="text-sm !font-normal underline"
                    link
                    onClick={() => window.open("https://www.aaneel.com/application-terms-of-service", "_blank")}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row !gap-3 justify-center">
              <div className="self-end leading-none gray-text text-xs">© {currentYear} AaNeel Infotech LLC</div>
              <div className="relative">
                <div className="gray-text absolute right-0 -top-[0.5rem] text-[0.625rem]">Powered by</div>
                <img className="self-end" src="/svg/aaneel-poweredby.svg" alt="powered by aaneel" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Access403;
