export const GenerateJWTTokenForOffline = () => {
  try {
    const header = {
      alg: "HS256", // Algorithm, e.g., HMAC SHA-256
      typ: "JWT", // Token type
    };
    let userName = null,
      offlineExp = null;
    const userTokenInfo = localStorage.getItem("UserTokenInfo") !== null ? JSON.parse(localStorage.getItem("UserTokenInfo") ?? "{}") : null;
    if (userTokenInfo !== null && userTokenInfo !== "" && userTokenInfo !== undefined) {
      userName = userTokenInfo?.profile?.given_name ?? null;
      //Commented this for testing purpose
      // offlineExp = userTokenInfo?.profile?.offlineaccesstokenexpiry ?? null;

      //remove it before moving to dev
      offlineExp = userTokenInfo?.expires_at ?? null;
    }
    const payload = {
      username: userName,
      exp: parseInt(offlineExp),
    };

    // Encode header and payload to Base64
    const encodedHeader = btoa(JSON.stringify(header));
    const encodedPayload = btoa(JSON.stringify(payload));

    // Create the signature
    const signature = btoa(
      window.crypto // Use window.crypto for secure random bytes
        .getRandomValues(new Uint8Array(32))
        .reduce((s, byte) => s + String.fromCharCode(byte), ""),
    );

    // Combine the encoded parts with dots to form the final JWT
    // we will change this after implement SSO logic for offline login
    if (userName !== null) {
      const generatedToken = `${encodedHeader}.${encodedPayload}.${signature}`;
      sessionStorage.setItem("offline-auth", generatedToken);
      return true;
    }
  } catch {
    return false;
  }

  return false;
};
