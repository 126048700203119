import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { AppResetModeStateModel } from "../../model/app-reset-model";

const initialState: AppResetModeStateModel = {
  isAppReset: true,
};

const appResetSlice = createSlice({
  name: "appReset",
  initialState,
  reducers: {
    AppResetLayout: (state, action) => {
      state.isAppReset = action.payload;
    },
  },
});

export const { AppResetLayout } = appResetSlice.actions;
export const selectAppReset = (state: RootState) => state.appReset;

export default appResetSlice.reducer;
