import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { LayoutExportDialogVisibleStateModel } from "../../model/layout-export-dialog-model";

const initialState: LayoutExportDialogVisibleStateModel = {
  isVisible: false,
};

const layoutExportDialogSlice = createSlice({
  name: "layoutExportDialogVisible",
  initialState,
  reducers: {
    LayoutExportDialog: (state, action) => {
      state.isVisible = action.payload;
    },
  },
});

export const { LayoutExportDialog } = layoutExportDialogSlice.actions;
export const selectLayoutExportDialog = (state: RootState) =>
  state.layoutExportDialogVisible;

export default layoutExportDialogSlice.reducer;
