import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SetAppointmentDate, selectActiveAppointmentsDate } from "../../Redux/features/activeAppointmentsDateSlice";
import { selectAppStatus } from "../../Redux/features/appStatusSlice";
import { setRefreshString } from "../../Redux/features/refreshDataSlice";
import { selectSyncLoading } from "../../Redux/features/syncLoadingDoneSlice";
import { Appointments } from "../../data/appointments/appointmentsConfig";
import { DateTimeConstant } from "../../data/constants/DateTimeConstants";
import { placeHolder } from "../../data/constants/HelperClass";
import ShowHideWrapper from "../../layout/context/wrappers/showHideWrapper";
import { AppointmentService } from "../../services/AppointmentService";
import DataRefreshButton from "../data-refresh-button/DataRefreshButton";
import NoAppointmentData from "../no_record_found/NoAppointmentData";

const AppointmentDetails = () => {
  const dispatch = useDispatch();
  const appointments = Appointments.AppontmentDatabase.appointments;
  const [selectedFilter, setSelectedFilter] = useState("All");
  const activeData = useSelector(selectActiveAppointmentsDate);
  const userTokenInfoString = localStorage.getItem("UserTokenInfo");
  const displayName = JSON.parse(userTokenInfoString ?? "{}")?.profile?.displayName ?? null;

  //#region Calendar, dates, and its related button

  // Use the useSelector hook to get the app status from the Redux store
  const isAppOnline = useSelector(selectAppStatus);

  // Use state to keep track of the current date
  // const [currentDate, setCurrentDate] = useState(moment());

  const [, setShowCalendar] = useState(false);
  const [appointmentsData, setAppointmentsData]: any = useState([]);
  const syncloadingString = useSelector(selectSyncLoading);
  const { getAll } = AppointmentService();
  useEffect(() => {
    const getList = async () => {
      let appoinemtList = await getAll(activeData.activeAppintmentsDate ?? moment().format(DateTimeConstant.DF));
      setAppointmentsData(appoinemtList);
    };

    getList();
  }, [syncloadingString, activeData.activeAppintmentsDate]);

  // Function to handle previous day click
  const handlePreviousDay = () => {
    const newDate = moment(activeData.activeAppintmentsDate).subtract(1, "day").format(DateTimeConstant.DF);
    setSelectedFilter("All");
    dispatch(SetAppointmentDate(newDate));
  };

  // Function to handle next day click
  const handleNextDay = () => {
    setSelectedFilter("All");
    const newDate = moment(activeData.activeAppintmentsDate).add(1, "day").format(DateTimeConstant.DF);
    dispatch(SetAppointmentDate(newDate));
  };

  // Check if the current date is today
  const isToday = moment(activeData.activeAppintmentsDate).isSame(moment(), "day");

  // Check if the next button should be hidden only when the app is offline
  const isNextButtonHidden =
    isAppOnline || // Always show next button when online
    (!isAppOnline &&
      (moment(activeData.activeAppintmentsDate).isSame(moment(), "day") || // Show next button for today
        moment(activeData.activeAppintmentsDate).isSame(moment().add(1, "day"), "day"))); // Show next button for tomorrow

  // Check if the previous button should be hidden only when the app is offline
  const isPreviousButtonHidden =
    isAppOnline || // Always show previous button when online
    (!isAppOnline &&
      (moment(activeData.activeAppintmentsDate).isSame(moment().add(1, "day"), "day") || // Show previous button for tomorrow
        moment(activeData.activeAppintmentsDate).isSame(moment().add(2, "days"), "day"))); // Show previous button for day after tomorrow

  const handleDateChange = (e: any) => {
    if (e.value) {
      dispatch(SetAppointmentDate(moment(e.value).format("MM-DD-YYYY")));
    } else {
      // If the selected date is null (Clear button clicked), set it to the current date
      dispatch(SetAppointmentDate(moment().format("MM-DD-YYYY")));
    }
    setShowCalendar(false);
  };

  //#endregion

  const filterAppointments = () => {
    let filteredData =
      selectedFilter === "All" ? appointmentsData : appointmentsData.filter((appointment: any) => appointment.appointmentStatus === selectedFilter);

    // Custom sorting logic for visitTime column
    filteredData = filteredData.sort((a: any, b: any) => {
      return 1 * a["start"].localeCompare(b["start"]);
    });

    return filteredData;
  };

  const customSort = (field: string, a: any, b: any, sortOrder: number) => {
    const valueA = a[field];
    const valueB = b[field];

    if (field === "visitTime") {
      const timeFormat = "hh:mm A";
      const momentA = moment(valueA, timeFormat).local();
      const momentB = moment(valueB, timeFormat).local();

      return sortOrder * momentA.diff(momentB);
    } else {
      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortOrder * valueA.localeCompare(valueB);
      } else if (moment.isMoment(valueA) && moment.isMoment(valueB)) {
        return sortOrder * (valueA.isBefore(valueB) ? -1 : 1);
      } else {
        // Add additional type-specific comparisons if needed
        return sortOrder * (valueA - valueB);
      }
    }
  };

  const handleSort = (e: any) => {
    const data = [...filteredAppointments];
    const sortField = e.field;
    const sortOrder: number = e.order || 0;

    switch (sortOrder) {
      case 1: // Ascending sort
        return data.sort((a, b) => customSort(sortField, a, b, 1));
      case -1: // Descending sort
        return data.sort((a, b) => customSort(sortField, a, b, -1));
      default: // No sort, return original order
        return data;
    }
  };

  const filteredAppointments = filterAppointments();
  const handleFilterClick = (selectedcategory: any) => {
    setSelectedFilter(selectedcategory);
  };

  // const appointments = Appointments.AppontmentDatabase.appointments;
  const navigate = useNavigate();

  const makeClassName = (col: string, value: string) => {
    if (col === "appointmentStatus") {
      const result = `${col?.replace(/([A-Z])/g, " $1").toLowerCase()}-${value?.split(" ")?.join("-")?.toLowerCase()}`;
      return result;
    } else {
      return "N/A";
    }
  };
  const handleAppointmentClick = (value: any) => {
    // dispatch(SetAppointmentDate(moment().format(DateTimeConstant.DF)));
    navigate("/pages/appointment-details/" + value);
  };

  const columnBodyTemplate = (item: any, col: string, valueCol?: string) => {
    let actCol = valueCol ?? col;
    let value = item[actCol];
    if (col === "memberName")
      return (
        <span onClick={() => handleAppointmentClick(item.id)} className={"hyperlink cursor-pointer " + makeClassName(actCol, value)}>
          {placeHolder(item?.planMemberID)} - {placeHolder(value)}
        </span>
      );
    else if (col === "start") {
      return (
        <span className={makeClassName(actCol, value)}>
          {moment(value).isValid() ? moment.utc(value).local().format(DateTimeConstant.HM) : placeHolder(value)}
        </span>
      );
    } else if (col === "visitType") {
      return <span className={"not-hyperlink"}>{placeHolder(value)}</span>;
    } else if (col === "address") {
      return <span>{placeHolder(item.address.replace(/\s*,\s*/g, ", "))}</span>;
    } else return <span className={makeClassName(actCol, value)}>{placeHolder(value)}</span>;
  };

  const rowClassName = (rowData: any) => {
    if (rowData.appointmentStatus.toLowerCase() === "check out") {
      const result = `${rowData.appointmentStatus.split(" ").join("-").toLowerCase()}`;
      return `is-${result}`;
    } else return "";
  };

  // const formttedDate = filteredAppointments.map((item: any) => ({
  //   ...item,
  //   start: moment(item.start).format(DateTimeConstant.HM),
  // }));

  const todaysAppointments = filteredAppointments;

  // Extract unique visit types from API data
  const appointmentStatusFilters: any[] = [
    "All",
    ...Array.from(new Set(appointmentsData.map((appointment: any) => String(appointment.appointmentStatus)))),
  ];

  const handleRefreshData = () => {
    dispatch(setRefreshString(moment().format(DateTimeConstant.DTFWM)));
  };
  return (
    <div className="px-3 flex flex-col !gap-9 appointment-details">
      <div className="flex flex-col !gap-[2.25rem]">
        <div className="flex flex-col !gap-5">
          <div className="flex flex-col !gap-2">
            <h3 className="font-normal m-0 text-primary">Welcome {displayName || "Guest User"}</h3>
            <div className="flex flex-row !gap-4 items-center">
              <h1 className="m-0 text-primary font-medium">{isToday ? "Today's Appointment" : "Appointment List"}</h1>
              <ShowHideWrapper>
                <DataRefreshButton handleRefreshData={handleRefreshData} />
              </ShowHideWrapper>
            </div>
          </div>
          <h4 className="font-medium m-0 text-primary flex flex-row !gap-4">
            <div className={`flex flex-row !gap-2 justify-between ${isAppOnline ? "w-[23rem]" : "w-[20.7rem]"}`}>
              {new Date(moment(activeData.activeAppintmentsDate).toDate()).toLocaleString(undefined, {
                weekday: "long",
                month: "long",
                day: "numeric",
                year: "numeric",
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })}
              {/* Conditionally render the Calendar based on app status */}
              {isAppOnline && (
                <Calendar
                  value={moment(activeData.activeAppintmentsDate).toDate()}
                  onChange={handleDateChange}
                  className="appointment-calendar"
                  showIcon
                  showButtonBar
                  icon="cl_calendar_today_fill"
                  dateFormat="dddd, MMMM D, YYYY"
                  clearButtonClassName="hidden"
                />
              )}
            </div>
            <div className="flex flex-row !gap-4 content-center">
              <div className="flex flex-row !gap-2 content-center">
                {isPreviousButtonHidden && (
                  <Button
                    className="!w-6 !h-6 p-0"
                    icon="cl_arrow_left_line"
                    rounded
                    severity="secondary"
                    aria-label="Previous"
                    onClick={handlePreviousDay}
                  />
                )}
                {isNextButtonHidden && (
                  <Button
                    className="!w-6 !h-6 p-0"
                    icon="cl_arrow_right_line"
                    rounded
                    severity="secondary"
                    aria-label="Next"
                    onClick={handleNextDay}
                  />
                )}
              </div>

              {!isToday && (
                <Button
                  className="!h-6 px-3 text-sm font-medium"
                  outlined
                  rounded
                  label="Today"
                  aria-label="Today"
                  onClick={() => dispatch(SetAppointmentDate(moment().format("MM-DD-YYYY")))}
                />
              )}
            </div>
          </h4>
        </div>

        <div className="flex flex-row !gap-2">
          {appointmentStatusFilters.map((appointmentStatus: string, index: number) => (
            <Button
              label={appointmentStatus}
              onClick={() => handleFilterClick(appointmentStatus)}
              outlined={selectedFilter === appointmentStatus ? false : true}
              rounded
              size="small"
              key={appointmentStatus + index}
              className={`chips-button ${selectedFilter === appointmentStatus ? "active" : ""}`}
            />
          ))}
        </div>
      </div>
      <DataTable
        value={todaysAppointments} //use todaysAppointments to get the filtered appointments for the current date
        totalRecords={filteredAppointments.length}
        scrollable
        scrollHeight="flex"
        removableSort
        sortMode="single"
        sortField="visitTime"
        sortOrder={1}
        rowClassName={rowClassName}
        // multiSortMeta={[{ field: "visitTime", order: 1 }]} // Specify the default sorting here
        emptyMessage={<NoAppointmentData />}
      >
        {appointments.cols.map((x: any, i: any) => (
          <Column
            field={x.col}
            sortable={x.isSortable}
            style={{ width: `${x.width}rem` }}
            header={x.display}
            className={makeClassName(x.col, x.display)}
            body={(e) => columnBodyTemplate(e, x.col, x.valueCol)}
            key={"eachkey-" + x + i}
            sortFunction={handleSort}
          ></Column>
        ))}
      </DataTable>
    </div>
  );
};
export default AppointmentDetails;
