export class DateTimeConstant {
  static API_DTF = "YYYY-MM-DDTHH:mm:ssZ"; // API DATE TIME FORMAT
  static PAYLOAD_DTF = "MM/DD/YYYY HH:mm"; // DATE TIME FORMAT
  static DTF = "MM/DD/YYYY hh:mm A"; // DATE TIME FORMAT
  static DF = "MM/DD/YYYY"; // DATE FORMAT
  static LMDF = "MMM DD, YYYY"; // LONG MONTH DATE FORMAT
  static DAYF = "DD"; // DAY FORMAT
  static MONTHF = "MM"; // MONTH FORMAT
  static SMONTHF = "MMM"; // SHORT MONTH FORMAT
  static LMONTHF = "MMMM"; // LONG MONTH FORMAT
  static YEARF = "YYYY"; // YEAR FORMAT
  static HM = "hh:mm A"; // 12 HOUR MINUTE FORMAT
  static HM24 = "HH:mm"; // 24 HOUR MINUTE FORMAT
  static H = "hh"; // HOUR FORMAT
  static HH24 = "HH"; // HOUR 24 FORMAT
  static m = "mm"; // MINUTE FORMAT
  static s = "ss"; // SECOND FORMAT
  static TIME_ONLY = "hh:mm:ss A"; // TIME ONLY FORMAT
  static ISO_DATE = "YYYY-MM-DD"; // ISO DATE FORMAT
  static ISO_TIME = "HH:mm:ss"; // ISO TIME FORMAT
  static ISO_DATE_TIME = "YYYY-MM-DDTHH:mm:ss"; // ISO DATE TIME FORMAT
  static LMDF_FULL = "dddd, MMMM Do YYYY"; // LONG MONTH DATE FULL FORMAT
  static ISO_DATE_SHORT = "YY-MM-DD"; // ISO SHORT DATE FORMAT
  static MDY = "MM-DD-YYYY"; // MONTH-DAY-YEAR FORMAT
  static YMD = "YYYY-MM-DD"; // YEAR-MONTH-DAY FORMAT
  static DMY = "DD-MM-YYYY"; // DAY-MONTH-YEAR FORMAT
  static MDY_SHORT = "MM-DD-YY"; // SHORT MONTH-DAY-YEAR FORMAT
  static YMD_SHORT = "YY-MM-DD"; // SHORT YEAR-MONTH-DAY FORMAT
  static DMY_SHORT = "DD-MM-YY"; // SHORT DAY-MONTH-YEAR FORMAT
  static DTFWM = "YYYY-MM-DDTHH:mm:ss.SSSZ";
}
