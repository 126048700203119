import { HeaderData, HeaderDataValue } from "./constants/Enum";

export class AppointmentDetailConfig {
  public static Configuration: any = {
    topHeader: {
      memberDemoGraphics: [
        {
          title: "profile image",
          displayFormat: HeaderData.isImage,
          field: "profileImage",
        },
        {
          title: "name",
          displayFormat: HeaderDataValue.isName,
          field: "membername",
        },
        {
          title: "gender",
          displayFormat: HeaderDataValue.isGender,
          field: "gender",
        },
        {
          title: "age",
          displayFormat: HeaderDataValue.isAge,
          field: "age",
        },
        {
          title: "dob",
          displayFormat: HeaderDataValue.isDob,
          field: "dob",
        },
        {
          title: "status",
          displayFormat: HeaderDataValue.isstatus,
          field: "status",
        },
        {
          title: "AaNeel Id",
          displayFormat: HeaderData.isPlainText,
          field: "memberID",
        },
        {
          title: "Address",
          displayFormat: HeaderData.isToolTip,
          field: "address",
        },
        {
          title: "Primary Phone",
          displayFormat: HeaderData.isDropdown,
          field: "primaryPhone",
          dropDown: [],
          dropdownProps: {
            dropdownData: [
              {
                itemIcon: "cl_mobile",
                itemTitle: "Primary Phone",
                itemValue: "",
                field: "primaryPhone",
              },
              {
                itemIcon: "cl_home_phone",
                itemTitle: "Secondary Phone",
                itemValue: "",
                field: "secondaryPhone",
              },
            ],
            dropdownTitle: "Patient Contact",
            viewMoreButton: false,
            viewMoreButtonText: "",
          },
        },
        {
          title: "Email",
          displayFormat: HeaderData.isToolTip,
          field: "email",
        },
        {
          title: "Race",
          displayFormat: HeaderData.isToolTip,
          field: "race",
        },
        {
          title: "Preferred Language",
          displayFormat: HeaderData.isToolTip,
          field: "preferredLanguage",
        },
      ],
      memberOfficeDetails: [
        {
          title: "Office Name",
          field: "officeName",
        },
        {
          title: "Term Date",
          field: "termDate",
          dataType: "Date",
        },
        {
          title: "Region",
          field: "region",
        },
      ],
      memberTeamDetail: [
        {
          title: "APP",
          field: "app",
        },
        {
          title: "CMD",
          field: "cmd",
        },
        {
          title: "Social Worker",
          field: "socialWorker",
        },
        {
          title: "Social Worker",
          field: "socialWorker",
        },
        {
          title: "PL",
          field: "pl",
        },
      ],
      hospitalAdmission: [
        {
          title: "Last Admission Type",
          field: "lastAdmissionType",
        },
        {
          title: "Historical Last 12 Months",
          field: "historicalLast12Months",
        },
        {
          title: "Last Year Admits",
          field: "lastYearAdmits",
        },
        {
          title: "This Year Admits",
          field: "thisYearAdmits",
        },
      ],
      pcpDetails: [
        {
          title: "Phone",
          field: "phone",
        },
        {
          title: "Fax",
          field: "fax",
        },
        {
          title: "Office",
          field: "office",
        },
        {
          title: "Effective Date",
          field: "effectiveDate",
        },
        {
          title: "Term Date",
          field: "termDate",
        },
      ],
      insuranceDetails: [
        {
          title: "Insurance Name",
          field: "insuranceName",
        },
        {
          title: "Medicare No",
          field: "medicareNo",
        },
        {
          title: "Plan",
          field: "plan",
        },
        {
          title: "Effective Date",
          field: "effectiveDate",
          dataType: "Date",
        },
        {
          title: "Term Date",
          field: "termDate",
          dataType: "Date",
        },
        {
          title: "LOB",
          field: "lob",
        },
        {
          title: "Medicaid",
          field: "medicareId",
        },
      ],
      pharmacyDetails: [
        {
          title: "Pharmacy Name",
          field: "pharmacyName",
        },
        {
          title: "Phone",
          field: "phone",
        },
        {
          title: "Fax",
          field: "fax",
        },
        {
          title: "Address",
          field: "address",
        },
      ],
      contacts: [
        {
          title: "Relationship",
          field: "relationship",
        },
        {
          title: "Name",
          field: "name",
        },
        {
          title: "Home Phone",
          field: "homePhone",
        },
      ],
      careGiver: [
        {
          title: "Care Giver",
          field: "careGiver",
        },
        {
          title: "Relationship",
          field: "relationship",
        },
        {
          title: "Phone",
          field: "phone",
        },
      ],
      familyHistory: {
        titleField: "dependantRelation",
        field: "description",
      },
      guardian: [
        {
          title: "Name",
          field: "guardianName",
        },
        {
          title: "Home Phone",
          field: "guardianHomePhone",
        },
        {
          title: "Alternate Phone",
          field: "guardianAlternatePhone",
        },
        {
          title: "Mobile Phone",
          field: "guardianMobilePhone",
        },
      ],
    },
    clinicianAlerts: {
      filterColumn: "",
      hasInfo: true,
      headerTooltip: "The patient's medical history does not include any clinical alerts.",
      hasDropdown: false,
      dropDown: [],
      list: {},
      dataset: [
        {
          field: "alert",
        },
      ],
      cols: [],
      icon: "cl_warning",
      i: "clinicianAlerts",
      label: "Clinician Alerts",
    },
    contactPreferences: {
      filterColumn: "",
      dropDown: [],
      list: {},
      dataset: [
        {
          icon: "cl_phone_fill",
        },
        {
          icon: "cl_sms",
        },
        {
          icon: "cl_email_fill",
        },
      ],
      cols: [],
      type: "minicard",
      i: "contactPreferences",
      label: "Contact Preferences",
    },
    labResults: {
      filterColumn: "",
      dropDown: [],
      sortField: "date",
      sortOrder: 1,
      hasNorthEastArrow: true,
      list: {},
      displayOnlyCount: true,
      dataset: [],
      cols: [
        {
          col: "date",
          display: "Date",
          dataType: "Date",
          dataFormat: "Date",
          isSortable: true,
          width: "9",
        },
        {
          col: "loincCode",
          display: "LOINC",
          width: "8",
        },
        {
          col: "testType",
          display: "Test",
        },
        {
          col: "observationValue",
          display: "Observation Value",
          valueClassName: "observationValue",
        },
        {
          col: "referenceRange",
          display: "Reference Range",
        },
        {
          col: "labName",
          display: "Lab Name",
          isSortable: true,
        },
        {
          col: "source",
          display: "Source",
          width: "8",
        },
      ],
      type: "minicard",
      icon: "cl_science",
      legends: "cl_dot_big",
      i: "labResults",
      label: "Lab Results",
    },
    advanceDirective: {
      filterColumn: "",
      dropDown: [],
      hasNorthEastArrow: true,
      hasInfoIcon: true,
      headerTooltip: "The patient's medical history does not include any documentation related to advance directives.",
      dataset: [
        {
          field: "header",
        },
      ],
      cols: [
        {
          col: "name",
          display: "Name",
          width: "15",
        },
        {
          col: "address",
          display: "Address",
        },
        {
          col: "phone",
          display: "Phone",
          width: "10",
        },
        {
          col: "effectiveDate",
          display: "Effective Date",
          dataType: "Date",
          dataFormat: "Date",
          width: "8",
        },
        {
          col: "termDate",
          display: "Term Date",
          dataType: "Date",
          dataFormat: "Date",
          width: "8",
        },
        // {
        //   col: "documentName",
        //   display: "Document",
        //   width: "15",
        // },
      ],
      list: {},
      type: "minicard",
      icon: "cl_task_2_fill",
      i: "advanceDirective",
      label: "Advance Directive",
    },
    specialist: {
      filterColumn: "",
      displayOnlyCount: true,
      list: {},
      dataset: [{ icon: "cl_eye_fill", dataText: "Specialist", field: "specialist" }],
      cols: [
        {
          col: "type",
          display: "Type",
        },
        {
          col: "specialist",
          display: "Name",
        },
        {
          col: "phoneNumber",
          display: "Phone Number",
        },
        {
          col: "address",
          display: "Address",
        },
      ],
      hasNorthEastArrow: true,
      type: "minicard",
      icon: "cl_specialist_doctor",
      i: "specialist",
      label: "Specialist",
    },
    memberStatusFlag: {
      filterColumn: "",
      dropDown: [],
      hasInfo: true,
      headerTooltip: "The patient's medical history does not include any Member Status Flag.",
      list: {},
      dataset: [
        {
          field: "memberStatusflag",
        },
      ],
      cols: [],
      icon: "cl_select_mem_2",
      i: "memberStatusFlag",
      label: "Member Status Flag",
    },
    awvDueDate: {
      filterColumn: "",
      hasInfo: true,
      dropdownProps: {
        dropdownData: [
          // {
          //   itemIcon: "cl_event_fill",
          //   itemTitle: "Completed Date",
          //   itemValue: "",
          //   field: "completedDate",
          //   isSideBySide: true,
          // },
          // {
          //   itemIcon: "cl_clock",
          //   itemTitle: "Time",
          //   itemValue: "",
          //   field: "completedDate",
          //   isSideBySide: true,
          // },
          {
            itemIcon: "cl_event_fill",
            itemTitle: "Date & Time",
            itemValue: "",
            field: "dueDate",
            isDateField: true,
            // isSideBySide: true,
          },
          // {
          //   itemIcon: "cl_clock",
          //   itemTitle: "Time",
          //   itemValue: "",
          //   field: "dueDate",
          //   isSideBySide: true,
          // },
          {
            itemIcon: "cl_doctor_1",
            itemTitle: "Scheduled With",
            field: "scheduledWith",
            itemValue: "",
          },
        ],
      },
      dropdownTitle: "Annual Wellness Visit Due Date",

      dropDown: [{ dataText: "09/16/2024" }],
      list: {},
      dataset: [{ field: "dueDate" }],
      cols: [],
      headerTooltip: "AWV Due date is not available for this patient",

      // type: "minicard",
      icon: "cl_event_fill",
      i: "awvDueDate",
      label: "AWV Due Date",
    },
    stratification: {
      filterColumn: "",
      dropDown: [],
      list: {},
      dataset: [
        {
          field: "stratification",
        },
      ],
      cols: [],
      type: "minicard",
      icon: "cl_bmi_meter",
      i: "stratification",
      label: "Stratification",
    },
    hpi: {
      list: {
        key: "code",
        value: "description",
      },
      dataset: [],
      cols: [
        {
          col: "date",
          display: "",
        },
        {
          col: "description",
          display: "",
        },
      ],
      type: "gaccordion",
      icon: "cl_doc_view",
      i: "hpi",
      stateTooltip: true,
      headerTooltip: "This list contains latest 5 HPI's",
      label: "History Of Present Illness",
    },
    problems: {
      filterColumn: "problemStatus",
      showHeaders: false,
      dropDown: [
        {
          label: "All",
          value: "All",
        },
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "Inactive",
          value: "Inactive",
        },
        {
          label: "Resolved",
          value: "Resolved",
        },
      ],
      list: {
        key: "code",
        value: "description",
      },
      dataset: [],
      cols: [
        {
          col: "moduleSource",
          display: "Source",
          hideInWidget: true,
        },
        {
          col: "visitDate",
          display: "Visit Date",
          dataType: "Date",
          dataFormat: "Date",
          hideInWidget: true,
          width: "8",
        },
        {
          col: "code",
          display: "Code",
          width: "8",
          sortable: false,
        },
        {
          col: "description",
          display: "Description",
          sortable: false,
        },
        {
          col: "onSetDate",
          display: "OnSet Date",
          dataType: "Date",
          hideInWidget: true,
          width: "8",
        },
        {
          col: "problemStatus",
          display: "Status",
          hideInWidget: true,
          sortable: false,
          valueClassName: "status",
          width: "8",
        },
      ],
      type: "gtable",
      icon: "cl_dr_patient",
      i: "problems",
      label: "Problems",
    },
    medication: {
      filterColumn: "status",
      showHeaders: true,
      dropDown: [
        {
          label: "All",
          value: "All",
        },
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "Discontinue",
          value: "Discontinue",
        },
        {
          label: "On Hold",
          value: "On Hold",
        },
      ],
      list: {
        key: "name",
        value: "status",
      },
      dataset: [],
      cols: [
        {
          col: "visitDate",
          display: "Visit Date",
          dataType: "Date",
          hideInWidget: true,
          dataFormat: "Date",
        },
        {
          col: "name",
          display: "Name",
        },
        {
          col: "start",
          display: "Start Date",
          dataFormat: "Date",
          dataType: "Date",
          hideInWidget: true,
          width: "8",
        },
        {
          col: "end",
          display: "End Date",
          dataType: "Date",
          dataFormat: "Date",
          hideInWidget: true,
          width: "8",
        },
        {
          col: "route",
          display: "Route",
          hideInWidget: true,
        },
        {
          col: "dose",
          display: "Dose",
          width: "8",
        },
        {
          col: "frequency",
          display: "Frequency",
          width: "8",
        },
        {
          col: "direction",
          display: "Directions",
          hideInWidget: true,
        },
        {
          col: "status",
          display: "Status",
          valueClassName: "status",
          width: "8",
        },
        {
          col: "rxStatus",
          display: "Rx Status",
          hideInWidget: true,
          valueClassName: "status",
          width: "8",
        },
      ],
      type: "gtable",
      icon: "cl_bottle_med_2",
      i: "medication",
      label: "Medication",
    },
    screenings: {
      filterColumn: "",
      showHeaders: true,
      dropDown: [],
      list: {
        key: "screening",
        value: "score",
      },
      dataset: [],
      cols: [
        {
          col: "screeningType",
          display: "Screening Type",
        },
        {
          col: "score",
          display: "Patient Score",
          width: "8",
        },
      ],
      type: "gtable",
      icon: "cl_user_note",
      i: "screenings",
      label: "Screenings",
    },
    allergies: {
      filterColumn: "status",
      showHeaders: true,
      sortField: "severityRating",
      sortOrder: 1,
      dropDown: [
        {
          label: "All",
          value: "All",
        },
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "Inactive",
          value: "Inactive",
        },
      ],
      list: {
        key: "allergy",
        value: "type",
      },
      dataset: [],
      cols: [
        {
          col: "visitDate",
          display: "Visit Date",
          dataType: "Date",
          dataFormat: "Date",
          hideInWidget: true,
          width: "8",
        },
        {
          col: "allergy",
          display: "Allergy",
        },
        {
          col: "type",
          display: "Type",
        },
        {
          col: "severityRating",
          display: "Severity",
          valueCol: "severity",
          valueClassName: "severity",
          isSortable: true,
          width: "8",
        },
        {
          col: "reaction",
          display: "Reaction",
          hideInWidget: true,
        },
        {
          col: "status",
          display: "Status",
          valueClassName: "status",
          hideInWidget: true,
          width: "8",
        },
      ],
      type: "gtable",
      icon: "cl_circle_dots",
      i: "allergies",
      label: "Allergies",
    },
    qualityManagement: {
      filterColumn: "reviewDate",
      showHeaders: true,
      dropDown: [
        {
          label: "All",
          value: "All",
        },
        {
          label: "Done",
          value: "Done",
        },
        {
          label: "Pending",
          value: "Pending",
        },
      ],
      list: {
        key: "code",
        value: "measure",
      },
      dataset: [],
      cols: [
        {
          col: "code",
          display: "Code",
          width: "6",
        },
        {
          col: "measure",
          display: "Measure",
        },
        {
          col: "reviewDate",
          display: "Review Date",
          dataType: "Date",
          dataFormat: "Date",
          width: "8",
        },
        {
          col: "planStatus",
          display: "Plan Status",
          valueClassName: "status",
          hideInWidget: true,
          width: "8",
        },
        {
          col: "dateOfService",
          display: "Date Of Service",
          dataType: "Date",
          hideInWidget: true,
          width: "8",
        },
        // {
        //   col: "documents",
        //   display: "Document(s)",
        //   hideInWidget: true,
        // },
      ],
      type: "gtable",
      icon: "cl_select_mem",
      i: "qualityManagement",
      headerTooltip: "This contains latest 5 Quality Managements",
      label: "Quality Management",
    },
    vitals: {
      filterColumn: "",
      dropDown: [],
      list: {},
      dataset: [
        {
          header: "Weight",
          type: "lbs",
          field: "weight",
        },
        {
          header: "BMI",
          type: "",
          field: "bmi",
        },
        {
          header: "Pulse",
          type: "",
          field: "pluse",
        },
        {
          header: "BP",
          type: "",
          field: "bp",
        },
        {
          header: "Temp",
          type: "°F",
          field: "temperature",
        },
        {
          header: "O₂",
          type: "%",
          field: "o2",
        },
        {
          header: "RR",
          field: "rr",
        },
        {
          header: "Pain",
          field: "pain",
        },
      ],
      cols: [],
      icon: "cl_heart_fill ",
      i: "vitals",
      label: "Vitals",
    },
    communication: {
      filterColumn: "status",
      showHeaders: true,
      dropDown: [],
      list: {
        key: "date",
        value: "type",
      },
      dataset: [],
      cols: [
        {
          col: "date",
          display: "Date & Time",
          dataType: "Date",
          dataFormat: "DateTime",
          width: "8",
        },
        {
          col: "type",
          display: "Type",
          width: "8",
        },
        {
          col: "category",
          display: "Category",
          hideInWidget: true,
        },
        {
          col: "status",
          display: "Call Status",
          hideInWidget: true,
        },
        {
          col: "fromPersonName",
          display: "From",
          hideInWidget: true,
        },
        {
          col: "to",
          display: "To",
          hideInWidget: true,
        },
        {
          col: "summary",
          display: "Summary",
        },
        {
          col: "attempCount",
          display: "Attempt",
          hideInWidget: true,
        },
      ],
      headerTooltip: "This contains latest 5 Communications",
      type: "gtable",
      icon: "cl_user_phone_fill",
      i: "communication",
      label: "Communication",
    },
    visitsTimeline: {
      filterColumn: "",
      dropDown: [],
      list: {},
      dataset: [],
      cols: [
        {
          col: "date",
          display: "Date",
        },
        {
          col: "visitTime",
          display: "Time",
          dataType: "Date",
          dataFormat: "Date",
          width: "8",
        },
        {
          col: "visitType",
          display: "Type",
        },
        {
          col: "visitCondition",
          display: "Visit Condition",
        },
        {
          col: "visitReason",
          display: "Reason",
        },
        {
          col: "visitNote",
          display: "Note",
        },
      ],
      type: "gaccordion",
      icon: "cl_stethoscope",
      i: "visitsTimeline",
      label: "Visits  Timeline",
    },
    notes: {
      list: {
        key: "date",
        value: "note",
      },
      dataset: [],
      cols: [
        {
          col: "date",
          display: "Date & Time",
          dataType: "Date",
          dataFormat: "DateTime",
          width: "8",
        },
        {
          col: "source",
          display: "Source",
          hideInWidget: true,
        },
        {
          col: "submittedBy",
          display: "Submitted By",
          width: "15",
        },
        {
          col: "noteDesc",
          display: "Note",
        },
      ],
      type: "scroll",
      icon: "cl_note_fill",
      i: "notes",
      headerTooltip: "This list contains latest 5 Notes",
      label: "Notes",
    },
    appointments: {
      sortField: "date",
      showHeaders: true,
      sortOrder: -1,
      filterColumn: "status",
      dropDown: [
        {
          label: "All",
          value: "All",
        },
        {
          label: "Upcoming",
          value: "Upcoming",
        },
        {
          label: "Past",
          value: "Past",
        },
      ],
      list: {},
      dataset: [],
      cols: [
        {
          col: "date",
          display: "Date & Time",
          dataType: "Date",
          dataFormat: "DateTime",
          isSortable: true,
          width: "9",
        },
        {
          col: "type",
          display: "Visit Type",
        },
        {
          col: "provider",
          display: "Scheduled With",
          isSortable: true,
        },
        {
          col: "status",
          display: "Status",
          isSortable: true,
          valueClassName: "status",
          width: "15",
        },
      ],
      type: "gtable",
      icon: "cl_calendar_view",
      i: "appointments",
      label: "Appointments",
    },
    riskScore: {
      filterColumn: "",
      dropDown: [],
      list: {},
      dataset: [
        {
          details: "Projected",
          field: "projected",
        },
        {
          details: "Potential",
          field: "potential",
        },
      ],
      cols: [],
      type: "infocard",
      i: "riskScore",
      label: "Risk  Score",
    },
    mraGaps: {
      filterColumn: "",
      dropDown: [],
      list: {},
      dataset: [
        {
          details: "Reported",
          field: "reported",
        },
        {
          details: "Suspected",
          field: "suspected",
        },
      ],
      cols: [],
      type: "infocard",
      i: "mraGaps",
      label: "MRA  Gaps",
    },
  };
}
