import ExpandedPopUp from "../../../clair-layout/expanded-pop-up/ExpandedPopUp";
import ExportLayoutPopUp from "../exportLayoutPopUp/exportLayoutPopUp";

const PopUpLayout = () => {
  return (
    <>
      <ExportLayoutPopUp />
      <ExpandedPopUp />
    </>
  );
};

export default PopUpLayout;
