import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveAppointmentsDate } from "../../Redux/features/activeAppointmentsDateSlice";
import { selectAppStatus } from "../../Redux/features/appStatusSlice";
import { selectConfirmRefreshString, setConfirmationRefreshString } from "../../Redux/features/confirmRefreshDataSlice";
import { selectRefreshString } from "../../Redux/features/refreshDataSlice";
import { setSyncLoading } from "../../Redux/features/syncLoadingDoneSlice";
import { SetMessage } from "../../Redux/features/toasterInfoSlice";
import { DateTimeConstant } from "../../data/constants/DateTimeConstants";
import { SyncStartMessage, SyncSummary } from "../../data/constants/SyncToasterMessages";
import { AppointmentService } from "../../services/AppointmentService";
import { SyncService } from "../../services/SyncService";

export const SyncComponent = () => {
  const dispatch = useDispatch();
  const { SyncData } = SyncService();
  const activeData = useSelector(selectActiveAppointmentsDate);
  const refreshString = useSelector(selectRefreshString);
  const confirmationRefreshString = useSelector(selectConfirmRefreshString);
  const isOnline = useSelector(selectAppStatus);
  const { checkAppointmentsAvailable } = AppointmentService();

  const sync = async () => {
    const appointmentDate = moment(activeData.activeAppintmentsDate);
    const refreshData = confirmationRefreshString !== refreshString;
    const isSyncFor3Days = moment().format(DateTimeConstant.DF) === appointmentDate.format(DateTimeConstant.DF) && !refreshData;
    const available = await checkAppointmentsAvailable(activeData.activeAppintmentsDate);
    const lastSyncDate = moment(localStorage.getItem("LastSyncOn")).format(DateTimeConstant.DF);
    const isDifferentDay = lastSyncDate !== moment().format(DateTimeConstant.DF);
    const startDate = appointmentDate.utc().format(DateTimeConstant.PAYLOAD_DTF);
    const preparedEndDate = isSyncFor3Days
      ? addBusinessDays(appointmentDate, 3).format(DateTimeConstant.PAYLOAD_DTF)
      : moment(activeData.activeAppintmentsDate).endOf("day").format(DateTimeConstant.PAYLOAD_DTF);
    const endDate = moment(preparedEndDate).utc().format(DateTimeConstant.PAYLOAD_DTF);

    const canFetchData = isOnline && (!available || refreshData || isDifferentDay);

    if (canFetchData) {
      dispatch(setConfirmationRefreshString(refreshString));
      try {
        dispatch(SetMessage({ severity: "success", summary: SyncSummary, detail: SyncStartMessage }));
        const result = await SyncData(startDate, endDate);
        dispatch(SetMessage({ severity: result.severity, summary: SyncSummary, detail: result.message }));
      } catch (ex: any) {
      } finally {
        dispatch(setSyncLoading(moment().format()));
      }
    }
  };

  useEffect(() => {
    sync();
  }, [activeData.activeAppintmentsDate, refreshString, isOnline]);

  return <></>;
};

export const addBusinessDays = (dt: moment.Moment, nDays: number): moment.Moment => {
  const Sunday = 0;
  const Saturday = 6;
  let daysRemaining = nDays;

  const newDate = dt.clone();

  while (daysRemaining > 0) {
    newDate.add(1, "days");
    if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
      daysRemaining--;
    }
  }

  return newDate.endOf("day");
};
