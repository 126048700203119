import moment from "moment";
import { DateTimeConstant } from "./DateTimeConstants";

export const utcConversionDateTime = (
    date: any | Date,
): any => {

    const localTime = moment.utc(getSQLFormattedDateTime(date)).local().format(DateTimeConstant.DTF);
    return localTime;
};

const getSQLFormattedDateTime = (dateString: string | null): string => {
    const dt = dateString != null ? moment(dateString).format('YYYY-MM-DD HH:mm:ss') : moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    return dt;
}
