import MemberOtherDetails from "./memberSubHeader/MemberOtherDetails";
import MemberDemographics from "./MemberDemographics";
import React, { useEffect, useState } from "react";
import { AppointmentDetailConfig } from "../../data/AppointmentDetailConfig";
import { AppointmentService } from "../../services/AppointmentService";
import { useParams } from "react-router";

const MemberDetails = () => {
  const widgetsConfig: any = AppointmentDetailConfig.Configuration["topHeader"];
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), "topHeader");
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="react-grid-layout">
      <div className="topHeader rounded-xl">
        {data?.memberDemoGraphics?.length &&
          data?.memberDemoGraphics.map((each: any, i: number) => {
            return (
              <React.Fragment key={`i${i}`}>
                <MemberDemographics
                  data={each}
                  widgetsConfig={widgetsConfig?.memberDemoGraphics}
                />
              </React.Fragment>
            );
          })}
        <div className="!py-2">
          <div className="flex flex-col gap-2  blur-t ">
            <MemberOtherDetails
              initialState={data}
              widgetsConfig={widgetsConfig}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberDetails;
