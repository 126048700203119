import moment from "moment";

//get current year
export const currentYear = moment().year();

export const appVersion = "1.1.1.1";

//return N/A or Value if exist
//export const placeHolder = (value: any) => value || "N/A";

export const placeHolder = (value: any) => {
  if (value === 0) {
    return "0";
  } else {
    return value || "N/A";
  }
};
