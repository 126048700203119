import { Toast } from "primereact/toast";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectToasterMessage } from "../../Redux/features/toasterInfoSlice";

export const ToasterComponent = () => {
  const toasterInfo = useSelector(selectToasterMessage);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (toasterInfo.severity) {
      toast.current?.show({
        severity: toasterInfo.severity,
        summary: toasterInfo.summary,
        detail: toasterInfo.detail,
      });
    }
  }, [toasterInfo.severity, toasterInfo.summary, toasterInfo.detail]);

  return <Toast ref={toast} />;
};
