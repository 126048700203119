import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useState } from "react";
import { RefreshDisableTime } from "../../data/constants/AppConstants";
import { useSelector } from "react-redux";
import { selectSyncLoading } from "../../Redux/features/syncLoadingDoneSlice";

const DataRefreshButton = ({ handleRefreshData }: any) => {
  const [buttonState, setButtonState] = useState(false);
  const syncloadingString = useSelector(selectSyncLoading);
  const [lastSyncOn, setLastSyncOn] = useState("N/A");
  const handleRefresh = () => {
    setButtonState(true);
    setTimeout(() => {
      setButtonState(false);
    }, RefreshDisableTime);
    handleRefreshData();
  };

  useEffect(() => {
    const lastSync = localStorage.getItem("LastSyncOn")?? "N/A";
    setLastSyncOn(lastSync);
  }, [syncloadingString])

  return (
    <>
      {/* <Tooltip target={".refresh-btn-tooltip"} disabled={!buttonState} /> */}

      <Tooltip target=".refresh-btn-tooltip">
        <div className="flex flex-col">
          <div>Last Synced: </div>
          <div>{lastSyncOn}</div>
        </div>
      </Tooltip>
      <div className="refresh-btn-tooltip">
        <Button
          icon="cl_auto_renew text-4xl"
          className="p-0"
          rounded
          text
          aria-label="Refresh"
          disabled={buttonState}
          onClick={() => handleRefresh()}
        />
      </div>
    </>
  );
};

export default DataRefreshButton;
