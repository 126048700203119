// Import necessary dependencies from React
import React, { ReactNode } from "react";

// Import the useVisibility custom hook from the visibilityContext
import { useVisibility } from "../visibilityContext";

// Define the type for the props passed to ShowHideWrapper
interface ShowHideWrapperProps {
  children: ReactNode;
}

// ShowHideWrapper component that conditionally renders its children based on visibility
const ShowHideWrapper: React.FC<ShowHideWrapperProps> = ({ children }) => {
  // Use the useVisibility custom hook to access the visibility context
  const { isAvailable } = useVisibility();

  // Conditionally render the children inside a div if isAvailable is true, otherwise, render null
  return isAvailable ? <div>{children}</div> : null;
};

// Export the ShowHideWrapper component as the default export
export default ShowHideWrapper;
