// Import necessary dependencies from React and Redux
import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";
import { selectAppStatus } from "../../Redux/features/appStatusSlice";

// Define the type for the props passed to VisibilityProvider
interface VisibilityContextProps {
  children: React.ReactNode;
}

// Define the structure of the context value
interface VisibilityContextValue {
  isAvailable: boolean;
}

// Create a context with the specified value type
const VisibilityContext = createContext<VisibilityContextValue | undefined>(
  undefined
);

// Custom hook for accessing the visibility context
export const useVisibility = () => {
  const context = useContext(VisibilityContext);
  if (!context) {
    // Throw an error if the hook is not used within a VisibilityProvider
    throw new Error("useVisibility must be used within a VisibilityProvider");
  }
  return context;
};

// VisibilityProvider component that provides the visibility context to its children
export const VisibilityProvider: React.FC<VisibilityContextProps> = ({
  children,
}) => {
  // Use the useSelector hook to get the app status from the Redux store
  const appOnline = useSelector(selectAppStatus);

  // Set the visibility based on the app status
  const isAvailable = appOnline;

  // Create the context value to be provided to the context provider
  const value: VisibilityContextValue = {
    isAvailable,
  };

  // Render the context provider with the specified value and its children
  return (
    <VisibilityContext.Provider value={value}>
      {children}
    </VisibilityContext.Provider>
  );
};
