import React, { useContext, useEffect, useState } from "react";
import type { AppBreadcrumbProps, Breadcrumb } from "../types/types";
import { LayoutContext } from "./context/layoutcontext";
import { useLocation } from "react-router-dom";

const AppBreadcrumb = (props: AppBreadcrumbProps) => {
  const location = useLocation();
  const [breadcrumb, setBreadcrumb] = useState<Breadcrumb | null>(null);
  const { breadcrumbs } = useContext(LayoutContext);

  useEffect(() => {
    const filteredBreadcrumbs = breadcrumbs?.find((crumb: Breadcrumb) => {
      return (
        crumb.to?.replace(/\/$/, "") === location.pathname.replace(/\/$/, "")
      );
    });
    setBreadcrumb(filteredBreadcrumbs ?? null);
  }, [location.pathname, breadcrumbs]);

  return (
    <div className={props.className}>
      <nav className="layout-breadcrumb">
        <ol>
          {breadcrumb?.labels?.map((label, index) => (
            <React.Fragment key={index}>
              {index !== 0 && (
                <li className="layout-breadcrumb-chevron"> / </li>
              )}
              <li key={index}>{label}</li>
            </React.Fragment>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default AppBreadcrumb;
