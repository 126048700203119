export const RefreshDisableTime: number = 15000;

export const AppMessages = {
  ERROR_COMMON: "Internal server error.",
  SUCCESS_RESET_PASSWORD_MSG: "Your Password changed successfully.",
  PIN_REGISTRATION_SUCCESS: "PIN Registered Successfully",
  PIN_REGISTRATION_ERROR: "Error in PIN Registration",
};

export const API_ENDPOINTS = {
  SYNC_SERVICE_ENDPOINT: "/api/Response/UserAppointments",
  OFFLINE_PIN_REGISTRATION: "api/user/SetApplicationUserOfflineLoginPin",
  GET_CLIENTUSER_SERVICE_ENDPOINT: "api/user/GetClientUserMapping",
};

export const REGEX = {
  CHECK_SPL_CHARS: /[^\w\s]/,
  ALLOW_ONLY_CHARS: /[^a-zA-Z]/g,
};
