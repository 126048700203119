import { placeHolder } from "../../../data/constants/HelperClass";

const MemberTeamInfo = ({ data, widgetsConfig }: any) => {
  const memberTeamInfo: any[] = widgetsConfig;
  return (
    <div className="flex flex-col gap-2 set-scroll-h touch-pan-y pb-2">
      <div className="title-text">WellBe Team</div>
      {data?.map((each: any, i: number) => {
        return (
          <div className="flex flex-col !gap-3" key={`i${i}`}>
            {memberTeamInfo.map((item, index) => {
              return (
                <div className="flex flex-col" key={index}>
                  <div className="label-text">{item?.title}</div>
                  <div className="value-text">
                    {placeHolder(each[item.field])}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
export default MemberTeamInfo;
