import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AppointmentService } from "../../services/AppointmentService";
import { GenericTable } from "../generic-components/GenericTable";
import sanitizeText from "../../layout/hooks/senitizeData";

export const Communication = ({ config, expanded }: any) => {
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  const resultData = data?.map((x: any) => {
    let each = { ...x };

    config?.cols
      ?.filter((a: any) => a.valueClassName)
      .forEach((element: any) => {
        each["className"] = each["className"] ?? {};
        let actCol = element.col ?? element.valueCol;
        if (each?.[actCol]) {
          each["className"][
            actCol
          ] = `${element.valueClassName?.toLowerCase()}-${each?.[actCol]
            ?.toLowerCase()
            .replace(" ", "-")}`;
        }
      });

    config?.cols
      ?.filter((a: any) => a.dataType === "Date")
      .forEach((element: any) => {
        each[element.col] = each?.[element.col]?.replace(
          "00:00:00",
          each?.time
        );
      });

    each.summary = sanitizeText(each.summary);

    return each;
  });

  const limited = expanded !== true ? resultData?.slice(0, 5) ?? [] : resultData ?? [];
  return <GenericTable payload={limited} config={config} expanded={expanded} />;
};
