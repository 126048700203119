import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAppStatus } from "../Redux/features/appStatusSlice";
import {
  SetLayoutState,
  selectLayoutState,
} from "../Redux/features/layoutStateSlice";
import AppHamburgerMenu from "./AppHamburgerMenu";
import AppMenu from "./AppMenu";
import { LayoutContext } from "./context/layoutcontext";
import { MenuProvider } from "./context/menucontext";

const AppSidebar = () => {
  const dispatch = useDispatch();
  const appStatus = useSelector(selectAppStatus);
  const layoutState = useSelector(selectLayoutState);
  const { layoutConfig } = useContext(LayoutContext);

  const anchor = () => {
    dispatch(
      SetLayoutState({
        anchored: !layoutState.anchored,
      })
    );
  };

  return (
    <>
      <AppHamburgerMenu />
      <div className="sidebar-header">
        <a href="/#/pages/appointment-details" className="app-logo">
          <div className="app-logo-normal flex flex-row items-center align-items-center gap-2 w-full">
            {(layoutConfig.menuTheme === "colorScheme" ||
              layoutConfig.menuTheme === "transparent") &&
              layoutConfig.colorScheme === "light" && (
                <img
                  src="/svg/logo.svg"
                  alt="app logo"
                  height={50}
                  width={50}
                  className="w-14 h-14"
                />
              )}
            {(layoutConfig.menuTheme === "primaryColor" ||
              layoutConfig.colorScheme === "dark") && (
              <img
                src="/svg/logo_color_scheme.svg"
                alt="app logo"
                height={50}
                width={50}
                className="w-14 h-14"
              />
            )}

            <div className="app-name-text text-2xl">ClairEMR</div>
          </div>
          <div className="app-logo-small flex flex-col items-center justify-center align-items-center w-full">
            {(layoutConfig.menuTheme === "colorScheme" ||
              layoutConfig.menuTheme === "transparent") &&
              layoutConfig.colorScheme === "light" && (
                <img
                  src="/svg/logo.svg"
                  alt="app logo"
                  height={22}
                  width={22}
                />
              )}
            {(layoutConfig.menuTheme === "primaryColor" ||
              layoutConfig.colorScheme === "dark") && (
              <img
                src="/svg/logo_color_scheme.svg"
                alt="app logo"
                height={22}
                width={22}
              />
            )}
            <div className="app-name-text text-xl">ClairEMR</div>
          </div>
        </a>
        <button
          className="layout-sidebar-anchor p-link z-2 mb-2"
          type="button"
          onClick={anchor}
        ></button>
      </div>
      <div className={"app-status " + (appStatus ? "online" : "offline")}>
        <div>
          <div className="!w-3 h-3 flex items-center justify-center rounded-full  p-[2px]">
            <i className={"!text-lg cl_dot_big "}></i>
          </div>

          <div className="app-status-text">
            {appStatus ? "Online" : "Offline"}
          </div>
        </div>
      </div>
      <div className="layout-menu-container">
        <MenuProvider>
          {/* add all you routing to AppMenu component instead of adding here directly */}
          <AppMenu />
        </MenuProvider>
      </div>
    </>
  );
};

export default AppSidebar;
