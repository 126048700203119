import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { AppMessages } from "../../data/constants/AppConstants";
import { ToasterSeverity } from "../../data/constants/Enum";
import { appVersion, currentYear } from "../../data/constants/HelperClass";
import { IdenityServerResponseErrorModel, IdenityServerResponseModel } from "../../model/common/identity-server-response-model";
import { ResetPasswordModel } from "../../model/identity-server/reset-password-model";
import { useAuth } from "../auth/authProvider";

const ResetPassword = () => {
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string[]>([]);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const authContext = useAuth();

  const location = useLocation();
  const toast = useRef<Toast>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const userName = searchParams.get("username");
    if (userName === null) {
      return window.location.replace("/");
    }

    if (userName) setUsername(userName);

    if (code) setCode(code);
  }, [location.search]);

  const validatePassword = (value: string) => {
    // const passwordRegEx =
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-~`!@#$%^&*()_+=|\\}{[\]"';:,<.>?/]).{8,}$/;

    const errors: string[] = [];

    if (!value) {
      errors.push("* Password is mandatory.");
    }

    if (value.length < 8) {
      errors.push("* Password must contain at least 8 characters.");
    }

    if (!/\d/.test(value)) {
      errors.push("* Password must contain at least 1 number.");
    }

    if (!/[a-z]/.test(value)) {
      errors.push("* Password must contain at least 1 lowercase letter.");
    }

    if (!/[A-Z]/.test(value)) {
      errors.push("* Password must contain at least 1 uppercase letter.");
    }

    if (!/[-~`!@#$%^&*()_+=|\\}{[\]"';:,<.>?/]/.test(value)) {
      errors.push("* Password must contain at least 1 special character.");
    }

    setPasswordError(errors);
    setIsPasswordValid(errors.length === 0);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
    comfirmPasswordValidationOnChangePassword(e.target.value, confirmPassword);
  };

  const comfirmPasswordValidationOnChangePassword = (password: string, confirmPassword: string) => {
    if (confirmPassword && confirmPassword !== password) {
      setConfirmPasswordError("* Passwords does not match.");
      setIsConfirmPasswordValid(false);
    } else {
      setConfirmPasswordError("");
      setIsConfirmPasswordValid(true);
    }
  };

  const validateConfirmPassword = (value: string) => {
    if (!value) {
      setConfirmPasswordError("* Confirm Password is mandatory.");
      setIsConfirmPasswordValid(false);
    } else if (value !== password) {
      setConfirmPasswordError("* Passwords does not match.");
      setIsConfirmPasswordValid(false);
    } else {
      setConfirmPasswordError("");
      setIsConfirmPasswordValid(true);
    }
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    validateConfirmPassword(e.target.value);
  };

  const useResetPasswordSubmit = async (e: any) => {
    e.preventDefault();

    if (isPasswordValid && isConfirmPasswordValid) {
      const data: ResetPasswordModel = {
        email: username,
        userName: username,
        password: password,
        confirmPassword: confirmPassword,
        identityCode: code,
      };

      try {
        let res = await fetch(`${process.env.REACT_APP_AUTHORITY}api/User/ResetPassword`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        let response: IdenityServerResponseModel = await res.json();

        if (response.succeeded) {
          toast.current?.show({
            severity: ToasterSeverity.success,
            summary: AppMessages.SUCCESS_RESET_PASSWORD_MSG,
          });

          authContext.signinRedirect();
        }

        if (!response.succeeded) {
          response.errors.forEach((error: IdenityServerResponseErrorModel) => {
            toast.current?.show({
              severity: ToasterSeverity.error,
              summary: error.description,
            });
          });
        }
      } catch (ex: any) {
        toast.current?.show({
          severity: ToasterSeverity.error,
          summary: AppMessages.ERROR_COMMON,
        });
      }
    }
  };

  const handleLogin = () => {
    authContext.signinRedirect();
  };

  return (
    <div className="reset-password-page">
      <Toast ref={toast} />
      <div className="flex place-content-center items-center h-screen justify-between p-10 lg:p-24 xl:p-60 ml-auto">
        <div className="self-center flex flex-col lg:flex-row justify-between w-full !gap-5 items-center">
          <div className="flex items-start">
            <div className="flex flex-col text-white">
              <div>Welcome to</div>
              <h1 className="my-0 text-white font-medium">ClairEMR®</h1>
              <div className="text-sm version-text">Version {appVersion}</div>
            </div>
          </div>
          <div className={"bg-white flex flex-col rounded-xl px-12 py-14 gap-3 w-[412px]"}>
            <div className="flex flex-col !gap-8 justify-stretch">
              <div>
                <img className="self-end" src="/svg/logo_full.svg" alt="powered by aaneel" />
              </div>
              <div className="flex flex-col gap-2">
                <h1 className="login-text font-medium">Reset Password</h1>
                <div className="flex gap-1">
                  <div className="gray-text">to continue</div>
                  <div className="aaneel-text font-medium">ClairEMR®</div>
                </div>
              </div>
              <div className="flex flex-col !gap-6">
                <div className="p-float-label">
                  <InputText id="username" className="w-full" value={username} disabled />
                  <label htmlFor="username">Username</label>
                </div>
                <div className="flex flex-col !gap-2">
                  <div className="p-float-label">
                    <InputText
                      id="password"
                      type="password"
                      className={passwordError.length > 0 ? "w-full p-invalid" : "w-full"}
                      value={password}
                      onChange={handlePasswordChange}
                      onBlur={handlePasswordChange}
                    />
                    <label htmlFor="password">Enter Password</label>
                  </div>
                  {passwordError.length > 0 && (
                    <div className="text-danger text-xs leading-normal">
                      {passwordError.map((error: any, index: any) => (
                        <div className="text-danger" key={index}>
                          {error}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="flex flex-col !gap-2">
                  <div className="p-float-label">
                    <InputText
                      id="confirmPassword"
                      type="password"
                      className={confirmPasswordError ? "w-full p-invalid" : "w-full"}
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      onBlur={handleConfirmPasswordChange}
                    />
                    <label htmlFor="confirmPassword">Confirm Password</label>
                  </div>
                  {confirmPasswordError && <div className="text-danger text-xs leading-normal">{confirmPasswordError}</div>}
                </div>
              </div>
              <Button
                rounded
                label="Reset"
                aria-label="Reset"
                disabled={!(isPasswordValid && isConfirmPasswordValid)}
                title="Reset"
                className="button-font-normal"
                onClick={useResetPasswordSubmit}
              />
            </div>
            <div className="flex flex-row justify-center content-center !gap-2 text-sm">
              <label className="text-gray-700 mt-px">Remember Password?</label>
              <Button label="Login now!" text rounded className="text-primary font-medium py-0 text-sm" onClick={handleLogin} />
            </div>
            <div className="flex flex-row !gap-3 justify-center">
              <div className="self-end leading-none gray-text text-xs">© {currentYear} AaNeel Infotech LLC</div>
              <div className="relative">
                <div className="gray-text absolute right-0 -top-[0.5rem] text-[0.625rem]">Powered by</div>
                <img className="self-end" src="/svg/aaneel-poweredby.svg" alt="powered by aaneel" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
