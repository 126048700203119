import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AppointmentService } from "../../services/AppointmentService";

interface TimeProps {
  config: any;
  layoutMode: boolean;
}

const InfoCard = ({ config, layoutMode }: TimeProps) => {
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config?.i);
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="flex items-center p-4 gap-3 h-full border-gray-200">
      <div className="flex flex-col gap-1 w-full">
        <div className="header-label text-base title-font-900 font-medium">{config.label}</div>
        <div className="!grid grid-cols-2 gap-4 items-center">
          {config?.dataset?.map((info: any, index: any) => {
            return (
              <div className="flex flex-col justify-center items-start" key={index}>
                <div className="font-medium text-xl">{isNaN(data?.[info.field]) || data?.[info.field] === 0 ? "N/A" : data?.[info.field]}</div>
                <div className="text-xs">{info.details}</div>
              </div>
            );
          })}
        </div>
      </div>
      {config?.hasNorthEastArrow && (
        <Button rounded text icon="cl_arrow_right" className=" minicard-lefticon -rotate-45 text-2xl text-primary" disabled={layoutMode}></Button>
      )}
    </div>
  );
};

export default InfoCard;
