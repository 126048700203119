export class Appointments {
  public static AppontmentDatabase = {
    appointments: {
      filterColumn: "status",
      dropDown: [],
      hasNorthEastArrow: true,
      list: {},
      displayOnlyCount: true,
      i: "todaysappointments",
      label: "Todays Appointments",
      sortField: "visitTime",
      filters: [],
      cols: [
        {
          col: "start",
          display: "Visit Time",
          isSortable: true,
          width: "",
        },
        // {
        //   col: "visitId",
        //   display: "Member ID",
        //   width: "",
        // },
        {
          col: "memberName",
          display: "Member",
          isSortable: true,
        },
        {
          col: "memberAge",
          display: "Age",
        },
        {
          col: "gender",
          display: "Sex",
        },

        {
          col: "address",
          display: "Address",
        },
        {
          col: "visitType",
          display: "Visit Type",
          isSortable: true,
        },
        {
          col: "appointmentStatus",
          display: "Status",
          isSortable: true,
        },
      ],
      dataset: [],
    },
  };
}
