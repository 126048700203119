import { createSlice } from "@reduxjs/toolkit";
import { IOfflinePin } from "../../components/pin-input/PinInput";
import { RootState } from "../app/store";

const initialState: IOfflinePin = {
  pin: "",
  isPinVerified: false,
};

const offlinePinSlice = createSlice({
  name: "offlinePinSlice",
  initialState,
  reducers: {
    offlinePinInfo: (state, action) => {
      if (action.payload.pin !== undefined) state.pin = action.payload.pin;
      if (action.payload.isPinVerified !== undefined) state.isPinVerified = action.payload.isPinVerified;
    },
  },
});

export const { offlinePinInfo } = offlinePinSlice.actions;
export const selectOfflinePin = (state: RootState) => state.offlinePinState;

export default offlinePinSlice.reducer;
