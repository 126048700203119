export const validateOfflineTokenService = () => {
  const token = sessionStorage.getItem("offline-auth") ?? "";
  if (token !== null && token !== "") {
    const [, payloadBase64] = token.split(".");
    const decodedPayload = atob(payloadBase64);
    const decodedPayloadObject = JSON.parse(decodedPayload);
    const expiryTimestampString = decodedPayloadObject.exp;
    const expiryTimestamp = expiryTimestampString
      ? parseInt(expiryTimestampString, 10)
      : null;

    if (expiryTimestamp !== null && !isNaN(expiryTimestamp)) {
      const currentTimestamp = Math.floor(Date.now() / 1000);

      if (currentTimestamp > expiryTimestamp) {
        sessionStorage.setItem("offline-auth", "");
        return false;
      } else {
        return true;
      }
    }
  }
  return false;
};
