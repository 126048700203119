"use client";
import { PrimeReactContext } from "primereact/api";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { Sidebar } from "primereact/sidebar";
import { TabPanel, TabView } from "primereact/tabview";
import { classNames } from "primereact/utils";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetLayoutConfig, selectLayoutConfig } from "../Redux/features/layoutConfigSlice";
import { SetLayoutState, selectLayoutState } from "../Redux/features/layoutStateSlice";
import AppLayoutSetting from "../components/layout-setting/AppLayoutSetting";
import type { AppConfigProps, ColorScheme } from "../types/types";
import { LayoutContext } from "./context/layoutcontext";

const AppConfig = (props: AppConfigProps) => {
  const dispatch = useDispatch();
  const layoutConfig = useSelector(selectLayoutConfig);
  const layoutState = useSelector(selectLayoutState);
  const { isSlim, isSlimPlus, isHorizontal } = useContext(LayoutContext);
  const { setRipple, changeTheme } = useContext(PrimeReactContext);
  const scales = [14, 15, 16, 17, 18];
  const componentThemes = [
    { name: "clair", color: "#4D5788" },
    { name: "indigo", color: "#6366F1" },
    { name: "blue", color: "#3B82F6" },
    { name: "purple", color: "#8B5CF6" },
    { name: "teal", color: "#14B8A6" },
    { name: "cyan", color: "#06b6d4" },
    { name: "green", color: "#10b981" },
    { name: "orange", color: "#f59e0b" },
    { name: "pink", color: "#d946ef" },
  ];

  useEffect(() => {
    if (isSlim() || isSlimPlus() || isHorizontal()) {
      dispatch(SetLayoutState({ resetMenu: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutConfig.menuMode]);

  const onConfigButtonClick = () => {
    dispatch(
      SetLayoutState({
        configSidebarVisible: true,
      }),
    );
  };

  const onConfigSidebarHide = () => {
    dispatch(
      SetLayoutState({
        configSidebarVisible: false,
      }),
    );
  };

  const changeInputStyle = (e: RadioButtonChangeEvent) => {
    dispatch(
      SetLayoutConfig({
        inputStyle: e.value,
      }),
    );
  };

  const changeRipple = () => {
    if (setRipple) setRipple(!layoutConfig.ripple);
    dispatch(
      SetLayoutConfig({
        ripple: !layoutConfig.ripple,
      }),
    );
  };

  const changeMenuMode = (e: RadioButtonChangeEvent) => {
    dispatch(SetLayoutConfig({ menuMode: e.value }));
  };

  const changeMenuTheme = (e: RadioButtonChangeEvent) => {
    dispatch(SetLayoutConfig({ menuTheme: e.value }));
  };

  const changeColorScheme = (colorScheme: ColorScheme) => {
    changeTheme?.(layoutConfig.colorScheme, colorScheme, "theme-link", () => {
      dispatch(SetLayoutConfig({ colorScheme }));
    });
  };

  const _changeTheme = (theme: string) => {
    const linkElement = document.getElementById("theme-link");
    replaceLink(linkElement, theme, () => {
      dispatch(SetLayoutConfig({ theme: theme }));
    });
  };

  const replaceLink = (linkElement: any, theme: string, onComplete: Function) => {
    const id = linkElement.getAttribute("id");
    const cloneLinkElement = linkElement.cloneNode(true);

    cloneLinkElement.setAttribute("href", linkElement.getAttribute("href").replace(layoutConfig.theme, theme));
    cloneLinkElement.setAttribute("id", id + "-clone");

    linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

    cloneLinkElement.addEventListener("load", () => {
      linkElement.remove();
      cloneLinkElement.setAttribute("id", id);
      onComplete();
    });
  };

  const decrementScale = () => {
    const preScale = layoutConfig.scale;
    dispatch(
      SetLayoutConfig({
        scale: preScale - 1,
      }),
    );
  };

  const incrementScale = () => {
    const preScale = layoutConfig.scale;

    dispatch(
      SetLayoutConfig({
        scale: preScale + 1,
      }),
    );
  };

  const applyScale = () => {
    document.documentElement.style.fontSize = layoutConfig.scale + "px";
  };

  useEffect(() => {
    applyScale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutConfig.scale]);

  return (
    <>
      <button className="layout-config-button config-link" type="button" onClick={onConfigButtonClick}>
        <div className="cl_settings text-2xl"></div>
      </button>
      <Sidebar
        visible={layoutState.configSidebarVisible}
        onHide={onConfigSidebarHide}
        position="right"
        className="layout-config-sidebar w-auto app-conf-sidebar"
      >
        <TabView>
          <TabPanel header="UI Settings" leftIcon="cl_color_palette mr-2">
            <h5 className="mb-4">Themes</h5>
            <div className="flex flex-wrap row-gap-3 gap-2 w-[238px]">
              {componentThemes.map((theme, i) => {
                return (
                  <div key={i}>
                    <button
                      type="button"
                      className="cursor-pointer p-link !w-12 h-12 border-circle flex-shrink-0 flex align-items-center justify-content-center"
                      title={theme.name}
                      onClick={() => _changeTheme(theme.name)}
                      style={{ backgroundColor: theme.color }}
                    >
                      {theme.name === layoutConfig.theme && <i className="cl_check_done text-white"></i>}
                    </button>
                  </div>
                );
              })}
            </div>

            <h5>Scale</h5>
            <div className="flex align-items-center">
              <Button
                icon="cl_subtract"
                type="button"
                onClick={decrementScale}
                className="!w-8 h-8 mr-2"
                rounded
                text
                disabled={layoutConfig.scale === scales[0]}
              ></Button>
              <div className="flex gap-2 align-items-center">
                {scales.map((s, i) => {
                  return (
                    <i
                      key={i}
                      className={classNames("cl_dot_big text-300", {
                        "text-primary-500": s === layoutConfig.scale,
                      })}
                    ></i>
                  );
                })}
              </div>
              <Button
                icon="cl_add"
                type="button"
                onClick={incrementScale}
                className="!w-8 h-8 ml-2"
                rounded
                text
                disabled={layoutConfig.scale === scales[scales.length - 1]}
              ></Button>
            </div>

            {!props.minimal && (
              <>
                <h5>Menu Type</h5>
                <div className="flex flex-wrap row-gap-3 w-[238px]">
                  <div className="flex align-items-center gap-2 w-1/2">
                    <RadioButton
                      name="menuMode"
                      value={"static"}
                      checked={layoutConfig.menuMode === "static"}
                      onChange={(e) => changeMenuMode(e)}
                      inputId="mode1"
                    ></RadioButton>
                    <label htmlFor="mode1">Static</label>
                  </div>
                  <div className="flex align-items-center gap-2 w-1/2">
                    <RadioButton
                      name="menuMode"
                      value={"overlay"}
                      checked={layoutConfig.menuMode === "overlay"}
                      onChange={(e) => changeMenuMode(e)}
                      inputId="mode2"
                    ></RadioButton>
                    <label htmlFor="mode2">Overlay</label>
                  </div>
                  <div className="flex align-items-center gap-2 w-1/2">
                    <RadioButton
                      name="menuMode"
                      value={"slim"}
                      checked={layoutConfig.menuMode === "slim"}
                      onChange={(e) => changeMenuMode(e)}
                      inputId="mode3"
                    ></RadioButton>
                    <label htmlFor="mode3">Slim</label>
                  </div>
                  <div className="flex align-items-center gap-2 w-1/2">
                    <RadioButton
                      name="menuMode"
                      value={"slim-plus"}
                      checked={layoutConfig.menuMode === "slim-plus"}
                      onChange={(e) => changeMenuMode(e)}
                      inputId="mode4"
                    ></RadioButton>
                    <label htmlFor="mode4">Slim +</label>
                  </div>
                  <div className="flex align-items-center gap-2 w-1/2">
                    <RadioButton
                      name="menuMode"
                      value={"drawer"}
                      checked={layoutConfig.menuMode === "drawer"}
                      onChange={(e) => changeMenuMode(e)}
                      inputId="mode7"
                    ></RadioButton>
                    <label htmlFor="mode7">Drawer</label>
                  </div>
                  <div className="flex align-items-center gap-2 w-1/2">
                    <RadioButton
                      name="menuMode"
                      value={"reveal"}
                      checked={layoutConfig.menuMode === "reveal"}
                      onChange={(e) => changeMenuMode(e)}
                      inputId="mode5"
                    ></RadioButton>
                    <label htmlFor="mode6">Reveal</label>
                  </div>
                  <div className="flex align-items-center gap-2 w-1/2">
                    <RadioButton
                      name="menuMode"
                      value={"horizontal"}
                      checked={layoutConfig.menuMode === "horizontal"}
                      onChange={(e) => changeMenuMode(e)}
                      inputId="mode5"
                    ></RadioButton>
                    <label htmlFor="mode5">Horizontal</label>
                  </div>
                </div>

                <h5>Menu Theme</h5>
                <div className="field-radiobutton">
                  <RadioButton
                    name="menuTheme"
                    value="colorScheme"
                    checked={layoutConfig.menuTheme === "colorScheme"}
                    onChange={(e) => changeMenuTheme(e)}
                    inputId="menutheme-colorscheme"
                  ></RadioButton>
                  <label htmlFor="menutheme-colorscheme">Color Scheme</label>
                </div>
                <div className="field-radiobutton">
                  <RadioButton
                    name="menuTheme"
                    value="primaryColor"
                    checked={layoutConfig.menuTheme === "primaryColor"}
                    onChange={(e) => changeMenuTheme(e)}
                    inputId="menutheme-primarycolor"
                  ></RadioButton>
                  <label htmlFor="menutheme-primarycolor">Primary Color</label>
                </div>
                <div className="field-radiobutton">
                  <RadioButton
                    name="menuTheme"
                    value="transparent"
                    checked={layoutConfig.menuTheme === "transparent"}
                    onChange={(e) => changeMenuTheme(e)}
                    inputId="menutheme-transparent"
                  ></RadioButton>
                  <label htmlFor="menutheme-transparent">Transparent</label>
                </div>
              </>
            )}

            <h5>Color Scheme</h5>
            <div className="field-radiobutton">
              <RadioButton
                name="colorScheme"
                value="light"
                checked={layoutConfig.colorScheme === "light"}
                onChange={(e) => changeColorScheme(e.value)}
                inputId="mode-light"
              ></RadioButton>
              <label htmlFor="mode-light">Light</label>
            </div>
            {/* <div className="field-radiobutton">
              <RadioButton
                name="colorScheme"
                value="dim"
                checked={layoutConfig.colorScheme === "dim"}
                onChange={(e) => changeColorScheme(e.value)}
                inputId="mode-dim"
              ></RadioButton>
              <label htmlFor="mode-dim">Dim</label>
            </div> */}
            <div className="field-radiobutton">
              <RadioButton
                name="colorScheme"
                value="dark"
                checked={layoutConfig.colorScheme === "dark"}
                onChange={(e) => changeColorScheme(e.value)}
                inputId="mode-dark"
              ></RadioButton>
              <label htmlFor="mode-dark">Dark</label>
            </div>

            {!props.minimal && (
              <>
                <h5>Input Style</h5>
                <div className="flex">
                  <div className="field-radiobutton flex-1">
                    <RadioButton
                      name="inputStyle"
                      value="outlined"
                      checked={layoutConfig.inputStyle === "outlined"}
                      onChange={(e) => changeInputStyle(e)}
                      inputId="outlined_input"
                    ></RadioButton>
                    <label htmlFor="outlined_input">Outlined</label>
                  </div>
                  <div className="field-radiobutton flex-1">
                    <RadioButton
                      name="inputStyle"
                      value="filled"
                      checked={layoutConfig.inputStyle === "filled"}
                      onChange={(e) => changeInputStyle(e)}
                      inputId="filled_input"
                    ></RadioButton>
                    <label htmlFor="filled_input">Filled</label>
                  </div>
                </div>

                <h5>Ripple Effect {layoutConfig.ripple}</h5>
                <InputSwitch checked={layoutConfig.ripple} onChange={changeRipple}></InputSwitch>
              </>
            )}
          </TabPanel>
          <TabPanel header="Layout Settings" leftIcon="cl_view_quilt_fill mr-2">
            <AppLayoutSetting />
          </TabPanel>
        </TabView>
      </Sidebar>
    </>
  );
};

export default AppConfig;
