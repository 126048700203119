import React from "react";
import { placeHolder } from "../../../data/constants/HelperClass";

const MemberPharmacyDetails = ({ data, widgetsConfig }: any) => {
  const memberPharmacyDetails: any[] = widgetsConfig;
  const pharmacyName = memberPharmacyDetails[0];
  const pharmacyDetails = memberPharmacyDetails.slice(1);

  const formatPhoneNumber = (phoneNumber: string) => {
    // Check if the phone number is not empty
    if (phoneNumber) {
      // Format the phone number to (XXX) XXX-XXXX
      const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      return formattedPhoneNumber;
    } else {
      return "N/A"; // Return empty string if the phone number is empty
    }
  };

  return (
    <div className="flex flex-col gap-2 set-scroll-h touch-pan-y pb-2">
      <div className="title-text">Pharmacy Details</div>
      {data?.map((each: any, i: number) => {
        return (
          <React.Fragment key={`i${i}`}>
            <div className="flex flex-col gap-2">
              <div className="title-text">{placeHolder(each[pharmacyName.field])}</div>
            </div>
            <div className="!grid grid-cols-2 flex-wrap gap-3">
              {pharmacyDetails.map((item, index) => {
                return (
                  <div className="flex flex-col" key={index}>
                    <div className="label-text">{item?.title}</div>
                    <div className="value-text">
                      {item.field === "phone" || item.field === "fax"
                        ? formatPhoneNumber(placeHolder(each?.[item?.field]))
                        : placeHolder(each?.[item?.field])}
                    </div>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
export default MemberPharmacyDetails;
