import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AppointmentService } from "../../services/AppointmentService";
import { GenericTable } from "../generic-components/GenericTable";

export const Screenings = ({ config, expanded }: any) => {
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  const resultData = data?.map((x: any) => {
    let each = { ...x };
    if (each.screeningType === "SH") {
      each.screeningType = "Social Determinants of Health";
      if (!each.score) {
        each.score = "N/A";
      }
    }
    config?.cols
      ?.filter((a: any) => a.valueClassName)
      .forEach((element: any) => {
        each["className"] = each["className"] ?? {};
        let actCol = element.col ?? element.valueCol;
        if (each?.[actCol]) {
          each["className"][actCol] = `${element.valueClassName?.toLowerCase()}-${each?.[actCol]?.toLowerCase().replace(" ", "-")}`;
        }
      });
    return each;
  });

  return <GenericTable payload={resultData} config={config} expanded={expanded} />;
};
