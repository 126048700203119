import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { RefreshData } from "../../model/refresh-data-model";

const initialState: RefreshData = {
  refreshString: ""
};

const confirmRefreshDataSlice = createSlice({
  name: "refreshData",
  initialState,
  reducers: {
    setConfirmationRefreshString: (state, action) => {
      state.refreshString = action.payload;
    },
  },
});

export const { setConfirmationRefreshString } = confirmRefreshDataSlice.actions;
export const selectConfirmRefreshString = (state: RootState) => state.confirmRefreshData.refreshString;

export default confirmRefreshDataSlice.reducer;
