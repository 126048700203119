import { Button } from "primereact/button";
import { forwardRef, useContext, useImperativeHandle, useRef } from "react";
import type { AppTopbarRef } from "../types/types";
import { LayoutContext } from "./context/layoutcontext";

const AppHamburgerMenu = forwardRef<AppTopbarRef>(({}, ref) => {
  const { onMenuToggle } = useContext(LayoutContext);
  const menubuttonRef = useRef(null);

  useImperativeHandle(ref, () => ({
    menubutton: menubuttonRef.current,
  }));

  return (
    <Button
      ref={menubuttonRef}
      type="button"
      className="topbar-menubutton p-link p-trigger hamburger"
      onClick={onMenuToggle}
    >
      {/* not an actual icon name */}
      <i className="cl_nav_open_close"></i>
    </Button>
  );
});

AppHamburgerMenu.displayName = "AppHamburgerMenu";

export default AppHamburgerMenu;
