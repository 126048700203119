import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ExpandedItemModel } from "../../model/expanded-item-model";

const initialState: ExpandedItemModel = {
  item: {},
};

const expandItemSlice = createSlice({
  name: "expandItem",
  initialState,
  reducers: {
    ExpandItemBase: (state, action) => {
      state.item = action.payload;
    },
  },
});

export const { ExpandItemBase } = expandItemSlice.actions;
export const selecActiveItem = (state: RootState) => state.expandItem;

export default expandItemSlice.reducer;
