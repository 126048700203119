import moment from "moment";
import { Tooltip } from "primereact/tooltip";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { DateTimeConstant } from "../../data/constants/DateTimeConstants";

// Import Swiper styles
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";
import "swiper/css";
import "swiper/css/bundle";
import { HeaderData, HeaderDataValue } from "../../data/constants/Enum";
import { useTextOverflowTooltip } from "../../layout/hooks/useTextOverflow";

const MemberDemographics = ({ data, widgetsConfig }: any) => {
  const op: any = useRef(null);
  useTextOverflowTooltip();

  for (var i = 0; i < widgetsConfig.length; i++) {
    if (widgetsConfig[i].field === "primaryPhone") {
      widgetsConfig[i].dropdownProps.dropdownData = [];
      widgetsConfig[i].dropdownProps.dropdownData.push({
        itemIcon: "cl_mobile",
        itemTitle: "Primary Phone",
        itemValue: data?.primaryPhone ? data?.primaryPhone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : "N/A",
        field: "primaryPhone",
      });
      widgetsConfig[i].dropdownProps.dropdownData.push({
        itemIcon: "cl_home_phone",
        itemTitle: "Secondary Phone",
        itemValue: data?.secondaryPhone ? data?.secondaryPhone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : "N/A",
        field: "secondaryPhone",
      });
    }
  }

  //const headerData = ["name", "gender", "age", "dob", "status"];
  return (
    <div className="flex flex-row gap-4 px-4 !py-2 clair-react-grid-header member-header rounded-t-xl">
      {/* max-h-20 */}
      <div className="flex flex-col !gap-3 flex-shrink-0 pr-4 items-start justify-center">
        <h1 className="font-semibold name-text xl:text-2xl">
          {/* <i className="cl_user mr-2"></i> */}
          {data?.[widgetsConfig?.filter((a: any) => a.displayFormat === HeaderDataValue.isName)[0].field]}
        </h1>
        <div className="font-normal text-base flex flex-row gap-2 flex-wrap name-text">
          <div className="flex-shrink-0">
            {data?.[widgetsConfig?.filter((a: any) => a.displayFormat === HeaderDataValue.isGender)[0].field] === "M" ? "Male" : "Female"}, &nbsp;
            {data?.[widgetsConfig?.filter((a: any) => a.displayFormat === HeaderDataValue.isAge)[0].field] + " Years"}
          </div>
          <div className="flex flex-row gap-2 items-center justify-center">
            <div className="!w-2 h-2 dot-ico"></div>
            <div>
              {moment(data?.[widgetsConfig?.filter((a: any) => a.displayFormat === HeaderDataValue.isDob)[0].field]).format(DateTimeConstant.DF)}
            </div>
          </div>
          {data?.[widgetsConfig.filter((a: any) => a.displayFormat === HeaderDataValue.isstatus)[0].field] === "True" ? (
            <div className="flex flex-row gap-2 items-center justify-center">
              <div className="!w-2 h-2 dot-ico"></div>
              <div>Engaged</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <Swiper
        className="custom-arrow-swiper"
        // install Swiper modules
        spaceBetween={20}
        slidesPerView={6}
        navigation={true}
        pagination={false}
        scrollbar={false}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 2,
          },
          1050: {
            slidesPerView: 3,
          },
          1366: {
            slidesPerView: 6,
          },
        }}
      >
        {/* {{ draggable: true }} */}
        {widgetsConfig.map((item: any, index: any) => {
          return (
            item.displayFormat !== HeaderDataValue.isName &&
            item.displayFormat !== HeaderDataValue.isAge &&
            item.displayFormat !== HeaderDataValue.isDob &&
            item.displayFormat !== HeaderDataValue.isGender &&
            item.displayFormat !== HeaderDataValue.isstatus &&
            item.displayFormat !== HeaderData.isImage && (
              <SwiperSlide key={index}>
                <div>
                  <div className="flex flex-row flex-shrink-0 overflow-hidden">
                    <div className="flex flex-col !gap-3 overflow-hidden">
                      {/* <div className="font-medium head-text">{item?.title}</div> */}
                      {item?.displayFormat === HeaderData.isPlainText && <div className="font-normal head-text pl-10 ml-4">{item?.title}</div>}
                      {item?.displayFormat !== HeaderData.isPlainText && <div className="font-normal head-text">{item?.title}</div>}

                      {/* Address, Email, Race, Pref Lang */}
                      {item?.displayFormat !== HeaderData.isPlainText && item?.displayFormat !== HeaderData.isDropdown && (
                        <>
                          <Tooltip target={`.${item.field}-tooltip`} />
                          <div
                            className={`font-normal name-text member-details text-ellipsis overflow-hidden whitespace-nowrap ${item.field}-tooltip overflow-tooltip`}
                          >
                            {data?.[item?.field]
                              ? item?.field === "address"
                                ? data?.[item?.field].replace(/\s*,\s*/g, ", ")
                                : data?.[item?.field]
                              : "N/A"}
                          </div>
                        </>
                      )}

                      {/* AAneel ID */}
                      {item?.displayFormat === HeaderData.isPlainText && (
                        <>
                          <Tooltip target={`.${item.field}-tooltip`} />
                          <div
                            className={`font-normal name-text text-ellipsis overflow-hidden whitespace-nowrap pl-10 ml-4 ${item.field}-tooltip overflow-tooltip`}
                          >
                            {data?.[item?.field] ?? "N/A"}
                          </div>
                        </>
                      )}

                      {/* phone */}
                      {item?.displayFormat === HeaderData.isDropdown && (
                        <>
                          <Tooltip target={`.${item.field}-tooltip`} />
                          <div
                            className={`font-normal name-text text-ellipsis overflow-hidden whitespace-nowrap ${item.field}-tooltip overflow-tooltip`}
                          >
                            {data?.[item?.field]
                              ? item?.field === "primaryPhone"
                                ? data?.[item?.field].replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
                                : data?.[item?.field]
                              : "N/A"}
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      {item?.displayFormat === HeaderData.isDropdown && (
                        <>
                          <Button
                            rounded
                            onClick={(e) => op.current.toggle(e)}
                            severity="secondary"
                            className="bg-transparent border-none mt-1 text-white"
                            icon="cl_arrow_down_line"
                          ></Button>
                          <OverlayPanel ref={op}>
                            <div className="flex flex-col gap-3">
                              {item?.dropdownProps?.dropdownTitle && (
                                <div className="text-base font-medium dropdown-title-text">{item?.dropdownProps?.dropdownTitle}</div>
                              )}
                              <div className="flex flex-col gap-3">
                                {item?.dropdownProps?.dropdownData?.map((item: any, index: any) => {
                                  return (
                                    <div className="dropdown-item-wrap" key={index}>
                                      <i className={item.itemIcon}></i>
                                      <div className="flex flex-col">
                                        <div className="title-text">{item.itemTitle}</div>
                                        <div className="value-text">{item.itemValue}</div>
                                      </div>
                                    </div>
                                  );
                                })}
                                {item?.dropdownProps?.dropdownProps?.viewMoreButton && (
                                  <Button
                                    className="!h-auto !bg-gray-300 !text-black !font-medium !border-none !px-4 !py-1 focus:!shadow-none"
                                    severity="secondary"
                                  >
                                    {item?.dropdownProps?.dropdownProps.viewMoreButtonText}
                                  </Button>
                                )}
                              </div>
                            </div>
                          </OverlayPanel>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )
          );
        })}
      </Swiper>
    </div>
  );
};
export default MemberDemographics;
