import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ActiveAppointment } from "../../model/active-appointment";

const initialState: ActiveAppointment = {
  id: undefined,
};

const activeAppointmentSlice = createSlice({
  name: "activeAppointment",
  initialState,
  reducers: {
    SetAppointmentId: (state, action) => {
      state.id = action.payload?.id ? parseInt(action.payload?.id) : undefined;
    },
  },
});

export const { SetAppointmentId } = activeAppointmentSlice.actions;
export const selectActiveAppointment = (state: RootState) => state.activeAppintment;

export default activeAppointmentSlice.reducer;
