import Dexie, { Table } from "dexie";

export class AppDatabase extends Dexie {
  public appCollection!: Table<any, number>; // id is number in this case
  public userCollection!: Table<any, number>; // id is number in this case

  public constructor() {
    super("appCollection");
    this.version(2).stores({
      appCollection: "id",
      userCollection: "id",
    });

    this.open().then(() => {});
  }
}
