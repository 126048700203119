import moment from "moment";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { VisibleLayout } from "../../Redux/features/expandModeSlice";
import { ExpandItemBase } from "../../Redux/features/expandedItemSlice";
import { DateTimeConstant } from "../../data/constants/DateTimeConstants";
import { useTextOverflowTooltip } from "../../layout/hooks/useTextOverflow";
import { AppointmentService } from "../../services/AppointmentService";

interface MiniCardProps {
  config: any;
}

const MiniCardLayout = ({ config }: MiniCardProps) => {
  const op: any = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  useTextOverflowTooltip();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config?.i);
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  const handleExpand = () => {
    dispatch(
      ExpandItemBase({
        activeItem: data,
        widgetConfig: config,
      })
    );
    dispatch(VisibleLayout(true));
  };

  const uniquePreferences = new Set();

  return (
    <div className="py-3">
      <div className=" !px-4 items-center gap-1 h-full flex justify-between">
        <div className="flex items-center overflow-hidden w-full">
          <div className="minicard-icon">
            {config?.icon && (
              <i className={"pr-3 !text-2xl " + config?.icon}></i>
            )}
          </div>
          <div className="flex flex-col gap-1 overflow-hidden">
            <div className="text-base font-medium minicard-title text-ellipsis overflow-hidden whitespace-nowrap">
              {config?.label}
            </div>
            <div className="text-sm flex flex-row justify-center items-center gap-2 minicard-data">
              {config?.displayOnlyCount ? (
                <div className="labResults-value">{data?.length}</div>
              ) : (
                <div className="flex justify-center items-center gap-2 overflow-hidden">
                  {config?.dataset?.map((each: any, index: any) => {
                    return (
                      <React.Fragment
                        key={`data-text${data?.[index]?.[each.field]}${index}`}
                      >
                        <div>
                          {config?.dataset?.map((each: any, index: any) => {
                            return (
                              <React.Fragment
                                key={`data-text${
                                  data?.[index]?.[each.field]
                                }${index}`}
                              >
                                {config?.i === "contactPreferences" && (
                                  <div>
                                    {data?.map((contact: any, index: any) => {
                                      // Generate a unique key based on contact preferences
                                      const key = `${contact.isSMS}_${contact.isPhone}_${contact.isEmail}`;

                                      // Check if this combination of preferences is already displayed
                                      if (uniquePreferences?.has(key)) {
                                        // If already displayed, return null to skip rendering
                                        return null;
                                      }

                                      // If not displayed, add the combination to the set
                                      uniquePreferences?.add(key);

                                      // Render icons based on contact preferences
                                      return (
                                        <div
                                          key={index}
                                          className="flex flex-row !gap-2 items-center"
                                        >
                                          <Tooltip target=".cl_phone_fill" />
                                          <i
                                            className={`cl_phone_fill ${
                                              contact.isPhone
                                                ? "dnd-icon-color"
                                                : ""
                                            }`}
                                            data-pr-tooltip={
                                              contact.isPhone
                                                ? "Do not contact via Phone"
                                                : "Contact via Phone"
                                            }
                                            data-pr-position="bottom"
                                          ></i>
                                          <Tooltip target=".cl_sms" />
                                          <i
                                            className={`cl_sms ${
                                              contact.isSMS
                                                ? "dnd-icon-color"
                                                : ""
                                            }`}
                                            data-pr-tooltip={
                                              contact.isSMS
                                                ? "Do not contact via SMS"
                                                : "Contact via SMS"
                                            }
                                            data-pr-position="bottom"
                                          ></i>
                                          <Tooltip target=".cl_email_fill" />

                                          <i
                                            className={`cl_email_fill ${
                                              contact.isEmail
                                                ? "dnd-icon-color"
                                                : ""
                                            }`}
                                            data-pr-tooltip={
                                              contact.isEmail
                                                ? "Do not contact via Email"
                                                : "Contact via Email"
                                            }
                                            data-pr-position="bottom"
                                          ></i>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </div>
                        {data?.[index]?.[each.field] ? (
                          <div
                            className={`text-base text-ellipsis overflow-hidden whitespace-nowrap ${config.i}-tooltip overflow-tooltip`}
                          >
                            {each?.field === "dateAndTime"
                              ? moment(data?.[index]?.[each.field]).format(
                                  DateTimeConstant.DF
                                )
                              : data?.[index]?.[each.field]}
                          </div>
                        ) : each.icon ? (
                          ""
                        ) : (
                          "None"
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex">
          {config?.hasDropdown && (
            <>
              <Button
                rounded
                onClick={(e) => op.current.toggle(e)}
                text
                icon="cl_arrow_down_line"
                className="text-2xl"
                disabled={config?.layoutMode}
              ></Button>
              <OverlayPanel ref={op}>
                <div>
                  {config?.dropdownProps?.dropdownTitle && (
                    <div className="text-base font-medium mb-4">
                      {config?.dropdownProps?.dropdownTitle}
                    </div>
                  )}
                  <div className="flex flex-col gap-3">
                    {config?.dropdownProps?.dropdownData?.map(
                      (item: any, index: number) => {
                        return (
                          (!item.isSideBySide ||
                            (config?.dropdownProps?.dropdownData &&
                              item.isSideBySide &&
                              index <
                                config?.dropdownProps?.dropdownData?.length -
                                  1 &&
                              config?.dropdownProps?.dropdownData[index + 1]
                                .isSideBySide)) && (
                            <div className=" dropdown-item-wrap" key={index}>
                              {item.isSideBySide ? (
                                <div className="flex flex-row gap-3">
                                  <div
                                    className="flex items-center gap-3 "
                                    key={index}
                                  >
                                    <i className={item.itemIcon}></i>
                                    <div className="flex flex-col">
                                      <div className="text-xs text-gray-400">
                                        {item.itemTitle}
                                      </div>
                                      <div className="text-sm">
                                        {item.itemValue}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="flex items-center gap-3 "
                                    key={index}
                                  >
                                    <i
                                      className={
                                        config?.dropdownProps?.dropdownData &&
                                        config?.dropdownProps?.dropdownData[
                                          index + 1
                                        ].itemIcon
                                      }
                                    ></i>
                                    <div className="flex flex-col">
                                      <div className="text-xs text-gray-400">
                                        {config?.dropdownProps?.dropdownData &&
                                          config?.dropdownProps?.dropdownData[
                                            index + 1
                                          ].itemTitle}
                                      </div>
                                      <div className="text-sm">
                                        {config?.dropdownProps?.dropdownData &&
                                          config?.dropdownProps?.dropdownData[
                                            index + 1
                                          ].itemValue}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="flex flex-row items-center gap-3"
                                  key={index}
                                >
                                  <i className={item.itemIcon}></i>
                                  <div className="flex flex-col">
                                    <div className="text-xs text-gray-400">
                                      {item.itemTitle}
                                    </div>
                                    <div className="text-sm">
                                      {item.itemValue}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        );
                      }
                    )}
                    {config?.dropdownProps?.viewMoreButton && (
                      <Button
                        className="!h-auto !font-medium !border-none !px-4 !py-1"
                        severity="secondary"
                      >
                        {config?.dropdownProps.viewMoreButtonText}
                      </Button>
                    )}
                  </div>
                </div>
              </OverlayPanel>
            </>
          )}
          {config.hasInfoIcon && data?.length === 0 && (
            <>
              <Tooltip target=".item-tooltipAD" position="right">
                <div className="flex flex-wrap w-[12.62rem]">
                  {config.headerTooltip}
                </div>
              </Tooltip>
              <div
                className={
                  "item-tooltipAD !w-8 !h-8 inline-flex items-center justify-center rounded-full flex-shrink-0 header-icon"
                }
              >
                <i className="cl_info_line !text-2xl text-primary"></i>
              </div>
            </>
          )}
          {data?.length > 0 && config?.hasNorthEastArrow && (
            <Button
              rounded
              text
              icon="cl_arrow_right"
              className=" minicard-lefticon -rotate-45 text-2xl text-primary"
              disabled={config?.layoutMode}
              onClick={() => handleExpand()}
            ></Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MiniCardLayout;
