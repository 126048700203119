export enum HeaderData {
  isHeaderData = "Header",
  isImage = "Image",
  isToolTip = "ToolTip",
  isPlainText = "PlainText",
  isDropdown = "Dropdown",
}

export enum HeaderDataValue {
  isName = "Name",
  isGender = "Gender",
  isAge = "Age",
  isDob = "Dob",
  isstatus = "Status",
}

export enum Configuration{
  checkNetworkAvailabilityURL = "https://livesessionservice.azurewebsites.net/isonline",
  timeInterval = 5000
}

export enum ToasterSeverity{
  success = 'success',
  info = 'info', 
  warn = 'warn',
  error = 'error'
}