import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { AppStatusModeStateModel } from "../../model/app-status-model";

const initialState: AppStatusModeStateModel = {
  isAppStatus: true,
};

const appStatusSlice = createSlice({
  name: "appStatus",
  initialState,
  reducers: {
    AppStatusLayout: (state, action) => {
      state.isAppStatus = action.payload;
    },
  },
});

export const { AppStatusLayout } = appStatusSlice.actions;
export const selectAppStatus = (state: RootState) =>
  state.appStatus.isAppStatus;

export default appStatusSlice.reducer;
