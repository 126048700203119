import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/app/store";
import { LayoutExportDialog, selectLayoutExportDialog } from "../../../Redux/features/layoutExportDailogModeSlice";

const ExportLayoutPopUp = () => {
  const dispatch = useDispatch();

  const activeUser = useSelector((state: RootState) => state.activeUser);
  const layoutExportDialogVisible = useSelector(selectLayoutExportDialog);

  const [fileName, setFileName] = useState("");

  const handleOnExportLayout = (e: any) => {
    e.preventDefault();
    saveFile(blob);
  };

  const saveFile = async (blob: any) => {
    const a = document.createElement("a");
    a.download = `${fileName}.json`;
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", () => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
    setFileName(() => "");
    setFileDialogMode(false);
  };

  const setFileDialogMode = (mode: boolean) => {
    dispatch(LayoutExportDialog(mode));
  };

  let exportLayoutDataStr: string = localStorage.getItem("layouts") ?? JSON.stringify({ app: [] });
  let exportLayoutData = JSON.parse(exportLayoutDataStr);

  let exportColums = ["label", "i", "size", "resizable", "hasTitle", "expandable"];

  exportLayoutData = exportLayoutData[activeUser.user].map((a: any) => {
    let result: any = {};
    exportColums.forEach((element) => {
      result[element] = a[element];
    });
    return result;
  });

  const blob = new Blob([JSON.stringify(exportLayoutData, null, 2)], {
    type: "application/json",
  });

  return (
    <>
      <Dialog header="Input Layout name" visible={layoutExportDialogVisible.isVisible} onHide={() => setFileDialogMode(false)} draggable={false}>
        <form className="flex flex-col gap-4" onSubmit={handleOnExportLayout}>
          <div className="flex flex-col">
            <InputText
              id="exportedLayoutFileName"
              value={fileName}
              onChange={(e) => setFileName(() => e.target.value)}
              placeholder="Layout name"
              autoFocus={true}
              type="text"
              required
            />
          </div>
          <div className="flex flex-row gap-2">
            <Button label="Cancel" icon="cl_close" onClick={() => setFileDialogMode(false)} className="p-button-text" />
            <Button label="Save" icon="cl_check_done" onClick={handleOnExportLayout} disabled={!fileName} />
          </div>
        </form>
      </Dialog>
    </>
  );
};
export default ExportLayoutPopUp;
