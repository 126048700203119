export const dropStatusList: any = {
  //   topHeader: "All",
  //   clinicianAlerts: "All",
  //   contactPreferences: "All",
  //   labResults: "All",
  //   advanceDirective: "All",
  //   specialist: "All",
  //   memberStatusFlag: "All",
  //   awvDueDate: "All",
  //   stratification: "All",
  problems: "Active",
  medication: "Active",
  //   screenings: "All",
  allergies: "Active",
  qualityManagement: "All",
  vitals: "",
  visitsTimeline:"",
  //   visitsTimeline: "All",
  communication: "All",
  // notes: "All",
  appointments: "All",
  //   riskScore: "All",
  //   mraGaps: "All",
};
