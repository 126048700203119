import he from "he";

const sanitizeText = (text: string) => {
    if (text === null || text === undefined) {
      return "";
    }
    const decodedText = he.decode(text); // Decode HTML entities
    return decodedText.replace(/<[^>]+>/g, "").replace(/ +(?= )/g, "");
  };

export default sanitizeText;