import { useEffect, useState } from "react";
import Loader from "../loader/Loader";
import { useAuth } from "./authProvider";

export const AuthCallBack = () => {
  const authContext = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("UserTokenInfo") ?? ""
  );

  useEffect(() => {
    const handleSignIn = async () => {
      try {
        if (
          window.location.href.indexOf("auth-callback") <= -1 &&
          !authContext.isAuthenticated()
        ) {
          authContext.signinRedirect();
        } else if (window.location.href.indexOf("auth-callback") > -1) {
          await authContext.signinRedirectCallback();
          setIsLoggedIn(localStorage.getItem("UserTokenInfo") ?? "");
        }
      } catch (error) {
        console.error("Error during sign-in callback:", error);
      }
    };
    handleSignIn();
  }, []);

  return (
    <>
      {isLoggedIn === "" ? (
        <div>
          <Loader />
        </div>
      ) : null}
    </>
  );
};
