import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectFilter } from "../../Redux/features/filterDataTableSlice";
import { AppointmentService } from "../../services/AppointmentService";
import { GenericTable } from "../generic-components/GenericTable";

export const Appointments = ({ config }: any) => {
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);
  const filterSelector = useSelector(selectFilter);

  const filterData = (filterData: any) => {
    const filterValue = filterSelector.filters[config.i];

    if (filterValue === "Upcoming") {
      filterData = filterData.filter((a: any) =>
        moment(moment(a[config.sortField])).isAfter(moment(new Date()))
      );
    } else if (filterValue === "Past") {
      filterData = filterData.filter((a: any) =>
        moment(moment(a[config.sortField])).isBefore(moment(new Date()))
      );
    }

    return filterData;
  };
  const formatedData = data?.map((x: any) => {
    let each = { ...x };
    config?.cols
      ?.filter((a: any) => a.valueClassName)
      .forEach((element: any) => {
        each["className"] = each["className"] ?? {};
        let actCol = element.valueCol ?? element.col;

        if (each?.[actCol]) {
          each["className"][
            actCol
          ] = `${element.valueClassName?.toLowerCase()}-${each?.[actCol]
            ?.toLowerCase()
            ?.replace(" ", "-")}`;
        }
      });

    // Apply date split logic
    config?.cols
      ?.filter((a: any) => a.dataType === "Date")
      .forEach(() => {
        // each[element.col] = DateTimeSplitter(each?.[element.col]);
      });

    return each;
  });
  const resultData = filterData(formatedData ?? []);

  return <GenericTable payload={resultData} config={config} />;
};
