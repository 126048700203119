import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectAppStatus } from "../Redux/features/appStatusSlice";
import Access403 from "../components/access-403/Access403";
import Loader from "../components/loader/Loader";
import { IIdentityServerResponseApplicationUser } from "../model/common/identity-server-response-model";
import { useCheckWithMinimalAPI } from "../services/checkNetworkAvailibilityService";
import { useClientUserService } from "../services/clientUserService";
import { validateOfflineTokenService } from "../services/validateOfflineTokenService";

const AuthGuard = (props: any) => {
  const isAppOnline = useSelector(selectAppStatus);
  const offlineToken = validateOfflineTokenService();
  const { getClientUserMapping } = useClientUserService();
  const [isAccessAllowed, setIsAccessAllowed] = useState<null | boolean>(null);
  const authToken = sessionStorage.getItem("offline-auth");
  const { checkWithMinimalAPI, loading } = useCheckWithMinimalAPI();
  const [loadingAccess, setLoadingAccess] = useState<boolean>(false);
  const loadTestUserList = JSON.parse(process.env.REACT_APP_LOADTESTUSER ?? "");
  const lowercaseLoadTestUserList = loadTestUserList.map((userEmail: string) => userEmail.toLowerCase());
  const userTokenInfoString = localStorage.getItem("UserTokenInfo") ? JSON.parse(localStorage.getItem("UserTokenInfo") ?? "{}") : null;

  const handleUnauthorizedAccess = async () => {
    if (isAppOnline) {
      try {
        setLoadingAccess(true);
        const appuserData: IIdentityServerResponseApplicationUser | null = await getClientUserMapping();
        setIsAccessAllowed(!(appuserData?.isDeleted || appuserData === null));
      } catch (error) {
        console.error("Failed to get client user mapping:", error);
        setIsAccessAllowed(false);
      } finally {
        setLoadingAccess(false);
      }
    }
  };

  useEffect(() => {
    handleUnauthorizedAccess();
  }, []);

  useEffect(() => {
    checkWithMinimalAPI();
  }, []);

  const render = () => {
    if (loadingAccess && isAppOnline) {
      return <Loader />;
    }

    if (isAppOnline && isAccessAllowed === null && authToken === null && !loading) {
      return <Access403 />;
    }

    if (isAppOnline && !isAccessAllowed && authToken === null && !loading) {
      return <Access403 />;
    }

    if (!isAppOnline && authToken === null) {
      return <Navigate to="/otp-login" replace />;
    }

    if (!loadingAccess && lowercaseLoadTestUserList.includes(userTokenInfoString?.profile?.email?.toLowerCase())) {
      return props.children;
    }

    return offlineToken === true ? props.children : <Navigate to="/otp-login" replace />;
  };

  return <>{render()}</>;
};

export default AuthGuard;
