/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "primereact/button";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectAppStatus } from "../../Redux/features/appStatusSlice";
import { selectOfflinePin } from "../../Redux/features/offlinePinSlice";
import { SetMessage } from "../../Redux/features/toasterInfoSlice";
import { appVersion, currentYear } from "../../data/constants/HelperClass";
import { AppDatabase } from "../../database/AppDB";
import { GenerateJWTTokenForOffline } from "../../services/jwtTokenService";
import useTotpService from "../../services/totpService";
import PinInput from "../pin-input/PinInput";
import CustomDialog from "../pop-ups/custom-dialog/CustomDialog";
import QRCodeGenerator from "../qrcode-generator/QRCodeGenerator";

const OfflineLogin = () => {
  const [pin, setPin] = useState<string>("");
  const dispatch = useDispatch();
  const [tokenError, setTokenError] = useState<boolean>(false);
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const offlinePin = useSelector(selectOfflinePin);
  const onPinChanges = (pin: string) => {
    setPin(pin);
  };
  const isOnline = useSelector(selectAppStatus);
  const { GetTotpCode } = useTotpService();
  const userTokenInfoString = localStorage.getItem("UserTokenInfo") !== null ? JSON.parse(localStorage.getItem("UserTokenInfo") ?? "{}") : null;
  const db = new AppDatabase();
  const [is2FaEnabled, setIs2FaEnabled] = useState<boolean>(false);
  const [is2FADialogVisible, setIs2FADialogVisible] = useState(false);
  const doc: { userId: string; TwoFA: boolean }[] = [];

  const handle2FA = useCallback(async () => {
    if (await get()) {
      setIs2FaEnabled(true);
    }
  }, []);

  useEffect(() => {
    sessionStorage.removeItem("offline-auth");
    handle2FA();
    dispatch(SetMessage({ summary: "", severity: "", detail: "" }));
  }, []);

  const get = async () => {
    const dbCollection = await db.userCollection.get(1);
    const userID = userTokenInfoString?.profile?.sub;
    const user2FAEnabled = dbCollection?.data?.filter((a: any) => a.userId === userID && a.TwoFA === true)?.[0];
    return user2FAEnabled !== undefined;
  };

  const handleStoreUser2FA = async () => {
    await db.transaction("rw", db.userCollection, async () => {
      const response = await get();
      if (!response) {
        doc.push({ userId: userTokenInfoString?.profile?.sub, TwoFA: true });
        await db.userCollection.add({ id: 1, data: doc });
      }
      return null;
    });
  };

  const HandleTotpLogin = async () => {
    if (Date.now() / 1000 > userTokenInfoString?.expires_at) {
      return window.location.replace("/#/logout");
    }

    let totpCode = await GetTotpCode();
    if (totpCode) {
      if (String(offlinePin.pin) === totpCode) {
        setTokenError(false);
        const isofflineToken = GenerateJWTTokenForOffline();
        if (isofflineToken) {
          await handleStoreUser2FA();
          setIs2FaEnabled(true);
          navigate("/pages/appointment-details");
        } else {
          setTokenError(true);
        }
      } else {
        setError(true);
        setPin("");
      }
    } else {
      setError(true);
      setPin("");
    }
  };

  const twofaHeader = () => {
    return (
      <div className="flex w-fill-available items-center justify-start">
        <div className="!w-8 !h-8 inline-flex items-center justify-center rounded-full flex-shrink-0 header-icon">
          <i className="cl_tfa text-2xl text-primary"></i>
        </div>
        <div className="pl-3 flex items-center justify-items-center">
          <div className="expanded-popup-header-value">2 Factor Authentication</div>
        </div>
      </div>
    );
  };

  const twoFADiagContent = () => {
    return (
      <div className="flex flex-col !gap-10">
        <div className="text-primary text-xl">
          Please use any of the recommended Authenticator apps (Microsoft/Google/Okta) to scan the QR code and generate your PIN.
        </div>
        <div className="flex justify-center">{<QRCodeGenerator />}</div>
      </div>
    );
  };

  return (
    <div className="offline-login">
      <div className="flex place-content-center items-center h-screen justify-between p-10 lg:p-24 xl:p-60 ml-auto">
        <div className="self-center flex flex-col lg:flex-row justify-between w-full !gap-5 items-center">
          <div className="flex items-start">
            <div className="flex flex-col text-white">
              <div>Welcome to</div>
              <h1 className="my-0 text-white font-medium">ClairEMR®</h1>
              <div className="text-sm version-text">Version {appVersion}</div>
            </div>
          </div>
          <div className={"bg-white flex flex-col rounded-xl px-12 py-14  " + (error ? "!gap-3" : "!gap-10")}>
            <div className="!px-6 flex flex-col !gap-8 justify-stretch">
              <div>
                <img className="self-end" src="/svg/logo_full.svg" alt="powered by aaneel" />
              </div>
              <div className="flex flex-col gap-2">
                <h1 className="login-text font-medium">Log in</h1>
                <div className="flex gap-1">
                  <div className="gray-text">to continue</div>
                  <div className="aaneel-text font-medium">ClairEMR</div>
                </div>
              </div>
              <div className="flex flex-col !gap-3">
                <h4 className="font-normal input-label">Please enter your PIN</h4>
                <PinInput pin={pin} onPinChange={onPinChanges} pinLength={6} onSubmit={HandleTotpLogin} />
              </div>
              <Button
                rounded
                disabled={pin?.length < 6}
                label="Login"
                aria-label="Login"
                title="Login"
                onClick={HandleTotpLogin}
                className="button-font-normal"
              />
              {isOnline && is2FaEnabled === false && (
                <div className="flex justify-center min-w-fit">
                  <a className="auth-btn flex justify-center font-medium" onClick={() => setIs2FADialogVisible(true)}>
                    2F Authentication
                  </a>
                </div>
              )}
            </div>
            {error && (
              <ul className="list-disc list-inside leading-normal !ml-11">
                <li className="text-danger text-xs">Incorrect PIN. Please try again.</li>
              </ul>
            )}
            <div className="flex flex-row !gap-3 justify-center">
              <div className="self-end leading-none gray-text text-xs">© {currentYear} AaNeel Infotech LLC</div>
              <div className="relative">
                <div className="gray-text absolute right-0 -top-[0.5rem] text-[0.625rem]">Powered by</div>
                <img className="self-end" src="/svg/aaneel-poweredby.svg" alt="powered by aaneel" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        visible={tokenError}
        header="Message"
        content="Please go back to online and login again."
        onHide={() => setTokenError(false)}
        maximizable={false}
        className={"w-[560px]"}
        maximized={false}
        resizable={false}
        draggable={false}
      />
      {is2FADialogVisible && (
        <CustomDialog
          visible={is2FADialogVisible}
          header={twofaHeader()}
          content={twoFADiagContent()}
          onHide={() => setIs2FADialogVisible(false)}
          maximizable={false}
          className={"w-[560px]"}
          maximized={false}
          resizable={false}
          draggable={false}
        />
      )}
    </div>
  );
};

export default OfflineLogin;
