import { useEffect, useState } from "react";
import { useParams } from "react-router";
import NoDataAvailable from "../../components/no_record_found/NoDataAvailable";
import { utcConversionDateTime } from "../../data/constants/DateTimeConversion";
import { AppointmentService } from "../../services/AppointmentService";
import sanitizeText from "../../layout/hooks/senitizeData";

const ScrollLayout = ({ config }: any) => {
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        
        let result = await getByWidget(parseInt(id), config?.i);
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  const result = data?.sort((itema: any, itemb: any) => {
    return Date.parse(itemb.date) - Date.parse(itema.date);
  }) ?? [];

  return result?.length > 0 ? (
    <div className="flex flex-col pt-2 overflow-auto !h-full">
      {result.slice(0, 5).map((item: any, index: number) => {
        return (
          <div
            className="flex flex-col !gap-2 flex-shrink-0 pr-4 items-start justify-center note-bottom-b"
            key={item + index}
          >
            <div
              className="flex flex-row !gap-3 items-center justify-center"
              key={index}
            >
              <div className="label-text">
                {/* {moment(item?.date).format("MM/DD/YYYY hh:mm A")} */}
                {/* {`${moment(item.date, DateTimeConstant.API_DTF)
                  .format(DateTimeConstant.DTF)}`} */}
                {utcConversionDateTime(item.date)}
              </div>
              <i className="cl_dot_big text-xxs text-primary-500"></i>
              <div className="label-text">{item?.submittedBy}</div>
            </div>
            <div className="value-text">{sanitizeText(item?.noteDesc)}</div>
          </div>
        );
      })}
    </div>
  ) : (
    <NoDataAvailable />
  );
};

export default ScrollLayout;
