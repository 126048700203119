import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectFilter } from "../../Redux/features/filterDataTableSlice";
import { DateTimeConstant } from "../../data/constants/DateTimeConstants";
import { placeHolder } from "../../data/constants/HelperClass";
import { AppointmentService } from "../../services/AppointmentService";
import { GenericTable } from "../generic-components/GenericTable";

export const Medication = ({ config, expanded }: any) => {
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  const filterSelector = useSelector(selectFilter);

  const filterData = (filterdata: any) => {
    const filterValue = expanded
      ? config.filterValue
      : filterSelector.filters[config.i];
    if (filterValue && filterValue !== "All") {
      filterdata = filterdata?.filter(
        (a: any) => a[config.filterColumn] === filterValue
      );
    }

    return filterdata;
  };

  const formatedData = data?.map((x: any) => {
    let each = { ...x };
    config?.cols
      ?.filter((a: any) => a.valueClassName)
      .forEach((element: any) => {
        each["className"] = each["className"] ?? {};
        let actCol = element.col ?? element.valueCol;
        if (each?.[actCol]) {
          each["className"][
            actCol
          ] = `${element.valueClassName?.toLowerCase()}-${each?.[actCol]
            ?.toLowerCase()
            .replace(" ", "-")}`;
        }
      });

    if (each.isControlled) {
      each["className"]["name"] = `text-danger ${
        each["className"]["name"] ?? ""
      }`;
    }

    // Formatting date to MM/DD/YYYY

    config?.cols
      ?.filter((a: any) => a.dataType === "Date")
      .forEach((element: any) => {
        let colDate = each?.[element.col];
        const date = moment(colDate);

        let formattedDate = date.isValid()
          ? date.format(DateTimeConstant.DF)
          : placeHolder("N/A");

        each[element.col] = formattedDate;
      });
    return each;
  });

  const resultData = filterData(formatedData ?? []);

  return (
    <GenericTable payload={resultData} config={config} expanded={expanded} />
  );
};
