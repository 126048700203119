import { useEffect } from "react";
import { useAuth } from "../auth/authProvider";
import useIdle from "./IdleTimerContainer";

const IdelPopup = () => {
  const timerInSecondsForAutoLogout = Number(process.env.REACT_APP_IDLE_TIMER_IN_SECONDS) / 2;
  const authContext = useAuth();
  const loadTestUserList = JSON.parse(process.env.REACT_APP_LOADTESTUSER ?? "");
  const lowercaseLoadTestUserList = loadTestUserList.map((userEmail: string) => userEmail.toLowerCase());
  const userTokenInfoString = localStorage.getItem("UserTokenInfo") ? JSON.parse(localStorage.getItem("UserTokenInfo") ?? "{}") : null;

  const { isIdle, setIsIdle } = useIdle({
    idleTime: timerInSecondsForAutoLogout,
  });

  const handleLogoutConfirmation = () => {
    try {
      if (authContext.isAuthenticated() && !lowercaseLoadTestUserList.includes(userTokenInfoString?.profile?.email?.toLowerCase())) {
        authContext.clearAuthSession();
        window.location.replace("/#/otp-login");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  useEffect(() => {
    if (isIdle) {
      setIsIdle(false);
      handleLogoutConfirmation();
    }
  }, [isIdle]);

  return <></>;
};

export default IdelPopup;
