// import Swiper bundle with all modules installed
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";

import MemberContacts from "./MemberContacts";
import MemberFamilyHistory from "./MemberFamilyHistory";
import MemberHospitalAdmission from "./MemberHospitalAdmission";
import MemberInsuranceDetails from "./MemberInsuranceDetails";
import MemberOfficeDetails from "./MemberOfficeDetails";
import MemberPharmacyDetails from "./MemberPharmacyDetails";
import MemberTeamInfo from "./MemberTeamInfo";

const MemberOtherDetails = ({ initialState, widgetsConfig }: any) => {
  return (
    <div className="flex px-4 custom-arrow-swiper rounded-b-xl">
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={20}
        slidesPerView={5}
        navigation={true}
        pagination={false}
        scrollbar={false}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
          1366: {
            slidesPerView: 5,
          },
        }}
      >
        {/* {{ draggable: true }} */}
        <SwiperSlide>
          <MemberOfficeDetails data={initialState?.memberOfficeDetails} widgetsConfig={widgetsConfig?.memberOfficeDetails} />
        </SwiperSlide>
        <SwiperSlide>
          <MemberTeamInfo data={initialState?.memberTeamDetail} widgetsConfig={widgetsConfig?.memberTeamDetail} />
        </SwiperSlide>
        <SwiperSlide>
          <MemberHospitalAdmission data={initialState?.hospitalAdmission} widgetsConfig={widgetsConfig?.hospitalAdmission} />
        </SwiperSlide>
        <SwiperSlide>
          <MemberInsuranceDetails data={initialState?.insuranceDetails} widgetsConfig={widgetsConfig?.insuranceDetails} />
        </SwiperSlide>
        <SwiperSlide>
          <MemberPharmacyDetails data={initialState?.pharmacyDetails} widgetsConfig={widgetsConfig?.pharmacyDetails} />
        </SwiperSlide>
        <SwiperSlide>
          <MemberContacts data={initialState?.contacts} widgetsConfig={widgetsConfig?.contacts} />
        </SwiperSlide>
        <SwiperSlide>
          <MemberFamilyHistory data={initialState?.familyHistory} widgetsConfig={widgetsConfig?.familyHistory} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
export default MemberOtherDetails;
