import React from "react";
import { placeHolder } from "../../../data/constants/HelperClass";
import { Contacts } from "../../../model/clair-emr-response-dto";

interface MemberContacts {
  data?: Contacts[];
  widgetsConfig?: any;
}

const MemberContacts = (memberContact: MemberContacts) => {
  const formatPhoneNumber = (phoneNumber: string) => {
    // Check if the phone number is not empty
    if (phoneNumber) {
      // Format the phone number to (XXX) XXX-XXXX
      const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      return formattedPhoneNumber;
    } else {
      return "N/A"; // Return empty string if the phone number is empty
    }
  };

  return (
    <div className="flex flex-col gap-2 set-scroll-h touch-pan-y pb-2">
      <div className="title-text">Contacts</div>
      {memberContact.data?.length != undefined && memberContact.data?.length > 0 ? (
        memberContact.data?.map((each: any, i: number) => {
          return (
            <React.Fragment key={i}>
              <div className="flex flex-col !gap-3">
                {memberContact.widgetsConfig.map((item: any, index: number) => {
                  return (
                    <div className="flex flex-col" key={index}>
                      <div className="label-text">{item?.title}</div>
                      <div className="value-text">
                        {item.field === "homePhone" ? formatPhoneNumber(placeHolder(each?.[item?.field])) : placeHolder(each?.[item?.field])}
                      </div>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          );
        })
      ) : (
        <div className="value-text">N/A</div>
      )}
    </div>
  );
};
export default MemberContacts;
