interface CustomNoDataAvailableProps {
  label?: string;
  width?: string;
  height?: string;
}

const NoDataAvailable: React.FC<CustomNoDataAvailableProps> = ({
  label = "Data",
  width = "88",
  height = "88",
}) => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="text-center">
        <svg
          width={width}
          height={height}
          viewBox="0 0 88 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M39.1078 60.6891C39.1078 60.6891 30.2844 44.2527 34.8341 35.2761C39.0874 26.8843 57.1182 30.4682 55.8209 23.4472C54.5235 16.4261 41.7788 16.4261 41.7788 16.4261"
            stroke="var(--primary-200)"
            strokeWidth="0.915786"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="1.83 1.83"
          />
          <path
            d="M33.2892 43.4922L27.8861 71.2405L27.184 74.8731L63.2049 74.6991C64.8228 74.6915 66.2575 73.2552 66.7749 71.1214L73.1641 44.9254C73.5594 43.306 72.6924 41.6209 71.4653 41.6224L34.9987 41.6606C34.205 41.6591 33.4937 42.41 33.2892 43.4922Z"
            fill="var(--primary-500)"
          />
          <g opacity="0.7">
            <path
              d="M33.2892 43.4922L27.8861 71.2405L27.184 74.8731L63.2049 74.6991C64.8228 74.6915 66.2575 73.2552 66.7749 71.1214L73.1641 44.9254C73.5594 43.306 72.6924 41.6209 71.4653 41.6224L34.9987 41.6606C34.205 41.6591 33.4937 42.41 33.2892 43.4922Z"
              fill="var(--primary-900)"
            />
          </g>
          <path
            d="M59.7539 69.3572L55.9381 38.6157C55.7855 37.3565 54.7796 36.4163 53.5937 36.4209L45.2295 36.4469C44.9187 36.4504 44.6118 36.5171 44.3276 36.6431C44.0434 36.769 43.7879 36.9516 43.5765 37.1795L39.6081 41.4028L16.3471 41.5279C14.9261 41.5356 13.8333 42.8818 14.021 44.3928L17.5636 72.8051C17.7162 74.0628 18.7221 75.0015 19.905 74.9999L60.4514 74.919C64.9662 75.0121 65.3982 73.6797 65.3982 73.6797C60.2148 74.8259 59.7539 69.3648 59.7539 69.3572Z"
            fill="var(--primary-500)"
          />
          <path
            d="M45.5947 13.1644C42.771 9.25411 42.0842 16.446 42.0842 16.446H44.9078C44.9078 16.446 47.1246 15.283 45.5947 13.1644Z"
            fill="var(--primary-500)"
          />
          <path
            d="M45.5947 19.7275C42.771 23.6377 42.0842 16.4459 42.0842 16.4459L44.9078 16.4459C44.9078 16.4459 47.1246 17.6088 45.5947 19.7275Z"
            fill="var(--primary-500)"
          />
        </svg>
      </div>
      <div className="font-normal text-sm">No {label} Available</div>
    </div>
  );
};

export default NoDataAvailable;
