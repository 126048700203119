import moment from "moment";
import { DateTimeConstant } from "../data/constants/DateTimeConstants";
import { AppDatabase } from "../database/AppDB";

export const AppointmentService = () => {
  const db = new AppDatabase();
  const tokenInfo = JSON.parse(localStorage.getItem("UserTokenInfo") ?? "{}");
  const userID = tokenInfo?.profile?.sub;
  const get = async (id: number) => {
    let dbCollection = await db.appCollection.get(1);
    let appointment = dbCollection?.data?.userInfo
      ?.filter((a: any) => a.userID === userID)?.[0]
      ?.userAppointments?.filter((a: any) => a.id === id);
    let appointmentDetails = appointment?.[0]?.patientInformation;
    return appointmentDetails !== undefined ? appointmentDetails : {};
  };

  const getByWidget = async (id: number, i: string) => {
    let appointmentDetails = await get(id);
    return appointmentDetails?.[i];
  };

  const getAll = async (appointmentDate: string | null = null) => {
    let dbCollection = await db.appCollection.get(1);
    const tokenInfo = JSON.parse(localStorage.getItem("UserTokenInfo") ?? "{}");
    const userID = tokenInfo?.profile?.sub;
    const start = moment(appointmentDate, DateTimeConstant.DF)
      .startOf("day")
      .utc()
      .format(DateTimeConstant.ISO_DATE_TIME);
    const end = moment(appointmentDate, DateTimeConstant.DF)
      .endOf("day")
      .utc()
      .format(DateTimeConstant.ISO_DATE_TIME);

    let userAppointments = dbCollection?.data?.userInfo?.filter(
      (a: any) => a.userID === userID
    )?.[0]?.userAppointments;
    if (appointmentDate) {
      userAppointments = userAppointments?.filter(
        (a: any) =>
          moment(a.start) >= moment(start) && moment(a.start) <= moment(end)
      );
    }
    if (userAppointments?.length > 0) {
      return userAppointments;
    } else {
      return [];
    }
  };

  const checkAppointmentsAvailable = async (appointmentDate: string) => {
    let userAppointments = await getAll(appointmentDate);

    return userAppointments?.length > 0;
  };
  return { getAll, get, getByWidget, checkAppointmentsAvailable };
};
