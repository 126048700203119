import { useLayoutEffect } from "react";

export const useTextOverflowTooltip = () => {
  const checkTextOverflow = (element: HTMLElement | null): boolean => {
    return element ? element.scrollWidth > element.clientWidth : false;
  };

  const checkOverflow = (el: any, value: any) => {
    if (el && value !== undefined) {
      const isTextOverflowed = checkTextOverflow(el);
      if (isTextOverflowed) {
        el.setAttribute("data-pr-tooltip", value);
      } else {
        el.removeAttribute("data-pr-tooltip");
      }
    }
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      const elements: any = document.getElementsByClassName("overflow-tooltip");
      for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        const value = element.textContent;
        checkOverflow(element, value);
      }
    };

    // Trigger initial check
    handleResize();

    // Re-run check on window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures effect runs only once after initial render

  return { checkOverflow };
};
