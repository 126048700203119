import moment from "moment";
import { DateTimeConstant } from "../../data/constants/DateTimeConstants";
import { placeHolder } from "../../data/constants/HelperClass";

const DateTimeSplitter = (dateString: string) => {
 
  const date = moment.utc(dateString).local();


  return (
    <div>
      {date.isValid() && (
        <>
          <div>{date.format(DateTimeConstant.DF)}</div>
          <div>{date.format(DateTimeConstant.HM)}</div>
        </>
      )}
      {placeHolder(date.isValid())}
    </div>
  );
};

export default DateTimeSplitter;
