import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AppointmentService } from "../../services/AppointmentService";
import { GenericTable } from "../generic-components/GenericTable";

export const Specialist = ({ config, expanded }: any) => {
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  const resultData = data?.map((x: any) => {
    let each = { ...x };

    config?.cols
      ?.filter((a: any) => a.valueClassName)
      .forEach((element: any) => {
        each["className"] = each["className"] ?? {};
        let actCol = element.col ?? element.valueCol;
        if (each?.[actCol]) {
          each["className"][
            actCol
          ] = `${element.valueClassName?.toLowerCase()}-${each?.[actCol]
            ?.toLowerCase()
            .replace(" ", "-")}`;
        }
      });

    config?.cols
      ?.filter((a: any) => a.col === "phoneNumber")
      .forEach((element: any) => {
        each[element.col] = each?.[element.col]?.replace(
          /(\d{3})(\d{3})(\d{4})/,
          "($1) $2-$3"
        );
      });

    config?.cols
      ?.filter((a: any) => a.col === "address")
      .forEach((element: any) => {
        each[element.col] = each?.[element.col]?.replace(/\s*,\s*/g, ", ");
      });

    return each;
  });

  return (
    <GenericTable payload={resultData} config={config} expanded={expanded} />
  );
};
