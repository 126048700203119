const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#e83e8c"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="glitch"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="15" y1="9" x2="9" y2="15"></line>
        <line x1="9" y1="9" x2="15" y2="15"></line>
      </svg>

      <h2 className="text-5xl mb-4 text-pink-600">404 - Uh-Oh!</h2>
      <p className="text-xl font-medium text-center w-1/2">Oops! It seems like you've entered the mysterious realm of non-existing pages.</p>
    </div>
  );
};

export default NotFound;
