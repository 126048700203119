import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { VisibleLayout } from "../../Redux/features/expandModeSlice";
import { ExpandItemBase } from "../../Redux/features/expandedItemSlice";
import { AppointmentService } from "../../services/AppointmentService";

export const ClinicianAlerts = ({ config, expanded }: any) => {
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();
  const op: any = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
      } else {
        setData([]);
      }
    };
    fetchData();
  }, [id]);

  const handleExpand = () => {
    dispatch(
      ExpandItemBase({
        activeItem: data,
        widgetConfig: config,
      })
    );
    dispatch(VisibleLayout(true));
  };

  return (
    <>
      {!expanded && (
        <div className="py-3">
          <div className=" !px-4 items-center gap-1 h-full flex justify-between">
            <div className="flex items-center overflow-hidden w-full">
              <div className="minicard-icon">
                {config?.icon && (
                  <i className={"pr-3 !text-2xl " + config?.icon}></i>
                )}
              </div>
              <div className="flex flex-col gap-1 overflow-hidden">
                <div className="text-base font-medium minicard-title text-ellipsis overflow-hidden whitespace-nowrap">
                  {config?.label}
                </div>
                <div className="text-sm flex flex-row justify-center items-center gap-2 minicard-data">
                  <div className="flex justify-center items-center gap-2 overflow-hidden">
                    <div
                      className={`text-base text-ellipsis overflow-hidden whitespace-nowrap ${config.i}-tooltip overflow-tooltip`}
                    >
                      {data?.alerts?.[0] ?? "None"}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex">
              {config.hasInfo && data?.alerts === null && (
                <>
                  <Tooltip target=".item-tooltip-clinician" position="right">
                    <div className="flex flex-wrap w-[12.62rem]">
                      {config.headerTooltip}
                    </div>
                  </Tooltip>
                  <div
                    className={
                      "item-tooltip-clinician !w-8 !h-8 inline-flex items-center justify-center rounded-full flex-shrink-0 header-icon"
                    }
                  >
                    <i className="cl_info_line !text-2xl text-primary"></i>
                  </div>
                </>
              )}
              {data?.alerts?.length === 1 && (
                <div
                  className={`text-base text-ellipsis overflow-hidden whitespace-nowrap ${config.i}-tooltip overflow-tooltip`}
                >
                  <Tooltip
                    target={`.${config.i}-tooltip`}
                    className={`${config.i}-tooltip-wrap`}
                    position="bottom"
                  />
                </div>
              )}
              {data?.alerts?.length >= 1 && data?.alerts?.length <= 3 && (
                <>
                  <Button
                    rounded
                    onClick={(e) => op.current.toggle(e)}
                    text
                    icon="cl_arrow_down_line"
                    className="text-2xl"
                    disabled={config.layoutMode}
                  ></Button>
                  <OverlayPanel className="w-56" ref={op}>
                    <div className="flex flex-col gap-2">
                      {data?.alerts?.map((eachAlert: any) => {
                        return (
                          <div key={`data-text${eachAlert}`}>{eachAlert}</div>
                        );
                      })}
                    </div>
                  </OverlayPanel>
                </>
              )}
              {data?.alerts?.length > 3 && (
                <>
                  <div
                    className={`text-base text-ellipsis overflow-hidden whitespace-nowrap ${config.i}-tooltip overflow-tooltip`}
                  >
                    <Tooltip
                      target={`.${config.i}-tooltip`}
                      className={`${config.i}-tooltip-wrap`}
                      position="bottom"
                    />
                  </div>
                  <Button
                    rounded
                    text
                    icon="cl_arrow_right"
                    className=" minicard-lefticon -rotate-45 text-2xl text-primary"
                    disabled={config?.layoutMode}
                    onClick={() => handleExpand()}
                  ></Button>
                  <OverlayPanel className="w-56" ref={op}>
                    <div className="flex flex-col gap-2">
                      {data?.alerts?.map((eachAlert: any) => {
                        return (
                          <div key={`data-text${eachAlert}`}>{eachAlert}</div>
                        );
                      })}
                    </div>
                  </OverlayPanel>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {expanded && (
        <ul className="flex flex-col !gap-2">
          {data?.alerts
            ?.filter((alert: any) => alert)
            .map((eachAlert: any, index: number) => (
              <li className="border-b text-sm !pb-2" key={`data-text${index}`}>
                {eachAlert}
              </li>
            ))}
        </ul>
      )}
    </>
  );
};
