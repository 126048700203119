import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { EditLayout, selectEdit } from "../../Redux/features/editModeSlice";

const AppLayoutSettingSaveCancel = () => {
  const dispatch = useDispatch();
  const layoutMode = useSelector(selectEdit);

  const handleEdit = (mode: boolean) => {
    dispatch(EditLayout(mode));
  }
  const handleDoneEdit = () => {
    var dataList: any = JSON.parse(
      localStorage.getItem("edit-layouts") ?? "[]"
    );
    if (dataList?.app?.length > 0) {
      localStorage.setItem("layouts", JSON.stringify(dataList));
    }
    handleEdit(false);
  };

  const handleCancelEdit = () => {
    handleEdit(false);
  };
  return (
    <>
      {layoutMode.isEdit && (
        <>
          <Button
            icon="cl_check_done"
            rounded
            aria-label="Done"
            title="Done"
            className="!fixed !bottom-6 !right-1 save-layout-edit"
            onClick={() => handleDoneEdit()}
          />
          <Button
            icon="cl_close"
            rounded
            aria-label="Cancel edit"
            title="Cancel Edit"
            className="!fixed !bottom-6 !right-14 save-layout-edit"
            onClick={() => handleCancelEdit()}
            severity="secondary"
          />
        </>
      )}
    </>
  );
};
export default AppLayoutSettingSaveCancel;
