import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectFilter } from "../../Redux/features/filterDataTableSlice";
import { DateTimeConstant } from "../../data/constants/DateTimeConstants";
import { AppointmentService } from "../../services/AppointmentService";
import { GenericTable } from "../generic-components/GenericTable";

export const QualityManagement = ({ config, expanded }: any) => {
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  const filterSelector = useSelector(selectFilter);

  const filterData = (filterData: any) => {
    const filterValue = expanded
      ? config.filterValue
      : filterSelector.filters[config.i];

    if (filterValue === "Done") {
      filterData = filterData.filter((a: any) =>
        moment(a.reviewDate, "MM/DD/YYYY").isValid()
      );
    } else if (filterValue === "Pending") {
      filterData = filterData.filter((a: any) => a.reviewDate === "Pending");
    }

    return filterData;
  };

  const formatedData = data?.map((x: any) => {
    let each = { ...x };
    config?.cols
      ?.filter((a: any) => a.valueClassName)
      .forEach((element: any) => {
        each["className"] = each["className"] ?? {};
        let actCol = element.col ?? element.valueCol;
        if (each?.[actCol]) {
          each["className"][
            actCol
          ] = `${element.valueClassName?.toLowerCase()}-${each?.[actCol]
            ?.toLowerCase()
            .replace(" ", "-")}`;
        }
      });

    // Formatting date to MM/DD/YYYY

    config?.cols
      ?.filter((a: any) => a.dataType === "Date")
      .forEach((element: any) => {
        let colDate = each?.[element.col];
        const date = moment(colDate);
        if (date.isValid()) {
          let formattedDate = date.format(DateTimeConstant.DF);
          each[element.col] = formattedDate;
        } else if (element.col === "reviewDate") {
          each[element.col] = "Pending";
        }
      });

    return each;
  });

  const resultData = filterData(formatedData ?? []);
  const limited =
    expanded !== true ? resultData?.slice(0, 5) ?? [] : resultData ?? [];

  return <GenericTable payload={limited} config={config} expanded={expanded} />;
};
