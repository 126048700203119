import { Outlet } from "react-router";
import AuthGuard from "../../auth-guard/AuthGuard";
import { useAuth } from "../../components/auth/authProvider";
import AppInstallationLogic from "../../components/pop-ups/custom-install-pop-up/AppInstallationLogic";
import { SyncComponent } from "../../components/sync-component/SyncComponent";
import ThemeLayout from "../layout";

const ProtectedLayout = () => {
  const auth = useAuth();
  return (
    <>
      {auth.isAuthenticated() && <SyncComponent />}
      <AuthGuard>
        <AppInstallationLogic />
        <ThemeLayout>
          <Outlet />
        </ThemeLayout>
      </AuthGuard>
    </>
  );
};

export default ProtectedLayout;
