import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import NoDataAvailable from "../../components/no_record_found/NoDataAvailable";
import { DateTimeConstant } from "../../data/constants/DateTimeConstants";
import { AppointmentService } from "../../services/AppointmentService";
import { GenericAccordion } from "../generic-components/GenericAccordion";

export const HPI = ({ config }: any) => {
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  const formatedData = data?.map((x: any) => {
    let each = { ...x };

    each.date = each.date
      ? moment(each.date, DateTimeConstant.API_DTF).format(
          DateTimeConstant.LMDF
        )
      : each.date;

    return each;
  });

  const result = formatedData?.map((item: any, index: any) => {
    let each: any = {};
    each.header = (
      <React.Fragment key={index + config.i + "p"}>
        <div className="text-sm font-semibold header-label title-font-500">
          {item?.date}
        </div>
        <div className="header-desc text-value-900">{item.description}</div>
      </React.Fragment>
    );

    each.body = <div>{item.description}</div>;
    return each;
  });

  return result?.length > 0 ? (
    <GenericAccordion payload={result} config={config} />
  ) : (
    <NoDataAvailable />
  );
};
