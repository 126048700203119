import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import NoDataAvailable from "../../components/no_record_found/NoDataAvailable";
import { DateTimeConstant } from "../../data/constants/DateTimeConstants";
import { placeHolder } from "../../data/constants/HelperClass";
import DateTimeSplitter from "../../layout/hooks/DateSplitter";
import { AppointmentService } from "../../services/AppointmentService";

export const AdvanceDirective = ({ config, expanded }: any) => {
  const [data, setData]: any = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();

  const [first, setFirst] = useState(0); // Current page
  const [rows, setRows] = useState(5); // Rows per page
  const onPage = (e: any) => {
    setFirst(e.first);
    setRows(e.rows);
  };

  const maskPhoneNumbers = (data: any) => {
    return data.map((item: any) => {
      if (item.phone) {
        const maskedPhone = item.phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        return { ...item, phone: maskedPhone };
      }
      return item;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
      } else {
        setData([]);
      }
    };

    fetchData();
  }, [id]);

  const resultData = data?.map((x: any) => {
    let each = { ...x };
    config?.cols
      ?.filter((a: any) => a.valueClassName)
      .forEach((element: any) => {
        each["className"] = each["className"] ?? {};
        let actCol = element.col ?? element.valueCol;
        if (each?.[actCol]) {
          each["className"][actCol] = `${element.valueClassName?.toLowerCase()}-${each?.[actCol]?.toLowerCase().replace(" ", "-")}`;
        }
      });

    // Formatting date to MM/DD/YYYY

    config?.cols
      ?.filter((a: any) => a.dataType === "Date")
      .forEach((element: any) => {
        let colDate = each?.[element.col];
        const date = moment(colDate);

        let formattedDate = date.isValid() ? date.format(DateTimeConstant.DF) : placeHolder("N/A");

        each[element.col] = formattedDate;
      });

    config?.cols
      ?.filter((a: any) => a.col === "address")
      .forEach((element: any) => {
        each[element.col] = each?.[element.col]?.replace(/\s*,\s*/g, ", ");
      });

    return each;
  });

  const maskedData = maskPhoneNumbers(resultData);
  var dataset: (typeof resultData)[] = [];
  dataset = maskedData.filter((x: any) => x.id !== 0);

  dataset.sort((a: any, b: any) => {
    if (a.header === "5 Wishes" && b.header !== "5 Wishes") {
      return -1;
    } else if (a.header !== "5 Wishes" && b.header === "5 Wishes") {
      return 1;
    } else {
      return a.header.localeCompare(b.header);
    }
  });

  const groupedData: Record<string, (typeof resultData)[]> = {};

  const iconMapping = {
    "5 Wishes": "cl_docs_fill",
    "Living Will": "cl_summary",
    "POA (Power Of Attorney)": "cl_care_team",
    "POLST/DNR": "cl_care_hand",
    "WellBe ACP Plan": "cl_special_user",
  };

  // Group the dataset by the "header" property
  dataset = dataset.slice(first, first + rows);

  dataset.forEach((item) => {
    if (!groupedData[item.header]) {
      groupedData[item.header] = [];
    }
    groupedData[item.header].push(item);
  });

  const headerTemplate = (dataData: any) => {
    return (
      <div className="flex align-items-center gap-2">
        <span className="test-type">{dataData.testType}</span>
      </div>
    );
  };

  const columnBodyTemplate = (item: any, colConfig: any) => {
    let actCol = colConfig.valueCol ?? colConfig.col;
    let value = item[actCol];
    return colConfig.dataType === "Date" ? (
      colConfig.dataFormat === "DateTime" ? (
        <span className={item?.className?.[actCol] ?? ""}>
          {value ? (moment.utc(value).local().isValid() ? DateTimeSplitter(value) : value) : "N/A"}
        </span>
      ) : (
        <span className={item?.className?.[actCol] ?? ""}>
          {value ? (moment.utc(value).local().isValid() ? moment.utc(value).local().format(DateTimeConstant.DF) : value) : "N/A"}
        </span>
      )
    ) : (
      <span className={item?.className?.[actCol] ?? ""}>{placeHolder(value)}</span>
    );
  };
  return (
    <div className="flex flex-col !gap-4 adv-pagination-fix justify-between">
      <div>
        {Object.keys(groupedData)?.map((header, i) => (
          <div key={"eachkey-" + i} className="datatable-wrapper flex flex-col !gap-3">
            <div className="flex flex-row items-center advanceDirectiveHeader !gap-3">
              <i className={iconMapping[header as keyof typeof iconMapping]}></i>
              <div>{header}</div>
            </div>
            <DataTable
              value={groupedData?.[header]}
              sortField={config.sortField}
              sortOrder={config.sortOrder ?? 1}
              scrollable
              scrollHeight={"flex"}
              rowGroupHeaderTemplate={headerTemplate}
              removableSort
              sortMode={config.sortMode ?? "single"}
              showHeaders={config.showHeaders}
              onPage={(e) => setFirst(e.first)}
              emptyMessage={<NoDataAvailable />}
            >
              {config.cols
                .filter((a: any) => !a.hideInWidget || expanded)
                .map((x: any, i: any) => (
                  <Column
                    field={x.col}
                    sortable={x.isSortable}
                    style={{ width: `${x.width}rem` }}
                    header={x.display}
                    dataType={x.dataType ?? "string"}
                    className={x.className ?? ""}
                    body={(e) => columnBodyTemplate(e, x)}
                    key={"eachkey-" + x + i}
                  ></Column>
                ))}
            </DataTable>
          </div>
        ))}
      </div>
      {resultData?.length > 0 ? (
        <Paginator className="adv-paginator" first={first} rows={rows} totalRecords={resultData?.length} onPageChange={onPage} />
      ) : (
        <NoDataAvailable />
      )}
    </div>

    // <GenericTable payload={resultData} config={config} expanded={expanded} />
  );
};
