import ReactDOM from "react-dom/client";
//layout style
import "./stylesheets/Styles.scss";
import "./stylesheets/color-palletes.scss";
import "./stylesheets/layout/fonts/icon-fonts/style.css";
import "./stylesheets/layout/layout.scss";
//icon
import "primeflex/primeflex.css";
//icon
import "primeicons/primeicons.css";
//core
import "primereact/resources/primereact.min.css";

import { PrimeReactProvider } from "primereact/api";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import store from "./Redux/app/store";
import { LayoutProvider } from "./layout/context/layoutcontext";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

//router

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <PrimeReactProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <LayoutProvider>
          <App />
        </LayoutProvider>
      </PersistGate>
    </Provider>
  </PrimeReactProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
