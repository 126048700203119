import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

interface CustomInstallConfirmPopUpProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onInstall: () => void;
}

const CustomInstallConfirmPopUp = ({
  isOpen,
  onRequestClose,
  onInstall,
}: CustomInstallConfirmPopUpProps) => {
  const dialogFooter = (
    <div className="flex items-center justify-center">
      <Button
        label="Install"
        // icon="cl_laptop_install"
        rounded
        onClick={onInstall}
        autoFocus
      />
      <Button
        label="Cancel"
        //  icon="cl_close"
        onClick={onRequestClose}
        outlined
        rounded
      />
    </div>
  );
  const header = () => {
    return (
      <div className="flex justify-center items-center ">
        <i className="cl_laptop_install text-4xl font-medium text-primary"></i>
      </div>
    );
  };
  return (
    <Dialog
      header={header}
      headerClassName="!pb-4"
      contentClassName="!pb-4"
      visible={isOpen}
      onHide={onRequestClose}
      modal
      draggable={false}
      closable={false}
      position="top-right"
      footer={dialogFooter}
      className="w-[472px]"
      id="CustomInstallConfirmPopUp"
    >
      <div className="flex flex-col gap-2">
        <div className="text-base font-medium text-primary">
          Install and unlock the full potential of ClairEMR app
        </div>
        <ul className="list-disc list-inside px-5 text-sm leading-normal">
          <li>
            <span className="font-medium">Swift Load Times: </span>
            <span>Experience faster loading for seamless navigation</span>
          </li>
          <li>
            <span className="font-medium">Quick Access Options: </span>
            <span>
              Pin to your taskbar or add to your homescreen for instant entry.
            </span>
          </li>
          <li>
            <span className="font-medium">Additional Features: </span>
            <span>
              Enjoy exclusive functionalities to enhance your user experience.
            </span>
          </li>
        </ul>
      </div>
    </Dialog>
  );
};

export default CustomInstallConfirmPopUp;
