import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { RefreshData } from "../../model/refresh-data-model";

const initialState: RefreshData = {
  refreshString: ""
};

const refreshDataSlice = createSlice({
  name: "refreshData",
  initialState,
  reducers: {
    setRefreshString: (state, action) => {
      state.refreshString = action.payload;
    },
  },
});

export const { setRefreshString } = refreshDataSlice.actions;
export const selectRefreshString = (state: RootState) => state.refreshData.refreshString;

export default refreshDataSlice.reducer;
