import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { SyncLoaderModel } from "../../model/sync-loader-model";

const initialState: SyncLoaderModel = {
  loadingString: ""
};

const syncLoadingDoneSlice = createSlice({
  name: "syncLoadingDone",
  initialState,
  reducers: {
    setSyncLoading: (state, action) => {
      state.loadingString = action.payload;
    },
  },
});

export const { setSyncLoading } = syncLoadingDoneSlice.actions;
export const selectSyncLoading = (state: RootState) => state.syncLoadingDone.loadingString;

export default syncLoadingDoneSlice.reducer;
