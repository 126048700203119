import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { EditModeStateModel } from "../../model/edit-mode-model";

const initialState: EditModeStateModel = {
  isEdit: false,
};

const editModeSlice = createSlice({
  name: "editMode",
  initialState,
  reducers: {
    EditLayout: (state, action) => {
      state.isEdit = action.payload;
    },
  },
});

export const { EditLayout } = editModeSlice.actions;
export const selectEdit = (state: RootState) => state.editMode;

export default editModeSlice.reducer;
