import moment from "moment";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { DateTimeConstant } from "../../data/constants/DateTimeConstants";
import { AppointmentService } from "../../services/AppointmentService";

export const AWVDueDate = ({ config }: any) => {
  const { getByWidget } = AppointmentService();
  const [data, setData]: any = useState([]);
  const op: any = useRef(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        setData(result);
      } else {
        setData([]);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div className="py-3">
      <div className=" !px-4 items-center gap-1 h-full flex justify-between">
        <div className="flex items-center overflow-hidden w-full">
          <div className="minicard-icon">
            {config?.icon && (
              <i className={"pr-3 !text-2xl " + config?.icon}></i>
            )}
          </div>
          <div className="flex flex-col gap-1 overflow-hidden">
            <div className="text-base font-medium minicard-title text-ellipsis overflow-hidden whitespace-nowrap">
              {config?.label}
            </div>
            <div className="text-sm flex flex-row justify-center items-center gap-2 minicard-data">
              <div className="flex justify-center items-center gap-2 overflow-hidden">
                <div
                  className={`text-base text-ellipsis overflow-hidden whitespace-nowrap ${config.i}-tooltip overflow-tooltip`}
                >
                  {data?.dueDate
                    ? moment(data?.dueDate).format(DateTimeConstant.DF)
                    : "None"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          {data && (
            <>
              <Button
                rounded
                onClick={(e) => op.current.toggle(e)}
                text
                icon="cl_arrow_down_line"
                className="text-2xl"
                disabled={config.layoutMode}
              ></Button>
              <OverlayPanel ref={op}>
                <div className="flex flex-col gap-3">
                  {config?.dropdownTitle && (
                    <div className="text-base font-medium dropdown-title-text">
                      {config?.dropdownTitle}
                    </div>
                  )}
                  <div className="flex flex-col gap-3">
                    {config?.dropdownProps?.dropdownData?.map(
                      (item: any, index: any) => {
                        return (
                          <div className="dropdown-item-wrap" key={index}>
                            <div className="flex flex-row gap-3">
                              <div
                                className="flex items-center gap-3 "
                                key={index}
                              >
                                <i className={item.itemIcon}></i>
                                <div className="flex flex-col">
                                  <div className="title-text">
                                    {item.itemTitle}
                                  </div>
                                  <div className="value-text">
                                    {item.isDateField
                                      ? moment(data[item.field]).format(
                                          "MM/DD/YYYY hh:mm A"
                                        )
                                      : data[item.field]}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                    {config?.dropdownProps?.dropdownProps?.viewMoreButton && (
                      <Button
                        className="!h-auto !bg-gray-300 !text-black !font-medium !border-none !px-4 !py-1 focus:!shadow-none"
                        severity="secondary"
                      >
                        {
                          config?.dropdownProps?.dropdownProps
                            .viewMoreButtonText
                        }
                      </Button>
                    )}
                  </div>
                </div>
              </OverlayPanel>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
