const MemberFamilyHistory = ({ data, widgetsConfig }: any) => {
  return (
    <div className="flex flex-col gap-2 set-scroll-h touch-pan-y pb-2">
      <div className="title-text">Family History</div>
      {data?.length > 0 ? (
        data?.map((each: any, i: number) => {
          return (
            <div className="flex flex-col !gap-3" key={`i${i}`}>
              <div className="flex flex-col">
                <div className="label-text">
                  {each?.[widgetsConfig?.titleField]}
                </div>
                <div className="value-text">
                  {each?.[widgetsConfig?.field]
                    ? each?.[widgetsConfig?.field]
                    : "No medical conditions"}
                </div>
              </div>
            </div>
          );
        })
      ) : (<div className="value-text">N/A</div>)}
    </div>
  );
};
export default MemberFamilyHistory;
