import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { AppointmentService } from "../../services/AppointmentService";

export const MemberStatusFlag = ({ config }: any) => {
  const [splitData, setSplitData] = useState([]);
  const { getByWidget } = AppointmentService();
  const { id } = useParams();
  const op: any = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let result = await getByWidget(parseInt(id), config.i);
        var splitArray = result[0]?.memberStatusflag?.split(", ");
        setSplitData(splitArray);
      } else {
        setSplitData([]);
      }
    };
    fetchData();
  }, [id]);

  return (
    <>
      {
        <div className="py-3">
          <div className=" !px-4 items-center gap-1 h-full flex justify-between">
            <div className="flex items-center overflow-hidden w-full">
              <div className="minicard-icon">
                {config?.icon && (
                  <i className={"pr-3 !text-2xl " + config?.icon}></i>
                )}
              </div>
              <div className="flex flex-col gap-1 overflow-hidden">
                <div className="text-base font-medium minicard-title text-ellipsis overflow-hidden whitespace-nowrap">
                  {config?.label}
                </div>
                <div className="text-sm flex flex-row justify-center items-center gap-2 minicard-data">
                  <div className="flex justify-center items-center gap-2 overflow-hidden">
                    <div
                      className={`text-base text-ellipsis overflow-hidden whitespace-nowrap ${config.i}-tooltip overflow-tooltip`}
                    >
                      {splitData[0] !== "" ? splitData[0] : "None"}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex">
              {config.hasInfo && splitData[0] === "" && (
                <>
                  <Tooltip target=".item-tooltip-msf" position="right">
                    <div className="flex flex-wrap w-[12.62rem]">
                      {config.headerTooltip}
                    </div>
                  </Tooltip>
                  <div
                    className={
                      "item-tooltip-msf !w-8 !h-8 inline-flex items-center justify-center rounded-full flex-shrink-0 header-icon"
                    }
                  >
                    <i className="cl_info_line !text-2xl text-primary"></i>
                  </div>
                </>
              )}
              {splitData?.length === 1 && (
                <div
                  className={`text-base text-ellipsis overflow-hidden whitespace-nowrap ${config.i}-tooltip overflow-tooltip`}
                >
                  <Tooltip
                    target={`.${config.i}-tooltip`}
                    className={`${config.i}-tooltip-wrap`}
                    position="bottom"
                  />
                </div>
              )}
              {splitData?.length > 1 && (
                <>
                  <Button
                    rounded
                    onClick={(e) => op.current.toggle(e)}
                    text
                    icon="cl_arrow_down_line"
                    className="text-2xl"
                    disabled={config.layoutMode}
                  ></Button>
                  <OverlayPanel className="w-56" ref={op}>
                    <div className="flex flex-col gap-2">
                      {splitData?.map((memberStatusFlag: any) => {
                        return (
                          <div key={`data-text${memberStatusFlag}`}>
                            {memberStatusFlag}
                          </div>
                        );
                      })}
                    </div>
                  </OverlayPanel>
                </>
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};
