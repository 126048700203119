import { Tooltip } from "primereact/tooltip";
import { useContext, useEffect, useRef } from "react";
import AppMenuitem from "./AppMenuitem";
import { LayoutContext } from "./context/layoutcontext";
import { MenuProvider } from "./context/menucontext";
import type {
  MenuProps,
  MenuModel,
  Breadcrumb,
  BreadcrumbItem,
} from "../types/types";

const AppSubMenu = (props: MenuProps) => {
  const { layoutState, setBreadcrumbs } = useContext(LayoutContext);
  const tooltipRef = useRef<Tooltip | null>(null);

  const { layoutConfig } = useContext(LayoutContext);

  useEffect(() => {
    if (tooltipRef.current) {
      tooltipRef.current.hide();
      (tooltipRef.current as any).updateTargetEvents();
    }
  }, [layoutState?.overlaySubmenuActive]);

  useEffect(() => {
    generateBreadcrumbs(props.model);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateBreadcrumbs = (model: MenuModel[]) => {
    let breadcrumbs: Breadcrumb[] = [];

    const getBreadcrumb = (item: BreadcrumbItem, labels: string[] = []) => {
      const { label, to, items } = item;

      label && labels.push(label);
      items &&
        items.forEach((_item) => {
          getBreadcrumb(_item, labels.slice());
        });
      to && breadcrumbs.push({ labels, to });
    };

    model.forEach((item) => {
      getBreadcrumb(item);
    });
    setBreadcrumbs(breadcrumbs);
  };

  return (
    <MenuProvider>
      <ul
        className={`layout-menu h-fill-available w-fill-available flex justify-between ${
          layoutConfig.menuMode === "horizontal" ? "flex-row" : "flex-col"
        }`}
      >
        {props.model.map((item, i) => {
          return !item.seperator ? (
            <AppMenuitem item={item} root={true} index={i} key={item.label} />
          ) : (
            <li className="menu-separator"></li>
          );
        })}
      </ul>
      <Tooltip
        ref={tooltipRef}
        target="li:not(.active-menuitem)>.tooltip-target"
      />
    </MenuProvider>
  );
};

export default AppSubMenu;
