import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootState } from "../../Redux/app/store";
import { VisibleLayout } from "../../Redux/features/expandModeSlice";
import { ExpandItemBase } from "../../Redux/features/expandedItemSlice";
import { FilterVisitDate } from "../../Redux/features/filterDataForVisitTimeline";
import { FilterDataTable } from "../../Redux/features/filterDataTableSlice";
import { FilterDate } from "../../Redux/features/filterDateForVitalsSlice";
import { selectWidgetDropDowns } from "../../Redux/features/widgetDropDownSlice";
import InfoCard from "../../components/info-card/InfoCard";
import { AppointmentDetailConfig } from "../../data/AppointmentDetailConfig";
import { dropStatusList } from "../../data/constants/DefaultDropdownStatusList";
import { AWVDueDate } from "../layout-items/AWVDueDate";
import { Allergies } from "../layout-items/Allergies";
import { Appointments } from "../layout-items/Appointments";
import { ClinicianAlerts } from "../layout-items/ClinicianAlerts";
import { Communication } from "../layout-items/Communication";
import { HPI } from "../layout-items/HPI";
import { Medication } from "../layout-items/Medication";
import { MemberStatusFlag } from "../layout-items/MemberStatusFlag";
import { Problems } from "../layout-items/Problems";
import { QualityManagement } from "../layout-items/QualityManagement";
import { Screenings } from "../layout-items/Screenings";
import VisitTimeline from "../layout-items/VisitTimeline";
import Vitals from "../layout-items/Vitals";
import MiniCardLayout from "../mini-cards/MiniCards";
import ScrollLayout from "../scroll-layout/ScrollLayout";

interface ClairLayoutItems {
  element: any;
  scrWidth: any;
  index: number;
  layoutMode: boolean;
}

const ClairLayoutItem: React.ForwardRefRenderFunction<unknown, ClairLayoutItems> = (props: ClairLayoutItems) => {
  const model = props;

  const { id } = useParams<{ id: string }>();

  var filterValue = useSelector((state: RootState) => state.filterDataTableMode.filters[model.element.i]);

  const dynamicDropDownValues = useSelector(selectWidgetDropDowns);
  const defaultConfigurations = AppointmentDetailConfig.Configuration[model.element.i];
  const dispatch = useDispatch();
  const dropdownValues = dynamicDropDownValues[model.element.i] ?? defaultConfigurations?.dropDown;

  const [widgetConfig] = useState(defaultConfigurations);

  const data: any = [];

  useEffect(() => {
    return () => {
      Object.keys(dropStatusList).forEach((key) => {
        dispatch(FilterDataTable({ name: key, value: dropStatusList[key] }));
      });
    };
  }, [id]);

  const handleExpand = () => {
    dispatch(
      ExpandItemBase({
        activeItem: data,
        widgetConfig: widgetConfig,
      }),
    );
    dispatch(VisibleLayout(true));
  };

  const handleStatusChange = (e: any) => {
    dispatch(FilterDataTable({ name: model.element.i, value: e.value }));
    if (model.element.i === "vitals") {
      dispatch(FilterDate(e.value));
    }
    if (model.element.i === "visitsTimeline") {
      dispatch(FilterVisitDate(e.value));
    }
  };

  return (
    <>
      {model.element.hasTitle && (
        <div className={"relative clair-react-grid-header rounded-t-xl"}>
          <div className="px-4 pt-3 pb-2 flex justify-between items-center gap-2 rounded-t-xl">
            <div className="flex w-fill-available items-center justify-start overflow-hidden">
              <div
                className={
                  "!w-8 !h-8 inline-flex items-center justify-center rounded-full flex-shrink-0 header-icon" +
                  (widgetConfig.icon ? "" : " icon-placeholder")
                }
              >
                <i className={"text-2xl " + widgetConfig.icon}></i>
              </div>
              <div className="pl-3 flex items-center justify-items-center overflow-hidden">
                <h2 className="header-label text-xl title-font-900 font-medium overflow-hidden whitespace-nowrap text-ellipsis">
                  {model.element.label}
                </h2>
              </div>
            </div>

            {dropdownValues?.length > 0 && (
              <Dropdown
                value={filterValue}
                onChange={(e) => handleStatusChange(e)}
                options={dropdownValues}
                optionLabel="label"
                className="h-8 header-dropdown"
                disabled={model.layoutMode}
              />
            )}
            {model.element.hasInfoIcon && (
              <>
                <Tooltip target=".item-tooltip" position="right" disabled={model.layoutMode} />
                <div
                  className={"item-tooltip !w-8 !h-8 inline-flex items-center justify-center rounded-full flex-shrink-0 header-icon"}
                  data-pr-tooltip={widgetConfig.headerTooltip}
                >
                  <i className={`cl_info_line !text-2xl text-primary ${model.layoutMode ? "p-disabled" : ""}`}></i>
                </div>
              </>
            )}
            {model.element.expandable && (
              <Button
                icon="cl_expand_widget !text-[28px]"
                rounded
                text
                aria-label="Expand card"
                onClick={() => handleExpand()}
                disabled={model.layoutMode}
                className="p-3"
              ></Button>
            )}
          </div>
        </div>
      )}

      {widgetConfig.type === "minicard" && (
        <div>
          <MiniCardLayout config={widgetConfig} />
        </div>
      )}
      {widgetConfig.type === "infocard" && <InfoCard config={widgetConfig} layoutMode={model.layoutMode} />}
      {widgetConfig.i === "clinicianAlerts" && (
        <div>
          <ClinicianAlerts config={widgetConfig} />
        </div>
      )}
      {widgetConfig.i === "awvDueDate" && (
        <div>
          <AWVDueDate config={widgetConfig} />
        </div>
      )}
      {widgetConfig.i === "memberStatusFlag" && (
        <div>
          <MemberStatusFlag config={widgetConfig} />
        </div>
      )}
      {widgetConfig.i === "vitals" && (
        <div className="!px-6 pb-2 card-overflow">
          <Vitals config={widgetConfig} />
        </div>
      )}
      {widgetConfig.i === "problems" && (
        <div className="!px-6 pb-2 card-overflow">
          <Problems config={widgetConfig} expanded={false} />
        </div>
      )}
      {widgetConfig.i === "medication" && (
        <div className="!px-6 pb-2 card-overflow">
          <Medication config={widgetConfig} />
        </div>
      )}
      {widgetConfig.i === "screenings" && (
        <div className="!px-6 pb-2 card-overflow">
          <Screenings config={widgetConfig} />
        </div>
      )}
      {widgetConfig.i === "allergies" && (
        <div className="!px-6 pb-2 card-overflow">
          <Allergies config={widgetConfig} />
        </div>
      )}
      {widgetConfig.i === "qualityManagement" && (
        <div className="!px-6 pb-2 card-overflow">
          <QualityManagement config={widgetConfig} />
        </div>
      )}
      {widgetConfig.i === "communication" && (
        <div className="!px-6 pb-2 card-overflow">
          <Communication config={widgetConfig} />
        </div>
      )}
      {widgetConfig.i === "appointments" && (
        <div className="!px-6 pb-2 card-overflow">
          <Appointments config={widgetConfig} />
        </div>
      )}
      {widgetConfig.i === "hpi" && (
        <div className="!px-6 pb-2 card-overflow">
          <HPI payload={data} config={widgetConfig} />
        </div>
      )}
      {widgetConfig.i === "visitsTimeline" && (
        <div className="!px-6 pb-2 card-overflow">
          <VisitTimeline config={widgetConfig} />
        </div>
      )}
      {widgetConfig.type === "scroll" && (
        <div className="!px-6 pb-2 card-overflow">
          <ScrollLayout config={widgetConfig} />
        </div>
      )}
    </>
  );
};
export default ClairLayoutItem;
