import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { FilterDateStateModel } from "../../model/filter-model";

const initialState: FilterDateStateModel = {
  vitalDate: "",
};

const vitalSlice = createSlice({
  name: "vitalDate",
  initialState,
  reducers: {
    FilterDate: (state, action) => {
      state.vitalDate = action.payload;
    },
  },
});

export const { FilterDate } = vitalSlice.actions;
export const selectDate = (state: RootState) => state.vitalDate;

export default vitalSlice.reducer;