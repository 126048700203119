import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VisibleLayout, selectVisible } from "../../Redux/features/expandModeSlice";
import { selecActiveItem } from "../../Redux/features/expandedItemSlice";
import { AdvanceDirective } from "../layout-items/AdvanceDirective";
import { Allergies } from "../layout-items/Allergies";
import { ClinicianAlerts } from "../layout-items/ClinicianAlerts";
import { Communication } from "../layout-items/Communication";
import { LabResults } from "../layout-items/LabResults";
import { Medication } from "../layout-items/Medication";
import { Notes } from "../layout-items/Notes";
import { Problems } from "../layout-items/Problems";
import { QualityManagement } from "../layout-items/QualityManagement";
import { Screenings } from "../layout-items/Screenings";
import { Specialist } from "../layout-items/Specialist";

const ExpandedPopUp = () => {
  const expandMode = useSelector(selectVisible);
  const [filterValue, setFilterValue] = useState("All");
  const [sortOrder, setSortOrder] = useState<number | undefined>(undefined);
  const [sortField, setSortField] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();
  const active = useSelector(selecActiveItem);

  useEffect(() => {
    if (expandMode.isVisible) {
      document.body.classList.add("overflow-hidden");
      if (active?.item?.widgetConfig?.label === "Medication") {
        setFilterValue("Active");
      } else {
        setFilterValue("All");
        setSortOrder(undefined);
        setSortField(undefined);
      }
    } else {
      document.body.classList.remove("overflow-hidden");
      document.body.classList.remove("p-overflow-hidden");
    }
  }, [expandMode.isVisible, active]);

  const closeExpandedDialog = () => {
    document.body.classList.remove("overflow-hidden");
    document.body.classList.remove("p-overflow-hidden");
    dispatch(VisibleLayout(false));
    setFilterValue("All");
    setSortOrder(undefined);
    setSortField(undefined);
  };

  const widgetConfig = {
    ...active?.item?.widgetConfig,
    sortField,
    sortOrder,
    filterValue,
    showHeaders: true,
  };

  // Get the data for the current page
  // const paginatedData = data?.slice(first, first + rows);

  const header = () => {
    return (
      <div className="flex justify-between items-center">
        <div className="flex w-fill-available items-center justify-start">
          <div
            className={
              "!w-8 !h-8 inline-flex items-center justify-center rounded-full flex-shrink-0 header-icon" +
              (widgetConfig?.icon ? "" : " icon-placeholder")
            }
          >
            <i className={"text-2xl " + widgetConfig?.icon}></i>
          </div>
          <div className="pl-3 flex items-center justify-items-center">
            <div className="expanded-popup-header-value">{widgetConfig?.label}</div>
          </div>
        </div>
        {widgetConfig?.label === "Medication" && (
          <div className="flex flex-row whitespace-nowrap !mr-3 legend">
            <div className="flex flex-row !gap-1 items-center observationvalue-h">
              <i className={"legend-icon cl_dot_big"}></i>
              <div>Controlled Substances</div>
            </div>
          </div>
        )}
        {widgetConfig?.label === "Lab Results" && (
          <div className="flex flex-row !gap-3 legend">
            <div className="flex flex-row !gap-1 items-center observationvalue-l">
              <i className={"legend-icon " + classNames(widgetConfig?.legends)}></i>
              <div>Low</div>
            </div>
            <div className="flex flex-row !gap-1 items-center observationvalue-h">
              <i className={"legend-icon " + classNames(widgetConfig?.legends)}></i>
              <div>High</div>
            </div>
            <div className="flex flex-row !gap-1 items-center observationvalue-n">
              <i className={"legend-icon " + classNames(widgetConfig?.legends)}></i>
              <div>Normal</div>
            </div>
          </div>
        )}
        {widgetConfig.dropDown?.length > 0 && (
          <Dropdown
            value={filterValue}
            onChange={(e) => setFilterValue(() => e.value)}
            options={widgetConfig.dropDown}
            optionLabel="label"
            className="h-8 header-dropdown"
          />
        )}
      </div>
    );
  };

  return (
    <>
      {expandMode.isVisible && (
        <Dialog
          header={header}
          headerClassName="!py-4"
          visible={expandMode.isVisible}
          maximizable={widgetConfig.i !== "clinicianAlerts"}
          className={`md:min-w-[45rem] md:max-w-[80%] expanded-pop-up-wrap ${widgetConfig.i} `}
          onHide={() => closeExpandedDialog()}
          maximized={false}
          closeIcon={"cl_close_circle_line"}
          maximizeIcon={"cl_maximize_popup"}
          draggable={false}
        >
          {widgetConfig.i === "qualityManagement" && <QualityManagement config={widgetConfig} expanded={true} />}
          {widgetConfig.i === "problems" && <Problems config={widgetConfig} expanded={true} />}
          {widgetConfig.i === "allergies" && <Allergies config={widgetConfig} expanded={true} />}
          {widgetConfig.i === "medication" && <Medication config={widgetConfig} expanded={true} />}
          {widgetConfig.i === "screenings" && <Screenings config={widgetConfig} expanded={true} />}
          {widgetConfig.i === "communication" && <Communication config={widgetConfig} expanded={true} />}
          {widgetConfig.i === "notes" && <Notes config={widgetConfig} expanded={true} />}
          {widgetConfig.i === "advanceDirective" && <AdvanceDirective config={widgetConfig} expanded={true} />}
          {widgetConfig.i === "labResults" && <LabResults config={widgetConfig} expanded={true} />}
          {widgetConfig.i === "specialist" && <Specialist config={widgetConfig} expanded={true} />}
          {widgetConfig.i === "clinicianAlerts" && <ClinicianAlerts config={widgetConfig} expanded={true} />}
        </Dialog>
      )}
    </>
  );
};

export default ExpandedPopUp;
