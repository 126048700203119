import { Toast } from "primereact/toast";
import { useRef } from "react";
import { Outlet } from "react-router";
import PopUpLayout from "../../components/pop-ups/popup-layout/PopUpLayout";
import AppBreadCrumb from "../AppBreadCrumb";

const CommonLayout = () => {
  const toast = useRef<Toast>(null);

  return (
    <>
      {/* <AppTopbar></AppTopbar> */}
      <PopUpLayout />
      <AppBreadCrumb className="content-breadcrumb" breadcrumbs={[]} />
      <Toast ref={toast} />
      <Outlet />
    </>
  );
};

export default CommonLayout;
