"use client";
import PrimeReact from "primereact/api";
import {
  useEventListener,
  useMountEffect,
  useResizeListener,
  useUnmountEffect,
} from "primereact/hooks";
import { classNames, DomHandler } from "primereact/utils";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import AppConfig from "./AppConfig";
import AppProfileSidebar from "./AppProfileSidebar";
import AppSidebar from "./AppSidebar";
import { LayoutContext } from "./context/layoutcontext";
import type { AppTopbarRef, ChildContainerProps } from "../types/types";
import { SetLayoutState } from "../Redux/features/layoutStateSlice";
import { useDispatch } from "react-redux";

const ThemeLayout = (props: ChildContainerProps) => {
  const {
    layoutConfig,
    layoutState,
    isSlim,
    isSlimPlus,
    isHorizontal,
    isDesktop,
  } = useContext(LayoutContext);
  const dispatch = useDispatch();
  const topbarRef = useRef<AppTopbarRef>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  // const pathname = usePathname();
  // const searchParams = useSearchParams();
  const [bindMenuOutsideClickListener, unbindMenuOutsideClickListener] =
    useEventListener({
      type: "click",
      listener: (event) => {
        const isOutsideClicked = !(
          sidebarRef.current?.isSameNode(event.target as Node) ||
          sidebarRef.current?.contains(event.target as Node) ||
          topbarRef.current?.menubutton?.isSameNode(event.target as Node) ||
          topbarRef.current?.menubutton?.contains(event.target as Node)
        );

        if (isOutsideClicked) {
          hideMenu();
        }
      },
    });

  const [bindDocumentResizeListener, unbindDocumentResizeListener] =
    useResizeListener({
      listener: () => {
        if (isDesktop() && !DomHandler.isTouchDevice()) {
          hideMenu();
        }
      },
    });

  let timeout: ReturnType<typeof setTimeout> | null = null;

  const onMouseEnter = () => {
    if (!layoutState.anchored) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      dispatch(
        SetLayoutState({
          sidebarActive: true,
        })
      );
    }
  };

  const onMouseLeave = () => {
    if (!layoutState.anchored) {
      if (!timeout) {
        timeout = setTimeout(
          () =>
            dispatch(
              SetLayoutState({
                sidebarActive: false,
              })
            ),
          300
        );
      }
    }
  };

  const hideMenu = useCallback(() => {
    dispatch(
      SetLayoutState({
        overlayMenuActive: false,
        overlaySubmenuActive: false,
        staticMenuMobileActive: false,
        menuHoverActive: false,
        resetMenu: (isSlim() || isSlimPlus() || isHorizontal()) && isDesktop(),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSlim, isSlimPlus, isHorizontal, isDesktop]);

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.className += " overflow-hidden";
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("overflow-hidden");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + "overflow-hidden".split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  };

  useMountEffect(() => {
    PrimeReact.ripple = true;
  });

  useEffect(() => {
    if (
      layoutState.overlayMenuActive ||
      layoutState.staticMenuMobileActive ||
      layoutState.overlaySubmenuActive
    ) {
      bindMenuOutsideClickListener();
    }

    if (layoutState.staticMenuMobileActive) {
      blockBodyScroll();
      (isSlim() || isSlimPlus() || isHorizontal()) &&
        bindDocumentResizeListener();
    }

    return () => {
      unbindMenuOutsideClickListener();
      unbindDocumentResizeListener();
      unblockBodyScroll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layoutState.overlayMenuActive,
    layoutState.staticMenuMobileActive,
    layoutState.overlaySubmenuActive,
    bindMenuOutsideClickListener,
  ]);

  useEffect(
    () => {
      const onRouteChange = () => {
        hideMenu();
      };
      onRouteChange();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // pathname, searchParams
    ]
  );

  useUnmountEffect(() => {
    unbindMenuOutsideClickListener();
  });

  const containerClass = classNames({
    "layout-light": layoutConfig.colorScheme === "light",
    "layout-dim": layoutConfig.colorScheme === "dim",
    "layout-dark": layoutConfig.colorScheme === "dark",
    "layout-colorscheme-menu": layoutConfig.menuTheme === "colorScheme",
    "layout-primarycolor-menu": layoutConfig.menuTheme === "primaryColor",
    "layout-transparent-menu": layoutConfig.menuTheme === "transparent",
    "layout-overlay": layoutConfig.menuMode === "overlay",
    "layout-static": layoutConfig.menuMode === "static",
    "layout-slim": layoutConfig.menuMode === "slim",
    "layout-slim-plus": layoutConfig.menuMode === "slim-plus",
    "layout-horizontal": layoutConfig.menuMode === "horizontal",
    "layout-reveal": layoutConfig.menuMode === "reveal",
    "layout-drawer": layoutConfig.menuMode === "drawer",
    "layout-static-inactive":
      layoutState.staticMenuDesktopInactive &&
      layoutConfig.menuMode === "static",
    "layout-overlay-active": layoutState.overlayMenuActive,
    "layout-mobile-active": layoutState.staticMenuMobileActive,
    "p-input-filled": layoutConfig.inputStyle === "filled",
    "p-ripple-disabled": !layoutConfig.ripple,
    "layout-sidebar-active": layoutState.sidebarActive,
    "layout-sidebar-anchored": layoutState.anchored,
  });

  if (layoutConfig.colorScheme === "dark") {
    document.body.classList.remove("layout-light");
    document.body.classList.add("layout-dark");
  } else if (layoutConfig.colorScheme === "light") {
    document.body.classList.remove("layout-dark");
    document.body.classList.add("layout-light");
  } else {
    document.body.classList.remove("layout-dark");
    document.body.classList.remove("layout-light");
  }
  if (layoutConfig.theme === "clair") {
    document.body.classList.add(layoutConfig.theme + "-theme");
  } else {
    document.body.classList.remove("clair-theme");
  }

  return (
    <React.Fragment>
      <div className={classNames("layout-container", containerClass)}>
        <div
          ref={sidebarRef}
          className="layout-sidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <AppSidebar />
        </div>
        <div className="layout-content-wrapper">
          {/* <AppTopbar ref={topbarRef} /> */}

          {/* <AppBreadCrumb className="content-breadcrumb"></AppBreadCrumb> */}
          <div className="layout-content">{props.children}</div>
        </div>
        <AppProfileSidebar />
        <AppConfig />
        <div className="layout-mask"></div>
      </div>
    </React.Fragment>
  );
};

export default ThemeLayout;
